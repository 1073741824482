import { Column, Columns } from "components/Layout"

import Container from "components/Container"
import Faq from "components/Faq"
import { Heading } from "components/Typography"
import Observer from "@researchgate/react-intersection-observer"
import onScrollAnimations from "styles/onScrollAnimations"
import styled from "styled-components/macro"
import { useState } from "react"

function SectionFaq({ id, title, subtitle }) {
  const [isInView, setInView] = useState(false)

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  return (
    <Observer onChange={handleIntersection}>
      <Element id={id} className={isInView && "-in-view"}>
        <StyledContainer>
          <Header>
            <Columns>
              <Column>
                <Title>{title}</Title>
              </Column>
              <Column size="half">
                <Subtitle>{subtitle}</Subtitle>
              </Column>
            </Columns>
          </Header>
          <Faq />
        </StyledContainer>
      </Element>
    </Observer>
  )
}

const Element = styled.div`
  padding: 90px 0 90px 0;
`

const StyledContainer = styled(Container)`
  ${onScrollAnimations(Element)};
`

const Header = styled.header`
  margin-bottom: 90px;

  ${Column}:last-child {
    margin-top: 29px;
  }
`

const Title = styled(Heading).attrs({
  extraLarge: true,
  "data-size": "extraLarge",
})``

const Subtitle = styled(Heading).attrs({
  semiMedium: true,
  "data-size": "semiMedium",
  as: "p",
})`
  font-weight: 400;
`

export default SectionFaq
