import { Link, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"

import Container from "components/Container"
import { Heading } from "components/Typography"
import { rgba } from "polished"
import styled from "styled-components/macro"

function Tabs({
  items,
  type,
  activeItemIndex = 0,
  className,
  onChange,
  onItemClick,
}) {
  const [selectedActiveIndex, setSelectedActiveIndex] = useState(null)
  const { pathname } = useLocation()

  useEffect(() => {
    setSelectedActiveIndex(null)
  }, [pathname])

  return (
    <Element className={className}>
      <Container>
        {items.map(({ title, url }, index) => {
          const isActive =
            selectedActiveIndex === index ||
            (selectedActiveIndex == null && activeItemIndex === index)
          return (
            <Tab
              className={isActive && "-active"}
              key={index}
              onClick={() => {
                setSelectedActiveIndex(index)
                onChange && onChange(index)
                onItemClick && onItemClick(index)
              }}
            >
              <TabInner as={url && Link} to={url}>
                <TabLabel>{title}</TabLabel>
              </TabInner>
            </Tab>
          )
        })}
      </Container>
    </Element>
  )
}

const Element = styled.ul`
  display: flex;
  list-style: none;
  background-color: ${(p) => p.theme.color.white};
  border-bottom: 2px solid ${(p) => rgba(p.theme.color.black, 0.1)};
`

const Tab = styled.li`
  display: inline-flex;
  position: relative;
  color: ${(p) => rgba(p.theme.color.black, 0.32)};
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background-color: currentColor;
    transition: width 0s ${(p) => p.theme.easeOutCubic};
  }

  &&:not(:last-child) {
    margin-bottom: 0;
    margin-right: 80px;
  }

  &.-active,
  &:hover {
    color: ${(p) => p.theme.color.black};
  }

  &.-active {
    &::before {
      width: 100%;
      transition-duration: 0.9s;
    }
  }
`

const TabInner = styled.span`
  display: inline-flex;
  align-items: center;
  height: 66px;
`

const TabLabel = styled(Heading).attrs({
  small: true,
  as: "span",
})``

export default Tabs
