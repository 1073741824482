import { useQuery } from "jsonapi-react"
import { getNonceValue } from "helpers/getNonceValue"

function useGuidelinesData() {
  const { data, isLoading, error } = useQuery([
    "node/pdf_guidelines_page",
    {
      include: [
        "node_type",
        "field_hero_img",
        "field_guidelines_tabs",
        "field_guidelines_tabs.field_downloads",
        "field_guidelines_tabs.field_main_pdf",
        "field_guidelines_tabs.field_downloads.field_download_file",
      ],
      nonce: getNonceValue(),
    },
  ])

  if (data?.[0]) {
    const tabs = data[0].getTabs()
    const resultData = {
      hero: data[0].hero,
      tabs: tabs.reduce((acc, item) => {
        acc[item.slug] = item
        return acc
      }, {}),
    }

    return { data: resultData, isLoading, error }
  }

  return { data, isLoading, error }
}

export { useGuidelinesData }
