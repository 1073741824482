import { useState } from "react"
import styled from "styled-components/macro"
import Observer from "@researchgate/react-intersection-observer"
import { media } from "styles/breakpoints"
import Container from "components/Container"
import { Columns, Column } from "components/Layout"
import { Heading } from "components/Typography"
import Asset from "./Asset"
import onScrollAnimations from "styles/onScrollAnimations"

function SectionFeaturedAsset({ id, title, subtitle, backgroundColor }) {
  const [isInView, setInView] = useState(false)

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  return (
    <Observer onChange={handleIntersection}>
      <Element
        id={id}
        backgroundColor={backgroundColor}
        className={isInView && "-in-view"}
      >
        <StyledContainer>
          <Header>
            <Columns>
              <Column>
                <Title>{title}</Title>
              </Column>
              <Column size="half">
                <Subtitle>{subtitle}</Subtitle>
              </Column>
            </Columns>
          </Header>
          <Asset />
        </StyledContainer>
      </Element>
    </Observer>
  )
}

const Element = styled.section`
  padding: 90px 0;
  background-color: ${(p) => p.backgroundColor};
`

const StyledContainer = styled(Container)`
  ${onScrollAnimations(Element)};
`

const Header = styled.header`
  margin-bottom: 100px;

  ${Column}:last-child {
    margin-top: 15px;
  }
`

const Title = styled(Heading).attrs({
  extraLarge: true,
  "data-size": "extraLarge",
})`
  ${media.ltMediumTablet} {
    font-size: 46px;
  }
`

const Subtitle = styled(Heading).attrs({
  semiMedium: true,
  "data-size": "semiMedium",
  as: "p",
})`
  font-weight: 400;
`

export default SectionFeaturedAsset
