import { GUIDELINES_TABS } from "config/constants"
import Hero from "components/Hero"
import Layout from "layouts"
import Loader from "components/Loader"
import { PdfPreviewLayout } from "./PdfPreviewLayout"
import { StyledTabs } from "./Guidelines.styled"
import theme from "styles/theme"
import { trackEvent } from "utils/analytics"
import { useGuidelinesData } from "./useGuidelinesData"
import { useLastLocation } from "react-router-last-location"
import { useLocation } from "react-router-dom"
import { useMemo } from "react"

function Guidelines() {
  const location = useLocation()
  const type = location.pathname.replaceAll("/", "")
  const { data, isLoading, error } = useGuidelinesData()
  const activeTabIndex = GUIDELINES_TABS.map(({ slug }) => slug).indexOf(type)

  const lastLocation = useLastLocation()

  const isLastLocationGuideline =
    lastLocation && !!data?.tabs?.[lastLocation.pathname.replaceAll("/", "")]
  const heroHeight = 750

  const scrollToTopPosition = isLastLocationGuideline
    ? Math.min(heroHeight - theme.headerHeight, window.pageYOffset)
    : null

  const tabs = useMemo(() => {
    return GUIDELINES_TABS.filter((tab) => data?.tabs[tab.slug]).map(
      (item) => ({
        title: item.title,
        url: "/" + item.slug,
      })
    )
  }, [data?.tabs])

  const activeTabData = useMemo(() => {
    return data?.tabs?.[type]
  }, [data?.tabs, type])

  return isLoading || error ? (
    <Loader error={error} />
  ) : (
    <Layout scrollToTopPosition={scrollToTopPosition}>
      <Hero
        title={data?.hero.title}
        subtitle={data?.hero.subtitle}
        backgroundImageUrl={data?.hero.getBackgroundImageUrl()}
        backgroundSizeCover
        size="medium"
        titleSize="large"
        align="center"
      />
      <StyledTabs
        items={tabs}
        activeItemIndex={activeTabIndex}
        onItemClick={(index) => {
          trackEvent("click_navigation", {
            navigation_button_name: tabs[index].title,
            navigation_category: "guidelines",
          })
        }}
      />
      <PdfPreviewLayout {...activeTabData} />
    </Layout>
  )
}

export default Guidelines
