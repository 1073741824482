import { Column, Columns } from "components/Layout"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { Heading } from "components/Typography"
import { appInsights } from "index"
import { getNonceValue } from "helpers/getNonceValue"
import { media } from "styles/breakpoints"
import { rgba } from "polished"
import styled from "styled-components/macro"
import { trackEvent } from "utils/analytics"
import { useQuery } from "jsonapi-react"

function getIndexOfParentChildren(el) {
  var children = el.parentNode.childNodes,
    i = 0
  for (; i < children.length; i++) {
    if (children[i] == el) {
      return i
    }
  }
  return -1
}

function Faq() {
  const elementRef = useRef()
  const { data: sections } = useQuery([
    "taxonomy_term/faq_category",
    {
      sort: "weight",
      nonce: getNonceValue(),
    },
  ])
  const { data: items } = useQuery([
    "node/faq",
    {
      include: ["field_faq_category"],
      nonce: getNonceValue(),
    },
  ])
  const [activeSectionIndex, setActiveSectionIndex] = useState(null)
  const [matchFired, setMatchFired] = useState(false)

  function handleClick(index) {
    const newIndex = index === activeSectionIndex ? null : index
    setActiveSectionIndex(newIndex)
    if (newIndex) {
      trackEvent("click_faq", {
        faq_name: sections[newIndex].name,
      })
    }
  }

  const openHashCategory = useCallback(() => {
    const slug = window.location.hash.substring(1)

    if (!items || !sections || matchFired || !slug) return

    const sectionNode = document.getElementById(slug)

    if (sectionNode) {
      setTimeout(() => {
        sectionNode.scrollIntoView({ behavior: "smooth", block: "center" })
        setActiveSectionIndex(getIndexOfParentChildren(sectionNode))
        setMatchFired(true)
      }, 10)
    }
  }, [items, sections, matchFired])

  useEffect(() => {
    if (items && sections) {
      openHashCategory()
    }
  }, [items, openHashCategory, sections])

  return (
    <Element ref={elementRef}>
      {sections?.map(({ name, field_slug }, index) => {
        const sectionItems = items?.filter(
          (item) => item.getCategoryName() === name
        )
        return (
          <Section
            id={field_slug}
            className={index === activeSectionIndex && "-active"}
            key={index}
          >
            <Column>
              <SectionTitle>{name}</SectionTitle>
            </Column>
            <Column size="half">
              <Questions>
                {sectionItems?.map(({ question, answer }, itemIndex) => (
                  <React.Fragment key={itemIndex}>
                    <Question onClick={() => handleClick(index)}>
                      {question}
                    </Question>
                    <Answer>
                      <AnswerInner>{answer}</AnswerInner>
                    </Answer>
                  </React.Fragment>
                ))}
              </Questions>
            </Column>
            <Toggle onClick={() => handleClick(index)} />
          </Section>
        )
      })}
    </Element>
  )
}

const Element = styled.div``

const Section = styled(Columns)`
  padding: 52px 0;
  position: relative;
  border: solid ${(p) => rgba(p.theme.color.black, 0.2)};
  border-width: 1px 0 0;

  &:last-child {
    border-bottom-width: 1px;
  }
`

const SectionTitle = styled(Heading).attrs({
  moleculeSubheading: true,
  "data-size": "moleculeSubheading",
  as: "h3",
})`
  max-width: 410px;
  font-weight: 500;
`

const Questions = styled.div`
  padding-right: 70px;

  ${media.mediumTablet} {
    padding-right: 170px;
  }
`

const Question = styled(Heading).attrs({
  moleculeSubheading: true,
  "data-size": "moleculeSubheading",
  as: "h4",
})`
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  font-weight: 300;
  user-select: none;
  cursor: pointer;
`

const Answer = styled.div`
  overflow: hidden;
  max-height: 0;
  font-size: 16px;
  line-height: 1.5;
  transition: max-height 0.5s ${(p) => p.theme.easeOutQuart};

  ${Section}.-active & {
    max-height: none; // TODO: animate
  }
`

const AnswerInner = styled.div`
  padding-top: 2px;
  padding-bottom: 40px;
  a {
    text-decoration: underline;
  }
`

const Toggle = styled.button`
  width: 34px;
  height: 34px;
  position: absolute;
  top: 52px;
  right: 0;
  background-color: ${(p) => p.theme.color.black};
  border: 2px solid transparent;
  border-radius: 50%;
  color: ${(p) => p.theme.color.white};
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    width: 14px;
    height: 2px;
    position: absolute;
    top: 14px;
    left: 8px;
    background: currentColor;
    transition: transform 0.5s ${(p) => p.theme.easeOutQuart};
  }

  &:after {
    transform: rotate(90deg);
  }

  ${Section}.-active & {
    background-color: ${(p) => p.theme.color.white};
    border-color: currentColor;
    color: ${(p) => p.theme.color.black};

    &::before {
      opacity: 0;
      transform: rotate(90deg);
    }

    &::after {
      transform: rotate(180deg);
    }
  }
`

export default Faq
