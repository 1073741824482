import { Heading, Paragraph } from "components/Typography"

import Container from "components/Container"
import { Helmet } from "react-helmet"
import Hero from "components/Hero"
import Layout from "layouts"
import Loader from "components/Loader"
import NewsList from "components/NewsList"
import { getNonceValue } from "helpers/getNonceValue"
import slugify from "helpers/slugify"
import styled from "styled-components/macro"
import { useQuery } from "jsonapi-react"

function Article({ slug }) {
  const {
    data: articles,
    isLoading,
    error,
  } = useQuery([
    "node/article",
    {
      include: [
        "field_hero_img",
        "field_hero_content_box",
        "field_thumbnail_image",
      ],
      nonce: getNonceValue(),
    },
  ])
  const page = articles?.find((item) => slugify(item.title) === slug)
  const { title, date, hero, content } = {
    ...page,
  }
  const newsList =
    articles
      ?.sort((a, b) => {
        return new Date(b.created) - new Date(a.created)
      })
      .filter((item) => slugify(item.title) !== slug)
      .slice(0, 3) || []

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isLoading || error ? (
        <Loader error={error} />
      ) : (
        <Layout>
          <Hero
            title={title}
            published={date}
            textColumns={hero.getTextColumns()}
            backgroundColor={hero.backgroundColor}
            backgroundImageUrl={hero.getBackgroundImageUrl}
            backgroundSizeContain
          />
          <StyledContainer>
            <Content>{content}</Content>
            <SectionNews>
              <SectionNewsTitle>More news</SectionNewsTitle>
              <NewsList items={newsList} isCompact />
            </SectionNews>
          </StyledContainer>
        </Layout>
      )}
    </>
  )
}

const StyledContainer = styled(Container)`
  padding-top: 87px;
  padding-bottom: 20px;
`

const Content = styled.div`
  max-width: 816px;
  margin: 0 auto 100px;

  ${Heading}[data-size="medium"] + ${Heading}[data-size="small"] {
    margin-top: 35px;
  }

  ${Paragraph} + ${Heading}[data-size="medium"] {
    margin-top: 80px;
  }

  ${Paragraph} + ${Heading}[data-size="small"] {
    margin-top: -5px;
  }

  ${Heading}[data-size="medium"] + ${Paragraph} {
    margin-top: 24px;
  }

  ${Heading}[data-size="small"] + ${Paragraph} {
    margin-top: -8px;
  }

  ${Paragraph} + ${Paragraph} {
    margin-top: -8px;
  }

  img {
    display: block;
    width: 704px;
    margin: 90px auto;
  }

  li {
    padding-left: 32px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 9px;
      left: 10px;
      width: 7px;
      height: 7px;
      background-color: currentColor;
      border-radius: 50%;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      opacity: 0.6;
    }
  }
`

const SectionNews = styled.section`
  max-width: 816px;
  margin: 0 auto;
`

const SectionNewsTitle = styled.h2`
  opacity: 0.8;
  margin-bottom: 32px;
  padding-bottom: 10px;
  text-transform: uppercase;
  border-bottom: 2px solid;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.015em;
`

export default Article
