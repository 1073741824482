import styled, { css } from "styled-components/macro"
import { useEffect, useRef, useState } from "react"

import { media } from "styles/breakpoints"
import useMousePosition from "hooks/useMousePosition"

const clipId = "segmented-circle-clip"

function SegmentedCircle({
  size = "medium",
  ballPosition,
  moveBallOnPointer,
  isParentInView,
  className,
}) {
  const [ballOffsetX, setBallOffsetX] = useState(0)
  const [ballOffsetY, setBallOffsetY] = useState(0)
  const [ballZoom, setBallZoom] = useState(1)
  const element = useRef()
  const ball = useRef()
  const { x, y } = useMousePosition()

  function handleMouseMove() {
    if (!moveBallOnPointer) {
      return
    }
    const proximityRadius = 150
    const radius = element.current.offsetWidth / 2
    const centerX = element.current.offsetLeft + radius
    const centerY = element.current.offsetTop - 385 + radius
    let diffX = centerX - x
    let diffY = centerY - y
    const r = Math.sqrt(diffX * diffX + diffY * diffY)
    if (r > proximityRadius) {
      diffX /= r
      diffY /= r
      diffX *= proximityRadius
      diffY *= proximityRadius
    }
    const absDistance = Math.hypot(centerX - x, centerY - y)
    const distanceDeltaX = Math.max(Math.min(diffX / proximityRadius, 1), -1)
    const distanceDeltaY = Math.max(Math.min(diffY / proximityRadius, 1), -1)
    let offsetX = distanceDeltaX * proximityRadius * -0.5
    let offsetY = distanceDeltaY * proximityRadius * -0.5
    let zoom = 1.15
    if (absDistance > proximityRadius * 2.5) {
      offsetX = 0
      offsetY = 0
      zoom = 1
    }
    setBallOffsetX(offsetX)
    setBallOffsetY(offsetY)
    setBallZoom(zoom)
  }

  useEffect(() => {
    if (moveBallOnPointer) {
      ball.current.style.transform = `translate(${ballOffsetX}px, ${ballOffsetY}px) scale(${ballZoom})`
    }
  }, [moveBallOnPointer, ballOffsetX, ballOffsetY, ballZoom])

  useEffect(handleMouseMove, [moveBallOnPointer, x, y])

  return (
    <Element
      ref={element}
      data-size={size}
      data-parent-in-view={isParentInView || null}
      className={className}
    >
      <Shape>
        <defs>
          <clipPath id={clipId} clipPathUnits="objectBoundingBox">
            <path d="M.97944.38576A.478.478,0,0,1,.41164.9874L.52135.52218ZM.36775.97456A.478.478,0,0,1,.13062.182l.348.32762ZM.165.1493A.478.478,0,0,1,.96988.34021L.51214.47782Z" />
          </clipPath>
        </defs>
      </Shape>
      <Layer1>
        <Layer2>
          <Layer3>
            <Layer4>
              <Ball
                ref={ball}
                position={ballPosition}
                moveOnPointer={moveBallOnPointer}
              />
            </Layer4>
          </Layer3>
        </Layer2>
      </Layer1>
    </Element>
  )
}

const Element = styled.div`
  filter: drop-shadow(0 50px 25px rgba(0, 0, 0, 0.1));

  &[data-size="small"] {
    width: 35vw;
    height: 35vw;
    filter: drop-shadow(0 40px 20px rgba(0, 0, 0, 0.1));
  }

  &[data-size="medium"] {
    width: calc(50vw - 40px);
    height: calc(50vw - 40px);
  }

  &[data-size="large"] {
    width: 1616px;
    height: 1616px;
    filter: drop-shadow(0 120px 50px rgba(0, 0, 0, 0.1));
  }

  ${media.smallDesktop} {
    &[data-size="small"] {
      width: 463px;
      height: 463px;
    }

    &[data-size="medium"] {
      width: 597px;
      height: 597px;

      @media (max-height: 780px) {
        width: 500px;
        height: 500px;
        margin-left: 80px;
      }

      @media (max-height: 680px) {
        width: 400px;
        height: 400px;
        margin-left: 150px;
      }
    }
  }
`

const Shape = styled.svg`
  width: 0;
  height: 0;
  position: absolute;
`

const Layer1 = styled.div`
  height: 100%;
  filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.03));
`

const Layer2 = styled.div`
  height: 100%;

  ${Element}[data-size="small"] &,
  ${Element}[data-size="medium"] & {
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.17));
  }
`

const Layer3 = styled.div`
  height: 100%;
  transform: rotate(var(--circle-rotation));
  will-change: transform;
`

const Layer4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  clip-path: url(#${clipId});
  background-image: linear-gradient(${(p) => p.theme.color.white}, #fafafa);

  ${Element}[data-size="small"] & {
    transform: rotate(14deg);
  }

  ${Element}[data-size="medium"] & {
    transform: rotate(24.2deg);
  }

  ${Element}[data-size="large"] & {
    transform: rotate(60deg);
    background-image: linear-gradient(${(p) => p.theme.color.white}, #f7f7f7);
  }

  ${Element}[data-size="large"][data-parent-in-view] & {
    transform: rotate(6.9deg);
    transition: transform 1.8s ${(p) => p.theme.easeOutCubic};
    will-change: transform;
  }
`

const Ball = styled.div`
  transition: opacity 1s, transform 1.05s ${(p) => p.theme.easeInOutCubic};
  will-change: transform;

  &::before {
    content: "";
    display: block;
    height: 100%;
    background-image: linear-gradient(
      130.52deg,
      #e4f9e8 12.91%,
      #adddb7 85.88%
    );
    border-radius: 50%;
    transition: transform 5s ${(p) => p.theme.easeOutCubic};
    will-change: transform;
  }

  &:hover {
    &::before {
      transform: scale(1.15);
    }
  }

  ${Element}[data-size="small"] & {
    width: 14vw;
    height: 14vw;
  }

  ${Element}[data-size="medium"] & {
    width: calc((50vw - 40px) * 0.4);
    height: calc((50vw - 40px) * 0.4);
  }

  ${Element}[data-size="large"] & {
    width: 500px;
    height: 500px;

    &::before {
      transform: rotate(0deg);
    }
  }

  ${(p) =>
    p.position === -1 &&
    css`
      opacity: 0;
    `}

  ${(p) =>
    p.position === 0 &&
    css`
      transform: translate(40px, 40px);

      ${Element}[data-size="small"] & {
        transform: translate(30px, 30px);
      }
    `}

  ${(p) =>
    p.position === 1 &&
    css`
      transform: translate(-45px, 15px);

      &::before {
        transform: rotate(120deg);
      }

      &:hover {
        &::before {
          transform: rotate(120deg) scale(1.15);
        }
      }

      ${Element}[data-size="small"] & {
        transform: translate(-33.75px, 11.25px);
      }
    `}
      
  ${(p) =>
    p.position === 2 &&
    css`
      transform: translate(15px, -45px);

      &::before {
        transform: rotate(240deg);
      }

      &:hover {
        &::before {
          transform: rotate(240deg) scale(1.15);
        }
      }

      ${Element}[data-size="small"] & {
        transform: translate(11.25px, -33.75px);
      }
    `}
        
  ${(p) =>
    p.position === 3 &&
    css`
      ${Element}[data-size="small"] & {
        transform: translate(-6px, 27px);
      }
    `}  
          
  ${(p) =>
    p.position === 4 &&
    css`
      ${Element}[data-size="small"] & {
        transform: translate(-23px, -12px);
      }
    `}
     
  ${(p) =>
    p.position === 5 &&
    css`
      ${Element}[data-size="small"] & {
        transform: translate(21px, -5px);
      }
    `}
    
  ${(p) =>
    p.moveOnPointer &&
    css`
      transition: transform 1.5s ${(p) => p.theme.easeOutQuart};
    `}
    
  ${media.smallDesktop} {
    ${Element}[data-size="small"] & {
      width: 182px;
      height: 182px;
    }

    ${Element}[data-size="medium"] & {
      width: 234px;
      height: 234px;

      @media (max-height: 780px) {
        width: 200px;
        height: 200px;
      }

      @media (max-height: 680px) {
        width: 160px;
        height: 160px;
      }
    }
  }
`

export default SegmentedCircle
