import styled, { css } from "styled-components/macro"

import Container from "components/Container"
import { Link } from "react-router-dom"
import Navigation from "./Navigation"
import logoMark from "assets/images/logoMark.svg"
import { media } from "styles/breakpoints"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useState } from "react"

function Header({ isTransparent: isTransparentInitially, className }) {
  const [isTransparent, setTransparency] = useState(true)

  useScrollPosition(
    ({ currPos }) => {
      if (!isTransparentInitially) {
        setTransparency(currPos.y >= 0)
      }
    },
    [isTransparent]
  )

  return (
    <Element
      transparent={isTransparent}
      isTransparentInitially={isTransparentInitially}
      className={className}
    >
      <Container>
        <Logo>
          <Link to="/">The Foundation</Link>
        </Logo>
        <Link to="/">
          <LogoMark src={logoMark} />
        </Link>
        <Navigation />
      </Container>
    </Element>
  )
}

const transparentStyles = css`
  background-color: transparent;
  pointer-events: none;
`

const Element = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${(p) => p.theme.color.background};
  transition: background-color 0.4s;

  ${Container} {
    display: flex;
    align-items: center;
  }

  ${(p) => p.isTransparentInitially && transparentStyles}

  ${media.ltSmallTablet} {
    ${Container} {
      height: 80px;
    }
  }

  ${media.smallDesktop} {
    ${(p) => p.transparent && transparentStyles}
  }
`

const Logo = styled.h2`
  position: absolute;
  top: 50%;
  left: ${(p) => p.theme.sidePadding.small + 32 + 24}px;
  transform: translateY(-50%);
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  white-space: nowrap;
  pointer-events: all;

  ${media.mediumTablet} {
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
  }
`

const LogoMark = styled.img`
  margin: 0 9px;
  vertical-align: middle;
  pointer-events: all;
`

export default Header
