import styled, { css } from "styled-components/macro"

const Columns = styled.div(({ verticalAlign }) => {
  const alignItems = verticalAlign === "center" ? "center" : null
  return css`
    display: flex;
    justify-content: space-between;
    align-items: ${alignItems};
  `
})

const Column = styled.div(({ size }) => {
  const width = size === "half" ? "50%" : "45%"
  return css`
    width: ${width};
  `
})

export { Columns, Column }
