import styled, { css } from "styled-components/macro"

import { ReactComponent as SvgScrollDownTree } from "assets/icons/scrollDownTree.svg"

function ScrollIndicator({ isVisible, className, children }) {
  return (
    <Element isVisible={isVisible} className={className}>
      <SvgScrollDownTree />
      {children}
    </Element>
  )
}

const Element = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 60px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
 
  transition: 0.5s ${(p) => p.theme.easeOutQuart};
  transition-property: visibility, opacity;

  ${(p) =>
    !p.isVisible &&
    css`
      visibility: hidden;
      opacity: 0;
    `}

  ${(p) =>
    p.isVisible &&
    css`
      transition-duration: 2s;
    `}

    button {
      position: absolute;
      top: 100%;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.03em;
      cursor: pointer;
      &:nth-of-type(1) {
        left: 0;
        transform: translate(-50%, 100%);
      }
      &:nth-of-type(2) {
        right: 0;
        transform: translate(50%, 100%);
      }
    }
`

export default ScrollIndicator
