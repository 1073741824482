import { Link } from "react-router-dom"
import { media } from "styles/breakpoints"
import navigation from "data/navigation.json"
import { rgba } from "polished"
import styled from "styled-components/macro"
import { trackEvent } from "utils/analytics"

function Navigation() {
  return (
    <Element>
      {navigation.map((list, index) => (
        <NavigationList key={index}>
          {list.map(({ path, title, subnav }, index) => (
            <NavigationItem key={index}>
              <NavigationLink
                to={path}
                onClick={() => {
                  trackEvent("click_navigation", {
                    navigation_button_name: title,
                    navigation_category: "header",
                  })
                }}
              >
                {title}
              </NavigationLink>
              {subnav && (
                <SubNavigationList>
                  {subnav.map(({ path, title }, index) => (
                    <SubNavigationItem key={index}>
                      <SubNavigationLink
                        to={path}
                        onClick={() => {
                          trackEvent("click_navigation", {
                            navigation_button_name: title,
                            navigation_category: "header",
                          })
                        }}
                      >
                        {title}
                      </SubNavigationLink>
                    </SubNavigationItem>
                  ))}
                </SubNavigationList>
              )}
            </NavigationItem>
          ))}
        </NavigationList>
      ))}
    </Element>
  )
}

const Element = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  height: ${(p) => p.theme.headerHeight}px;

  ${media.ltSmallTablet} {
    display: none;
  }

  ${media.mediumTablet} {
    justify-content: space-between;
  }
`

const NavigationList = styled.ul`
  display: flex;
  font-size: 16px;
`

const NavigationItem = styled.li`
  margin-left: 24px;
  position: relative;
  pointer-events: all;

  ${media.smallDesktop} {
    margin-left: 32px;
  }
`

const NavigationLink = styled(Link)`
  display: inline-flex;
  position: relative;

  &::before {
    content: "";
    opacity: 0;
    width: 0%;
    height: 1px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background-color: currentColor;
    pointer-events: none;
    transition: 0.25s ${(p) => p.theme.easeOutQuart};
    transition-property: opacity, width;
  }

  &:only-child {
    &:hover {
      &::before {
        width: 100%;
        opacity: 1;
      }
    }
  }
`

const SubNavigationList = styled.ul`
  visibility: hidden;
  opacity: 0;
  width: 128px;
  padding-top: 18px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);

  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.14285714;
  letter-spacing: 0.01em;
  transition: 0.25s ${(p) => p.theme.easeOutQuad};
  transition-property: visibility, opacity;

  &::before {
    content: "";
    width: 100%;
    position: absolute;
    top: 18px;
    bottom: 0;
    left: 0;
    background-color: ${(p) => rgba(p.theme.color.background, 0.7)};
  }

  &::after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    top: 11px;
    left: calc(50% - 7.5px);
    transform: rotate(45deg);
    background-color: ${(p) => p.theme.color.black};
  }

  ${NavigationItem}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

const SubNavigationItem = styled.li`
  &:not(:last-child) {
    padding-bottom: 1px;
  }
`

const SubNavigationLink = styled(Link)`
  display: block;
  padding: 8px 10px;
  position: relative;
  z-index: 1;
  background-color: ${(p) => p.theme.color.black};
  color: ${(p) => p.theme.color.white};
  transition: none;

  &:hover {
    background-color: ${(p) => p.theme.color.green};
    color: ${(p) => p.theme.color.black};
  }

  ${SubNavigationItem}:first-child & {
    &::before {
      content: "";
      opacity: 0;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -8px;
      left: calc(50% - 8px);
      transform: rotate(45deg);
      background-color: inherit;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
`

export default Navigation
