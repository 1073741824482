import { APP_VERSION } from "config/constants"
import App from "./App"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import React from "react"
import ReactDOM from "react-dom"
import reportWebVitals from "./reportWebVitals"

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_ANALYTICS_INSTRUMENTATION_KEY,
  },
})

appInsights.loadAppInsights()
appInsights.context.application.ver = APP_VERSION

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
