import * as Styled from "./PdfPreviewLayout.styled"

import { useEffect, useState } from "react"

import PropTypes from "prop-types"
import { getFileType } from "utils/getFileType"
import { trackEvent } from "utils/analytics"

function PdfPreviewLayout({
  title,
  subtitle,
  pdfUrl,
  downloadLinks,
  isActive,
}) {
  const [isInView, setInView] = useState(false)

  useEffect(() => {
    if (isInView) {
      setInView(false)
      setTimeout(() => {
        setInView(true)
      }, 10)
    } else {
      setInView(true)
    }
  }, [title])

  return (
    <Styled.Container className={isInView && "-in-view"}>
      <Styled.Heading>{title}</Styled.Heading>
      <Styled.Paragraph dangerouslySetInnerHTML={{ __html: subtitle }} />
      <Styled.Main>
        <Styled.Iframe title={title} src={pdfUrl} />
        <div>
          <Styled.Downloads>
            <h3>Downloads</h3>
            <ul>
              {downloadLinks?.map((item, i) => (
                <li key={i}>
                  <a
                    href={item.downloadUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      trackEvent("media_downloaded", {
                        media_name: item.title,
                        media_type: `${getFileType(item.downloadUrl)}`,
                      })
                    }}
                  >
                    {item.title}
                  </a>
                  <div dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                </li>
              ))}
            </ul>
          </Styled.Downloads>
        </div>
      </Styled.Main>
    </Styled.Container>
  )
}

PdfPreviewLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  downloadLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      downloadUrl: PropTypes.string,
    })
  ),
}

export { PdfPreviewLayout }
