import { Heading, Paragraph } from "components/Typography"
import parse, { domToReact } from "html-react-parser"

import { SplitWords } from "components/SplitText"
import { rgba } from "polished"
import slugify from "helpers/slugify"
import stripHtml from "helpers/stripHtml"

const backendUrl = process.env.REACT_APP_BACKEND_URL

const schema = {
  "node--homepage": {
    type: "homepage",
    fields: {
      hero: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_hero_title,
            subtitle: attrs.field_hero_description,
            ctaButtonText: attrs.field_hero_btn_cta,
            ctaButtonUrl: attrs.field_hero_btn_url,
            getBackgroundImageUrl: () => {
              return backendUrl + attrs.field_hero_img?.uri?.url
            },
            getMobileImageUrl: () => {
              return backendUrl + attrs.field_hero_img_mobile?.uri?.url
            },
          }
        },
      },
      sectionTileRow: {
        resolve: (value, attrs, data) => {
          return {
            getTiles: () => {
              return attrs.field_carousel.map((item) => {
                return {
                  title: item.field_p_cp_title,
                  text: item.field_p_cp_desc,
                  imageUrl: backendUrl + item.field_p_cp_img?.uri?.url,
                  url: item.field_p_cp_url,
                }
              })
            },
          }
        },
      },
      sectionFigure: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_s_time_title,
            text: attrs.field_s_time_desc.processed,
            linksTitle: attrs.field_s_time_links_title,
            getLinks: () => {
              return attrs.field_s_time_links.map((link) => {
                return {
                  title: link.field_p_cl_label,
                  url: link.field_p_cl_url,
                }
              })
            },
          }
        },
      },
      sectionMolecule: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_s_mol_title,
            subtitle: attrs.field_s_mol_desc,
            buttonText: attrs.field_s_mol_btn_cta,
            buttonUrl: attrs.field_s_mol_btn_url,
          }
        },
      },
      introducingGreen: {
        resolve: (_, attrs) => ({
          title: attrs.field_s_green_title,
          description: attrs.field_s_green_desc.value,
          ctaTitle: attrs.field_s_green_btn_cta,
          ctaUrl: attrs.field_s_green_btn_url,
          getBackgroundImageUrl: () => {
            return backendUrl + attrs.field_s_green_background?.uri?.url
          },
        }),
      },
      newsSection: {
        resolve: (_, attrs) => ({
          showSection: attrs.field_show_news_section,
        }),
      },
    },
  },
  "node--field_guide_page": {
    type: "field_guide_page",
    fields: {
      hero: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_hero_title,
            getTextColumns: () => {
              return attrs.field_hero_content_box.map((item) => {
                return {
                  title: item.field_title,
                  text: stripHtml(item.field_content.processed),
                }
              })
            },
          }
        },
      },
      sectionQuestion: {
        resolve: (value, attrs, data) => {
          const elements = parse(attrs.field_s_first_content.processed)
          return {
            title: elements.find((item) => item.type === "h3")?.props?.children,
            question: elements.find((item) => item.type === "h2")?.props
              ?.children,
          }
        },
      },
      sectionWithCircle: {
        resolve: (value, attrs, data) => {
          return {
            getSegments: () => {
              return attrs.field_s_map_overview.map((item) => {
                const content = parse(item.field_mot_tab_content.processed, {
                  replace: ({ name, children }) => {
                    if (name === "h2") {
                      return (
                        <Heading medium as="h4">
                          {domToReact(children)}
                        </Heading>
                      )
                    } else if (name === "p") {
                      return <Paragraph>{domToReact(children)}</Paragraph>
                    }
                  },
                })
                return {
                  tabLabel: item.field_mot_tab_name,
                  title: item.field_mot_tab_title,
                  subtitle: item.field_mot_tab_subtitle,
                  detailsCaption: item.field_mot_tab_creative_promos,
                  content,
                }
              })
            },
          }
        },
      },
      sectionShowcase: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_s_showcase_title,
            subtitle: attrs.field_s_showcase_subtitle,
          }
        },
      },
    },
  },
  "node--showcase_detail": {
    type: "showcase_detail",
    fields: {
      description: {
        resolve: (value, attrs, data) => {
          return stripHtml(attrs?.field_content?.processed)
        },
      },
      categoryName: {
        resolve: (value, attrs, data) => {
          return attrs.field_showcase_category?.name
        },
      },
      getCategory: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_showcase_category?.name
          }
        },
      },
      getHeaderImageUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            if (!attrs.field_header_image?.uri?.url) return
            return backendUrl + attrs.field_header_image?.uri?.url
          }
        },
      },
      getHeaderVideoUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            if (!attrs.field_header_video?.uri?.url) return
            return backendUrl + attrs.field_header_video?.uri?.url
          }
        },
      },
      map: {
        resolve: (value, attrs, data) => {
          const namedPositions = [
            "clarity",
            "optimism",
            "bravery",
            "clarity-optimism",
            "bravery-optimism",
            "bravery-clarity",
          ]
          return {
            title: attrs.field_maps_title,
            legend: [
              {
                title: "Bravery",
                description: attrs.field_maps_bravery_description,
              },
              {
                title: "Optimism",
                description: attrs.field_maps_optimism_description,
              },
              {
                title: "Clarity",
                description: attrs.field_maps_clarity_description,
              },
            ],
            ballPosition: namedPositions.indexOf(attrs.field_map_position),
          }
        },
      },
      getGallery: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_gallery_images
              ?.map((item) => {
                return {
                  category:
                    item.field_g_image_label || item.field_g_video_label,
                  imageUrl: item.field_g_image_item?.uri?.url
                    ? backendUrl + item.field_g_image_item?.uri?.url
                    : undefined,
                  videoUrl: item.field_g_video_item?.uri?.url
                    ? backendUrl + item.field_g_video_item?.uri?.url
                    : undefined,
                  videoThumbnail: item.field_g_video_thumbnail?.uri?.url
                    ? backendUrl + item.field_g_video_thumbnail?.uri?.url
                    : undefined,
                }
              })
              .filter((item) => item.imageUrl || item.videoThumbnail)
          }
        },
      },
    },
  },
  "node--guidelines_page": {
    type: "guidelines_page",
    fields: {
      hero: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_hero_title,
            subtitle: attrs.field_hero_description,
            backgroundColor: attrs.field_hero_background_color?.color,
            getBackgroundImageUrl: () => {
              return backendUrl + attrs.field_hero_img?.uri?.url
            },
          }
        },
      },
      getTabs: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_guidelines_tabs.map((subpage) => {
              const title = subpage.field_tab_name
              return {
                title,
                url: `/${slugify(title)}`,
              }
            })
          }
        },
      },
      getSubpages: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_guidelines_tabs
          }
        },
      },
    },
  },
  "node--pdf_guidelines_page": {
    type: "pdf_guidelines_page",
    fields: {
      hero: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_hero_title,
            subtitle: attrs.field_hero_description,
            getBackgroundImageUrl: () => {
              return backendUrl + attrs.field_hero_img?.uri?.url
            },
          }
        },
      },
      getTabs: {
        resolve: (value, attrs, data) => {
          return () =>
            attrs.field_guidelines_tabs?.map((item) => ({
              slug: item.field_tab_name.toLowerCase().replaceAll(" ", "-"),
              title: item.field_header,
              subtitle: item.body.processed,
              pdfUrl: backendUrl + item.field_main_pdf?.uri?.url,
              downloadLinks:
                item.field_downloads?.map((file) => {
                  // console.log({ file })
                  return {
                    title: file.field_label,
                    subtitle: file.field_description?.processed,
                    downloadUrl:
                      file.field_url ||
                      backendUrl + file.field_download_file?.uri?.url,
                  }
                }) || [],
            })) || []
        },
      },
    },
  },
  "node--guidelines_logos": {
    type: "guidelines_logos",
    fields: {
      header: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.title,
            subtitle: stripHtml(attrs.field_description.processed),
            ctaText: attrs.field_main_download_cta,
            ctaSummary: stripHtml(
              attrs.field_main_download_description.processed
            ),
            getCtaUrl: () => {
              return backendUrl + attrs.field_main_download_file?.uri?.url
            },
            getImageUrl: () => {
              return (
                backendUrl +
                (attrs.field_hero_img_svg?.uri?.url ||
                  attrs.field_hero_img?.uri?.url)
              )
            },
          }
        },
      },
      getLogoSections: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_logos_sections?.map((section) => {
              const description = parse(section.field_description.processed, {
                replace: ({ name, children }) => {
                  if (name === "p") {
                    return (
                      <Heading semiMedium as="p">
                        {domToReact(children)}
                      </Heading>
                    )
                  }
                },
              })
              return {
                title: section.field_title,
                description,
                ctaText: section.field_donwload_cta,
                getCtaUrl: () => {
                  return backendUrl + section.field_download_file?.uri?.url
                },
                slideshow: section.field_logos_boxes?.map((slide) => {
                  return {
                    caption: slide.field_box_label,
                    getImageUrl: () => {
                      return (
                        backendUrl +
                        (slide.field_box_image_svg?.uri?.url ||
                          slide.field_box_image?.uri?.url)
                      )
                    },
                    getIsPicture: () => {
                      return (
                        slide.field_box_image?.uri?.url &&
                        slide.field_box_image?.filemime === "image/jpeg"
                      )
                    },
                    description: slide.field_box_description.processed,
                  }
                }),
              }
            })
          }
        },
      },
      subBrands: {
        resolve: (value, attrs, data) => {
          const description = parse(
            attrs.field_s_sub_branded_description.processed,
            {
              replace: ({ name, children }) => {
                if (name === "p") {
                  return (
                    <Heading semiMedium as="p">
                      {domToReact(children)}
                    </Heading>
                  )
                }
              },
            }
          )
          return {
            title: attrs.field_s_sub_branded_title,
            description,
            ctaText: attrs.field_s_sub_branded_download_cta,
            getCtaUrl: () => {
              return backendUrl + attrs.field_s_sub_branded_download?.uri?.url
            },
            getLogos: () => {
              return attrs.field_s_sub_branded_logos.map((logo) => {
                const uri = logo.field_download_file?.uri?.url
                return {
                  title: logo.field_donwload_cta,
                  imageUrl:
                    backendUrl +
                    (logo.field_thumbnail_svg?.uri?.url ||
                      logo.field_thumbnail_image?.uri?.url),
                  url: uri ? backendUrl + uri : null,
                  downloadUrl: logo.field_url,
                }
              })
            },
          }
        },
      },
    },
  },
  "node--guidelines_typography": {
    type: "guidelines_typography",
    fields: {
      header: {
        resolve: (value, attrs, data) => {
          const description = parse(attrs.field_description.processed, {
            replace: ({ name, children }) => {
              if (name === "p") {
                return (
                  <Heading semiMedium as="p">
                    {domToReact(children)}
                  </Heading>
                )
              }
            },
          })
          return {
            title: attrs.title,
            description,
            ctaText: attrs.field_hero_cta,
            ctaUrl: attrs.field_hero_url,
            getImageUrl: () => {
              return (
                backendUrl +
                (attrs.field_hero_img_svg?.uri?.url ||
                  attrs.field_hero_img?.uri?.url)
              )
            },
            getFileUrl: () => {
              const path = attrs.field_hero_file?.uri?.url
              return path ? backendUrl + path : null
            },
          }
        },
      },
      sectionFontPreview: {
        resolve: (value, attrs, data) => {
          const description = parse(attrs.field_s_description.processed, {
            replace: ({ name, children }) => {
              if (name === "p") {
                return (
                  <Heading semiMedium as="p">
                    {domToReact(children)}
                  </Heading>
                )
              }
            },
          })
          return {
            title: attrs.field_s_title,
            description,
          }
        },
      },
    },
  },
  "node--guidelines_colors": {
    type: "guidelines_colors",
    fields: {
      header: {
        resolve: (value, attrs, data) => {
          const description = parse(attrs.field_description.processed, {
            replace: ({ name, children }) => {
              if (name === "p") {
                return (
                  <Heading semiMedium as="p">
                    {domToReact(children)}
                  </Heading>
                )
              }
            },
          })
          return {
            title: attrs.title,
            description,
            getImageUrl: () => {
              return (
                backendUrl +
                (attrs.field_hero_img_svg?.uri?.url ||
                  attrs.field_hero_img?.uri?.url)
              )
            },
          }
        },
      },
      sectionColor: {
        resolve: (value, attrs, data) => {
          const description = parse(attrs.field_s_color_description.processed, {
            replace: ({ name, children }) => {
              if (name === "p") {
                return <Paragraph>{domToReact(children)}</Paragraph>
              }
            },
          })
          return {
            title: attrs.field_s_color_title,
            description,
            colorCard: {
              name: attrs.field_s_color_name,
              cmyk: attrs.field_s_color_cmyk,
              rgb: attrs.field_s_color_rgb,
              hex: attrs.field_s_color_hex.replace("#", ""),
              pms: attrs.field_s_color_pantone,
            },
          }
        },
      },
      sectionHistory: {
        resolve: (value, attrs, data) => {
          const description = parse(
            attrs.field_s_history_description.processed,
            {
              replace: ({ name, children }) => {
                if (name === "p") {
                  return (
                    <Heading semiMedium as="p">
                      {domToReact(children)}
                    </Heading>
                  )
                }
              },
            }
          )
          return {
            title: attrs.field_s_history_title,
            description,
          }
        },
      },
      sectionPalette: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_s_pp_title,
            description: attrs.field_s_pp_description,
            getColors: () => {
              return attrs.field_s_pp_swatches?.map((color) => {
                return {
                  name: color.field_color_name,
                  cmyk: color.field_color_cmyk,
                  rgb: color.field_color_rgb,
                  hex: color.field_color_hex,
                  pms: color.field_color_pantone,
                }
              })
            },
          }
        },
      },
    },
  },
  "node--resources_page": {
    type: "resources_page",
    fields: {
      hero: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_hero_title,
            subtitle: attrs.field_hero_description,
            backgroundColor: attrs.field_hero_background_color?.color,
            getBackgroundImageUrl: () => {
              return backendUrl + attrs.field_hero_img?.uri?.url
            },
          }
        },
      },
      navigation: {
        resolve: (value, attrs, data) => {
          return [
            {
              title: attrs.field_assets_tab_name,
            },
            {
              title: attrs.field_templates_tab_name,
            },
            {
              title: attrs.field_faq_tab_name,
            },
          ]
        },
      },
      sectionAssets: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_assets_tab_title,
            subtitle: stripHtml(attrs.field_assets_tab_description.processed),
          }
        },
      },
      sectionFeaturedAsset: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_templates_tab_title,
            subtitle: stripHtml(
              attrs.field_templates_tab_description.processed
            ),
            backgroundColor: attrs.field_templates_tab_color.color,
          }
        },
      },
      sectionFaq: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_faq_tab_title,
            subtitle: stripHtml(attrs.field_faq_tab_description.processed),
          }
        },
      },
    },
  },
  "taxonomy_term--asset_category": {
    type: "taxonomy_term/asset_category",
    fields: {
      description: {
        resolve: (value, attrs, data) => {
          return stripHtml(attrs?.description?.processed)
        },
      },
      getImageUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            return backendUrl + attrs.field_thumbnail_image?.uri?.url
          }
        },
      },
      getDownloadUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            const uri = attrs.field_download_file?.uri?.url
            return attrs.field_url
              ? attrs.field_url
              : uri
              ? backendUrl + uri
              : undefined
          }
        },
      },
    },
  },
  "node--asset_item": {
    type: "asset_item",
    fields: {
      getDownloadUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            const uri = attrs.field_download_file?.uri?.url
            return attrs.field_url
              ? attrs.field_url
              : uri
              ? backendUrl + uri
              : undefined
          }
        },
      },
      getCategoryName: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_asset_category?.name
          }
        },
      },
    },
  },
  "node--template": {
    type: "template",
    fields: {
      description: {
        resolve: (value, attrs, data) => {
          return stripHtml(attrs.field_description.processed)
        },
      },
      buttonText: {
        resolve: (value, attrs, data) => {
          return attrs.field_download_cta
        },
      },
      getDownloadUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            return backendUrl + attrs.field_download_file?.uri?.url
          }
        },
      },
      fileName: {
        resolve: (value, attrs, data) => {
          return attrs.field_filename
        },
      },
      date: {
        resolve: (value, attrs, data) => {
          return attrs.field_date
        },
      },
      getFirstImageUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            return backendUrl + attrs.field_thumbnail_image?.uri?.url
          }
        },
      },
      getSecondImageUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            return backendUrl + attrs.field_thumbnail_image_second?.uri?.url
          }
        },
      },
    },
  },
  "node--faq": {
    type: "faq",
    fields: {
      getCategoryName: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_faq_category?.name
          }
        },
      },
      question: {
        resolve: (value, attrs, data) => {
          return attrs.field_question
        },
      },
      answer: {
        resolve: (value, attrs, data) => {
          return parse(attrs.field_answer.processed, {
            replace: ({ name, children }) => {
              if (name === "p") {
                return <Paragraph>{domToReact(children)}</Paragraph>
              }
            },
          })
        },
      },
    },
  },
  "node--color_history_detail": {
    type: "color_history_detail",
    fields: {
      category: {
        resolve: (value, attrs, data) => {
          return attrs.field_history_date
        },
      },
      getImageUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            return backendUrl + attrs.field_thumbnail_image?.uri?.url
          }
        },
      },
      getStory: {
        resolve: (value, attrs, data) => {
          return () => {
            return {
              title: attrs.title,
              category: attrs.field_history_date,
              items: attrs.field_slides,
            }
          }
        },
      },
    },
  },
  "paragraph--colod_detail_slide": {
    type: "colod_detail_slide",
    fields: {
      title: {
        resolve: (value, attrs, data) => {
          return attrs.field_slide_title
        },
      },
      description: {
        resolve: (value, attrs, data) => {
          return attrs.field_slide_description.processed
        },
      },
      getImageUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            return backendUrl + attrs.field_slide_background_image?.uri?.url
          }
        },
      },
      getColors: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_color_swatches?.map(
              (item) => item?.field_color?.color
            )
          }
        },
      },
      theme: {
        resolve: (value, attrs, data) => {
          return attrs.field_slide_layout
        },
      },
    },
  },
  "node--molecule_main_page": {
    type: "molecule_main_page",
    fields: {
      subtitle: {
        resolve: (value, attrs, data) => {
          return attrs.field_mol_dl_title
        },
      },
      buttonText: {
        resolve: (value, attrs, data) => {
          return attrs.field_mol_dl_cta
        },
      },
      getButtonUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            return backendUrl + attrs.field_mol_dl_file?.uri?.url
          }
        },
      },
      finalButtonText: {
        resolve: (value, attrs, data) => {
          return attrs.field_view_btn_cta
        },
      },
      finalButtonUrl: {
        resolve: (value, attrs, data) => {
          return attrs.field_view_btn_url
        },
      },
      hoverIndicatorText: {
        resolve: (value, attrs, data) => {
          return attrs.field_mol_hoover_cta
        },
      },
      getParts: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_mol_parts.map((part) => {
              const html = part.field_p_mol_part_desc?.processed
              return {
                title: part.field_p_mol_part_label,
                description:
                  html &&
                  parse(html, {
                    replace: ({ name, children }) => {
                      if (name === "p") {
                        return <Paragraph>{domToReact(children)}</Paragraph>
                      }
                    },
                  }),
              }
            })
          }
        },
      },
    },
  },
  "node--molecule_part_page": {
    type: "molecule_part_page",
    fields: {
      content: {
        resolve: (value, attrs, data) => {
          return (
            <>
              <Heading
                moleculeLarge
                className="-word-spacing"
                style={{ maxWidth: 700 }}
              >
                <SplitWords>{attrs.title}</SplitWords>
              </Heading>
              <Heading
                moleculeSubheading
                as="h3"
                className="-word-spacing -delay-small"
              >
                <SplitWords>{attrs.field_subtitle}</SplitWords>
              </Heading>
            </>
          )
        },
      },
      subtitle: {
        resolve: (value, attrs, data) => {
          return attrs.field_subtitle
        },
      },
      getSlides: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_slides
          }
        },
      },
      drawerLayout: {
        resolve: (value, attrs, data) => {
          return attrs.field_values_layout
        },
      },
      getValues: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_values
          }
        },
      },
      getTones: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_tones
          }
        },
      },
    },
  },
  "paragraph--mol_slides": {
    type: "mol_slides",
    fields: {
      alignCenter: {
        resolve: (value, attrs, data) => {
          const html = attrs.field_p_mol_slide_content.processed
          const isH3H5 = html.includes("<h3>") && html.includes("<h5>")
          const hasCta = attrs.field_p_mol_btn_cta?.length > 0
          return !isH3H5 && !hasCta
        },
      },
      buttonText: {
        resolve: (value, attrs, data) => {
          return attrs.field_p_mol_btn_cta
        },
      },
      buttonUrl: {
        resolve: (value, attrs, data) => {
          return attrs.field_p_mol_btn_url
        },
      },
      content: {
        resolve: (value, attrs, data) => {
          const html = attrs.field_p_mol_slide_content.processed.replace(
            />\s+</g,
            "><"
          )
          const content = parse(html, {
            replace: ({ name, children, prev, next }) => {
              const hasCta = attrs.field_p_mol_btn_cta?.length > 0
              let className = ""
              if (name === "h2") {
                if (prev?.name === "h4") {
                  className = "-delay-small"
                  return (
                    <Heading moleculeLarge className={className}>
                      {domToReact(children)}
                    </Heading>
                  )
                } else {
                  const style = { maxWidth: 780 }
                  if (prev?.name === "h2") {
                    style.display = "inline-block"
                    if (next?.name === "h2") {
                      className = "-delay-small"
                      style.marginRight = "0.28em"
                    } else {
                      className = "-delay-medium"
                    }
                  }
                  return (
                    <Heading extraLarge className={className} style={style}>
                      {domToReact(children)}
                    </Heading>
                  )
                }
              } else if (name === "h3") {
                const delay = prev?.name === "h3" ? "medium" : "small"
                className = `-word-spacing -delay-${delay}`
                return (
                  <Heading moleculeMedium as="h3" className={className}>
                    <SplitWords>{domToReact(children)}</SplitWords>
                  </Heading>
                )
              } else if (name === "h4") {
                className = prev?.name === "h2" ? `-delay-small` : ""
                return (
                  <Heading
                    moleculeLargeText
                    as="h4"
                    className={className}
                    style={{ maxWidth: 780 }}
                  >
                    {domToReact(children)}
                  </Heading>
                )
              } else if (name === "h5") {
                className = "-word-spacing"
                return (
                  <Heading moleculeSmall as="h5" className={className}>
                    <SplitWords>{domToReact(children)}</SplitWords>
                  </Heading>
                )
              } else if (name === "p") {
                className = "-delay-small"
                if (hasCta) {
                  className =
                    prev?.name === "p"
                      ? next?.name === "p"
                        ? "-delay-1"
                        : "-delay-2"
                      : ""
                }
                const style = hasCta ? { maxWidth: 550 } : null
                return (
                  <Heading
                    moleculeSubheading
                    as="p"
                    className={className}
                    style={style}
                  >
                    {domToReact(children)}
                  </Heading>
                )
              }
            },
          })
          return content
        },
      },
    },
  },
  "paragraph--molecule_values": {
    type: "molecule_values",
    fields: {
      title: {
        resolve: (value, attrs, data) => {
          return attrs.field_p_mol_val_title
        },
      },
      description: {
        resolve: (value, attrs, data) => {
          return stripHtml(attrs.field_p_mol_val_content?.processed)
        },
      },
    },
  },
  "paragraph--molecule_tone": {
    type: "molecule_tone",
    fields: {
      title: {
        resolve: (value, attrs, data) => {
          return attrs.field_p_mol_val_title
        },
      },
      description: {
        resolve: (value, attrs, data) => {
          return stripHtml(attrs.field_p_mol_val_content?.processed)
        },
      },
      content: {
        resolve: (value, attrs, data) => {
          const html = attrs.field_p_mol_val_bottom_content?.processed
          return (
            html &&
            parse(html, {
              replace: ({ name, children }) => {
                if (name === "h2") {
                  return (
                    <Heading small as="h4">
                      {domToReact(children)}
                    </Heading>
                  )
                } else if (name === "p") {
                  return <Paragraph>{domToReact(children)}</Paragraph>
                }
              },
            })
          )
        },
      },
    },
  },
  "node--news_page": {
    type: "news_page",
    fields: {
      hero: {
        resolve: (value, attrs, data) => {
          return {
            title: attrs.field_hero_title,
            description: attrs.field_hero_description,
            getBackgroundImageUrl: () => {
              return backendUrl + attrs.field_hero_img?.uri?.url
            },
          }
        },
      },
      getFeaturedNews: {
        resolve: (value, attrs, data) => {
          return () => {
            return attrs.field_featured_news
          }
        },
      },
    },
  },
  "node--article": {
    type: "article",
    fields: {
      hero: {
        resolve: (value, attrs, data) => {
          return {
            backgroundColor: rgba(
              attrs.field_hero_background_color.color,
              attrs.field_hero_background_color.opacity
            ),
            getBackgroundImageUrl: () => {
              return backendUrl + attrs.field_hero_img?.uri?.url
            },
            getTextColumns: () => {
              return (
                attrs.field_hero_content_box &&
                attrs.field_hero_content_box.map((item) =>
                  stripHtml(item.field_content?.processed)
                )
              )
            },
          }
        },
      },
      date: {
        resolve: (value, attrs, data) => {
          const date = new Date(attrs.created)
          return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        },
      },
      getThumbnailUrl: {
        resolve: (value, attrs, data) => {
          return () => {
            return backendUrl + attrs.field_thumbnail_image?.uri?.url
          }
        },
      },
      excerpt: {
        resolve: (value, attrs, data) => {
          function truncate(str, n, useWordBoundary) {
            if (str.length <= n) {
              return str
            }
            const subString = str.substr(0, n - 1)
            return (
              (useWordBoundary
                ? subString.substr(0, subString.lastIndexOf(" "))
                : subString) + "…"
            )
          }
          return truncate(stripHtml(attrs.field_content.processed), 170, true)
        },
      },
      content: {
        resolve: (value, attrs, data) => {
          const html = attrs.field_content?.processed
          return (
            html &&
            parse(html, {
              replace: ({ name, children }) => {
                children &&
                  children.map((item) => {
                    if (item.name === "img") {
                      item.attribs.src = backendUrl + item.attribs.src
                      item.attribs.width = null
                      item.attribs.height = null
                    }
                    return item
                  })
                if (name === "h2") {
                  return (
                    <Heading medium as="h2" style={{ fontWeight: 700 }}>
                      {domToReact(children)}
                    </Heading>
                  )
                } else if (name === "h3") {
                  return (
                    <Heading
                      small
                      as="h3"
                      style={{ textTransform: "none", letterSpacing: 0 }}
                    >
                      {domToReact(children)}
                    </Heading>
                  )
                } else if (name === "p") {
                  return <Paragraph>{domToReact(children)}</Paragraph>
                }
              },
            })
          )
        },
      },
    },
  },
}

export default schema
