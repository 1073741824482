import { APP_INSIGHSTS_PROPERTIES } from "config/constants"
import { appInsights } from "index"

//
// @param {string} name - The name of the event to track
// @param {object} properties - The properties to track
export function trackEvent(name, properties = {}) {
  appInsights.trackEvent({
    name: name,
    properties: {
      // add parameter(s) related to event
      ...properties,
      // compulsory common properties
      ...APP_INSIGHSTS_PROPERTIES,
      cust_screen_resolution: `${window.screen.availHeight}x${window.screen.availWidth}`,
      cust_locale: navigator.language,
    },
  })
}

//
// @param {string} name - The name of the page
export function trackPageView(name) {
  appInsights.trackPageView({
    name: name,
    properties: {
      // compulsory common properties
      ...APP_INSIGHSTS_PROPERTIES,
      cust_screen_resolution: `${window.screen.availHeight}x${window.screen.availWidth}`,
      cust_locale: navigator.language,
    },
  })
}
