import Button from "components/Button"
import { Link } from "react-router-dom"
import { Paragraph } from "components/Typography"
import { rgba } from "polished"
import styled from "styled-components/macro"
import { useMemo } from "react"

function Legend({
  items,
  buttonText,
  buttonUrl,
  finalButtonText,
  finalButtonUrl,
  activeItemIndex,
  className,
  onItemMouseEnter,
  onItemMouseLeave,
}) {
  
  // Remove last item (View the full molecue)
  const legendItems = useMemo(() => {
    return items.slice(0, -1);
  },[items])

  return (
    <Element className={className}>
      <Items className={typeof activeItemIndex === "number" && "-hover"}>
        {legendItems.map(({ title, description }, index) => (
          <Item
            className={activeItemIndex === index && "-active"}
            key={index}
            onMouseEnter={() => {
              onItemMouseEnter(index)
            }}
            onMouseLeave={() => {
              onItemMouseEnter()
            }}
          >
            <Title>
              <Count>0{index + 1}</Count> {title}
            </Title>
            <Description>{description}</Description>
          </Item>
        ))}
      </Items>
      <TextLink to="/field-guide">
        <Paragraph>
        Learn how we bring our brand DNA to life in The Field Guide.
        </Paragraph>
      </TextLink>
    </Element>
  )
}

const Element = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  position: absolute;
  top: ${(p) => p.theme.headerHeight / 2}px;
  bottom: 0;
  right: ${(p) => p.theme.sidePadding.small}px;

  @media (min-width: 1150px) {
    width: 588px;
  }

  @media (min-width: 1250px) {
    left: 50%;
    right: auto;
  }

  @media (max-width: 980px) {
    width: 600px;
    left: ${(p) => p.theme.sidePadding.small}px;
    margin: 0 auto;
  }
`

const Items = styled.ul`
  margin-bottom: 35px;

  @media (max-width: 980px) {
    margin-bottom: 45px;
  }
`

const Item = styled.li`
  transition: color 0.35s;

  ${Items}.-hover &:not(.-active) {
    color: ${(p) => rgba(p.theme.color.black, 0.2)};
  }

  @media (max-width: 1149px) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 980px) {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    ${Items}.-hover &:not(.-active) {
      color: inherit;
    }
  }
`

const Title = styled.h3`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.015em;

  @media (min-width: 1150px) {
    float: left;
    margin-top: calc(2vh + 5px);

    ${Item}:first-child & {
      margin-top: 5px;
    }

    @media (min-height: 800px) {
      margin-top: 29px;
    }
  }

  @media (max-width: 1149px) {
    margin-bottom: 10px;
  }
`

const Count = styled.span`
  display: inline-block;

  &::after {
    content: ".";
  }

  @media (min-width: 1150px) {
    width: 44px;

    &::after {
      content: "";
    }
  }
`

const Description = styled.div`
  font-size: 14px;
  line-height: 1.3;

  @media (min-width: 1150px) {
    margin-left: 188px;
    padding: 2vh 0;
    border-top: 1px solid ${(p) => rgba(p.theme.color.black, 0.23)};

    ${Item}:first-child & {
      padding-top: 0;
      border-top-width: 0;
    }

    @media (min-height: 600px) {
      font-size: 16px;
    }

    @media (min-height: 640px) {
      line-height: 1.5;
    }

    @media (min-height: 800px) {
      padding: 24px 0;
    }
  }
`

const TextLink = styled(Link)`
  ${Paragraph} {
    text-decoration: underline;
    font-weight: 700;
  }
`


export default Legend
