import { useEffect } from "react"
import { useLocation } from "react-router-dom"

function ScrollToTop({ position = 0 }) {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, position)
  }, [pathname, position])

  return null
}

export default ScrollToTop
