import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import { rgba } from "polished"
import Container from "components/Container"
import { Heading } from "components/Typography"

function FeaturedItem({ title, date, imageUrl, url }) {
  return (
    <Element>
      <Image src={imageUrl} alt={title} />
      <Content to={url}>
        <Container>
          <Date>{date}</Date>
          <Title>{title}</Title>
          <ReadMore>read</ReadMore>
        </Container>
      </Content>
    </Element>
  )
}

const Element = styled.article`
  display: block;
  margin-bottom: 90px;
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 37.916667vw;
  object-fit: cover;
  pointer-events: none;
`

const Content = styled(Link)`
  display: block;
  padding: 29px 0 34px;
  background-color: #ececec;
`

const Date = styled.p`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${(p) => rgba(p.theme.color.black, 0.8)};
`

const Title = styled(Heading).attrs({
  medium: true,
  "data-size": "medium",
  as: "h3",
})`
  font-weight: 500;

  &&:not(:last-child) {
    margin-bottom: 32px;
  }
`

const ReadMore = styled.span`
  padding-bottom: 4px;
  border-bottom: 2px solid;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.02em;
`

export default FeaturedItem
