import { useState, useRef, useEffect } from "react"
import styled, { css } from "styled-components/macro"
import useWindowSize from "hooks/useWindowSize"
import ovals from "./backgroundOvals.json"

function nearbyPoint(x, y, radius) {
  const r = radius * Math.sqrt(Math.random())
  const theta = Math.random() * 2 * Math.PI
  const x1 = x + r * Math.cos(theta)
  const y1 = y + r * Math.sin(theta)
  return { x: x1, y: y1 }
}

function Background({ isSemiTransparent }) {
  const [isVisible, setVisible] = useState(false)
  const { width, height } = useWindowSize()
  const ref = useRef()
  const contextRef = useRef()
  const radius = 100

  function animate(ovalIndex, time) {
    if (!ref.current) {
      return
    }

    const oval = ovals[ovalIndex]
    if (!oval.startTime) {
      oval.startTime = time || performance.now()
    }

    const duration = 6000 + ovalIndex * 200
    const x = oval.x
    const y = oval.y
    const nextX = oval.nextX
    const nextY = oval.nextY
    const deltaTime = (time - oval.startTime) / duration
    const currentX = x + (nextX - x) * deltaTime
    const currentY = y + (nextY - y) * deltaTime

    if (deltaTime >= 1) {
      // ended
      oval.x = nextX
      oval.y = nextY
      const initialX = window.innerWidth / 2 + oval.initialX
      const initialY = window.innerHeight / 2 + oval.initialY
      const destination = nearbyPoint(initialX, initialY, radius)
      oval.nextX = destination.x
      oval.nextY = destination.y
      oval.startTime = null
      animate(ovalIndex)
      oval.currentX = nextX
      oval.currentY = nextY
    } else {
      oval.currentX = currentX
      oval.currentY = currentY
      requestAnimationFrame((time) => {
        animate(ovalIndex, time)
      })
    }
  }

  function draw() {
    if (!ref.current) {
      return
    }
    const canvas = ref.current
    const context = contextRef.current
    context.clearRect(0, 0, canvas.width, canvas.height)
    ovals.forEach((oval, index) => {
      context.drawImage(oval.img, oval.currentX, oval.currentY)
    })
    requestAnimationFrame(draw)
  }

  useEffect(() => {
    const canvas = ref.current
    contextRef.current = canvas.getContext("2d")
    ovals.forEach((oval, index) => {
      const initialX = window.innerWidth / 2 + oval.initialX
      const initialY = window.innerHeight / 2 + oval.initialY
      const destination = nearbyPoint(initialX, initialY, radius)
      const img = new Image()
      oval.nextX = destination.x
      oval.nextY = destination.y
      oval.x = initialX
      oval.y = initialY
      img.src = require(`assets/images/ovals/${index + 1}.png`)?.default
      oval.img = img
      animate(index)
    })
    draw()
  }, [width, height]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setVisible(true)
  }, [])

  return (
    <Element
      ref={ref}
      isVisible={isVisible}
      isSemiTransparent={isSemiTransparent}
      width={width}
      height={height}
    />
  )
}

const Element = styled.canvas`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  transition: opacity 2s;

  ${(p) =>
    p.isVisible &&
    css`
      opacity: 1;
    `}

  ${(p) =>
    p.isSemiTransparent &&
    css`
      opacity: 0.3;
    `}
`

export default Background
