import { useState, useRef } from "react"
import styled from "styled-components/macro"
import { media } from "styles/breakpoints"
import Observer from "@researchgate/react-intersection-observer"
import Container from "components/Container"
import { Paragraph } from "components/Typography"
import ScrollIndicator from "components/ScrollIndicator"
import onScrollAnimations from "styles/onScrollAnimations"
import { invertedSelection } from "styles/mixins"

function SectionPlain({ content, ctaText }) {
  const [isContentInView, setContentInView] = useState(false)
  const element = useRef()

  function handleIntersection(event) {
    setContentInView(event.isIntersecting)
  }

  return (
    <Element ref={element} className={isContentInView && "-in-view"}>
      <Observer onChange={handleIntersection}>
        <StyledContainer>
          <Content>{content}</Content>
          <ScrollIndicator parentRef={element}>{ctaText}</ScrollIndicator>
        </StyledContainer>
      </Observer>
    </Element>
  )
}

const Element = styled.section`
  padding: 25px 20px 50px;
  background-color: ${(p) => p.theme.color.black};
  text-align: center;
  color: ${(p) => p.theme.color.white};
  ${invertedSelection};

  ${media.smallTablet} {
    padding: 160px 0 130px;
  }
`

const StyledContainer = styled(Container)`
  ${onScrollAnimations(Element)};
`

const Content = styled.div`
  max-width: 890px;
  margin-bottom: 28px;

  ${media.ltSmallTablet} {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.42857143;
  }

  ${media.smallTablet} {
    margin: 0 auto 200px;

    ${Paragraph} {
      font-size: 30px;
      line-height: 1.3333333;

      &:first-of-type {
        margin-top: 63px;
      }
    }
  }
`

export default SectionPlain
