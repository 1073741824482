import Container from "components/Container"
import FeaturedNewsItem from "components/NewsList/FeaturedItem"
import { Heading } from "components/Typography"
import { Helmet } from "react-helmet"
import Layout from "layouts"
import Loader from "components/Loader"
import NewsList from "components/NewsList"
import { ROUTE_TITLES } from "config/constants"
import { getNonceValue } from "helpers/getNonceValue"
import { media } from "styles/breakpoints"
import slugify from "helpers/slugify"
import styled from "styled-components/macro"
import { useQuery } from "jsonapi-react"

function Main() {
  const { data, isLoading, error } = useQuery([
    "node/news_page",
    {
      include: ["field_hero_img", "field_featured_news"],
      nonce: getNonceValue(),
    },
  ])
  const { data: articles } = useQuery([
    "node/article",
    {
      include: ["field_thumbnail_image"],
      nonce: getNonceValue(),
    },
  ])
  const page = data && data[0]
  const { hero, getFeaturedNews } = {
    ...page,
  }
  const featuredNews = page && getFeaturedNews()
  const newsList =
    articles?.sort((a, b) => {
      return new Date(b.created) - new Date(a.created)
    }) || []

  return (
    <>
      <Helmet>
        <title>{ROUTE_TITLES["/news"]}</title>
      </Helmet>
      {isLoading || error ? (
        <Loader error={error} />
      ) : (
        <Layout>
          <Content>
            <Heading moleculeExtraLarge dataSize="moleculeExtraLarge" as="h1">
              {hero.title}
            </Heading>
            <Subtitle dangerouslySetInnerHTML={{ __html: hero.description }} />
          </Content>
          <FeaturedNewsItem
            title={featuredNews.title}
            date={featuredNews.date}
            imageUrl={hero.getBackgroundImageUrl()}
            url={`/news/${slugify(featuredNews.title)}`}
            isFeatured
          />
          <Container>
            <NewsList items={newsList} />
          </Container>
        </Layout>
      )}
    </>
  )
}

const Subtitle = styled(Heading).attrs({
  semiMedium: true,
  "data-size": "semiMedium",
  as: "p",
})`
  ${media.ltSmallTablet} {
    font-size: ${(p) => p.theme.baseFontSize};
  }

  ${media.smallTablet} {
    max-width: 55vw;
    white-space: pre-line;
  }
`

const Content = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 37.916667vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default Main
