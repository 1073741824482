import { Heading, Text } from "components/Typography"
import { useRef, useState } from "react"

import LayeredFigure from "components/LayeredFigure"
import Observer from "@researchgate/react-intersection-observer"
import { media } from "styles/breakpoints"
import styled from "styled-components/macro"
import theme from "styles/theme"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

function Header({ title, text }) {
  const [isInView, setInView] = useState(false)
  const [scrollProgress, setScrollProgress] = useState(0)
  const ref = useRef()

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  useScrollPosition(
    ({ currPos }) => {
      if (!isInView) return
      const trackLength = window.innerHeight * 0.9 - theme.headerHeight
      const progress = Math.min(
        Math.max((window.innerHeight - currPos.y) / trackLength, 0),
        1
      )
      setScrollProgress(progress)
    },
    [isInView],
    ref
  )

  return (
    <Observer onChange={handleIntersection}>
      <Element
        ref={ref}
        style={{
          "--header-scroll-progress": scrollProgress,
        }}
      >
        <Content>
          <Heading extraLarge>{title}</Heading>
          <Text dangerouslySetInnerHTML={{ __html: text }} />
        </Content>
        <StyledLayeredFigure size="small" />
      </Element>
    </Observer>
  )
}

const Element = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`

const Content = styled.div`
  opacity: calc(var(--header-scroll-progress, 0) * 1.5);
  width: 40.9091%;
  margin-right: 7.79221%;

  ${media.ltMediumTablet} {
    width: 50%;
    margin-right: 0;
  }
`

const StyledLayeredFigure = styled(LayeredFigure)`
  width: 50%;
  height: 420.47px;
  position: relative;

  ${media.ltMediumTablet} {
    width: 44%;
    transform: scale(0.7);
    transform-origin: 0 50%;
  }

  ${media.smallDesktop} {
    width: 44.399351%;
  }
`

export default Header
