import Header from "components/Header"
import Footer from "components/Footer"
import ScrollToTop from "components/ScrollToTop"

function FullLayout({ scrollToTopPosition, children }) {
  return (
    <div className="App">
      <Header />
      {children}
      <Footer />
      <ScrollToTop position={scrollToTopPosition} />
    </div>
  )
}

export default FullLayout
