import Container from "components/Container"
import { Heading } from "components/Typography"
import Observer from "@researchgate/react-intersection-observer"
import onScrollAnimations from "styles/onScrollAnimations"
import styled from "styled-components/macro"
import { useState } from "react"

function SectionQuestion({ title, question }) {
  const [isContentInView, setContentInView] = useState(false)

  function handleIntersection(event) {
    setContentInView(event.isIntersecting)
  }

  return (
    <Element className={isContentInView && "-in-view"}>
      <Observer onChange={handleIntersection}>
        <StyledContainer>
          <Title>{title}</Title>
          <Question>{question}</Question>
        </StyledContainer>
      </Observer>
    </Element>
  )
}

const Element = styled.section`
  padding-bottom: 300px;
  position: relative;
  text-align: center;
`

const StyledContainer = styled(Container)`
  ${onScrollAnimations(Element)};
`

const Title = styled.h3`
  margin-bottom: 70px;
  font-size: 40px;
  line-height: 1.2;
`

const Question = styled(Heading).attrs({
  extraLarge: true,
  as: "h2",
})`
  max-width: 910px;
  margin-left: auto;
  margin-right: auto;

  strong {
    display: inline-block;
    position: relative;
    border-bottom: 2px solid;
    font-style: inherit;
    line-height: .85em;

    &:hover em {
      &::after {
        opacity: 0;
      }
    }

    &:not(:hover) code {
      visibility: hidden;
      opacity: 0;
      transform: scale(0.8);
    }
  }

  code {
    width: 330px;
    padding: 26px 24px;
    position: absolute;
    top: 120px;
    right: -16px;
    z-index: 1;
    transform-origin: 100% 0;
    background-color: ${(p) => p.theme.color.black};
    text-align: initial;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    font-family: inherit;
    color: ${(p) => p.theme.color.white};
    transition: 0.5s ${(p) => p.theme.easeOutQuart};
    transition-property: visibility, opacity, transform;
    will-change: transform;
    pointer-events: none;
  }

  em {
    width: 19px;
    height: 19px;
    position: absolute;
    top: -12px;
    right: -16px;
    background-color: currentColor;
    border-radius: 50%;
    font-size: 0;

    &::before,
    &::after {
      content: "";
      width: 7px;
      height: 1px;
      position: absolute;
      top: 9px;
      left: 6px;
      background-color: ${(p) => p.theme.color.white};
    }

    &::after {
      transform: rotate(90deg);
      transition: opacity 0.25s;
    }
  }
`

export default SectionQuestion
