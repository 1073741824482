import * as Styled from "./HomepageNews.styled"

import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import slugify from "helpers/slugify"

function HomepageNews({ data }) {
  return (
    <Styled.Container>
      {data.map(({ title, excerpt, getThumbnailUrl, field_url }, i) => (
        <Link key={i} to={field_url ? field_url : `/news/${slugify(title)}`}>
          <Styled.Article>
            <div style={{ backgroundImage: `url(${getThumbnailUrl()})` }} />
            <span>Typography</span>
            <h3>{title}</h3>
            <p>{excerpt}</p>
          </Styled.Article>
        </Link>
      ))}
    </Styled.Container>
  )
}

HomepageNews.prototypes = {
  data: PropTypes.array.isRequired,
}

export { HomepageNews }
