import styled from "styled-components/macro"

function Numbers({
  isVisible,
  items,
  activeItemIndex,
  onItemMouseEnter,
  onItemMouseLeave,
}) {
  return (
    <Element
      data-active-item={activeItemIndex}
      className={isVisible && "-visible"}
    >
      {items.map((name, index) => (
        <Number
          className={activeItemIndex === index && "-active"}
          key={index}
          onMouseEnter={() => {
            onItemMouseEnter(index)
          }}
          onMouseLeave={() => {
            onItemMouseEnter()
          }}
        >
          0{index + 1}
        </Number>
      ))}
    </Element>
  )
}

const Element = styled.div`
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ${(p) => p.theme.easeOutQuart};

  &.-visible {
    opacity: 1;
    transition-duration: 2s;
  }
`

const Number = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 2;
  background-color: ${(p) => p.theme.color.white};
  border: 2px solid;
  border-radius: 50%;
  font-weight: 700;
  font-size: 20px;
  user-select: none;
  transition: 0.5s ${(p) => p.theme.easeOutQuart};
  transition-property: opacity, transform;

  &:nth-child(1) {
    top: 259px;
    left: 439px;
  }

  &:nth-child(2) {
    top: 214px;
    left: 260px;
  }

  &:nth-child(3) {
    top: 77px;
    left: 325px;
  }

  &:nth-child(4) {
    top: 376px;
    left: 173px;
  }

  &:nth-child(5) {
    top: 140px;
    left: 56px;
  }

  &.-active {
    transform: scale(1.28);
  }

  ${Element}:not(.-visible) & {
    transform: scale(0);
  }

  ${Element}[data-active-item] &:not(.-active) {
    opacity: 0.4;
  }
`

export default Numbers
