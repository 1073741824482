import * as Styled from "./IntroducingGreen.styled"

import Button from "components/Button"
import PropTypes from "prop-types"
import React from "react"

function IntroducingGreen({
  title,
  description,
  ctaTitle,
  ctaUrl,
  backgroundImageUrl,
}) {
  return (
    <Styled.Container style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      {/* <Styled.CImage  /> */}
      <Styled.Content>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        <Button to={ctaUrl}>{ctaTitle}</Button>
      </Styled.Content>
    </Styled.Container>
  )
}

IntroducingGreen.prototypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ctaTitle: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
}

export { IntroducingGreen }
