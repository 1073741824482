import { Heading, Paragraph } from "components/Typography"
import styled, { css } from "styled-components/macro"

import Background from "./Background"
import Button from "components/Button"
import Drawer from "components/Drawer"
import LayeredFigure from "components/LayeredFigure"
import Legend from "./Legend"
import Loader from "components/Loader"
import ScrollIndicator from "./ScrollIndicator"
import ScrollWheelHandler from "components/ScrollWheelHandler"
import Slide from "./Slide"
import { getNonceValue } from "helpers/getNonceValue"
import isTouchDevice from "helpers/isTouchDevice"
import { media } from "styles/breakpoints"
import { useQuery } from "jsonapi-react"
import useScrollLock from "hooks/useScrollLock"
import { useState } from "react"

function MoleculeStory() {
  const { data, isLoading, error } = useQuery([
    "node/molecule_main_page",
    {
      include: [
        "field_mol_dl_file",
        "field_mol_parts",
        "field_mol_parts.paragraph_type",
      ],
      nonce: getNonceValue(),
    },
  ])
  const { data: parts, isLoading: arePartsLoading } = useQuery([
    "node/molecule_part_page",
    {
      include: ["field_slides", "field_values", "field_tones"],
      nonce: getNonceValue(),
    },
  ])
  const intro = data && data[0]
  const {
    title,
    subtitle,
    buttonText,
    getButtonUrl,
    finalButtonText,
    finalButtonUrl,
    getParts,
  } = {
    ...intro,
  }
  const mainParts = typeof getParts === "function" ? getParts() : []
  const sections =
    parts &&
    mainParts?.map((part, sectionIndex) => {
      return {
        title: part.title,
        getSlideIndex: () => {
          return getSlideIndex(sectionIndex)
        },
      }
    })
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [prevActiveSlideIndex, setPrevActiveSlideIndex] = useState(-1)
  const [valuesDrawerOpen, setValuesDrawerOpen] = useState(false)
  const [toneDrawerOpen, setToneDrawerOpen] = useState(false)
  const [activeLegendItemIndex, setActiveLegendItemIndex] = useState(null)
  const isLayeredFigureBlurred = getLayeredFigureBlurred()
  const activeSectionIndex = getActiveSectionIndex()
  const isolatedLayerIndex = getIsolatedLayerIndex()
  const focusedLayerIndex = getFocusedLayerIndex()
  const lastSlideIndex = parts ? getSlideIndex(parts.length) : 0
  const isNavigationVisible =
    activeSlideIndex > 0 && activeSlideIndex < lastSlideIndex
  let values = []
  let tones = []

  const processedSlides =
    parts
      ?.sort((a, b) => {
        return new Date(a.created) - new Date(b.created)
      })
      .map((part) => {
        const { content, getSlides, drawerLayout, getValues, getTones } = part
        const slides = getSlides()
        const partValues = getValues()
        const partTones = getTones()
        part.slidesLength = slides.length
        if (drawerLayout === "values" && partValues?.length > 0) {
          values = partValues
        }
        if (drawerLayout === "tones" && partTones?.length > 0) {
          tones = partTones
        }
        return [
          {
            content,
          },
          ...slides.map(({ alignCenter, buttonText, buttonUrl, content }) => {
            if (buttonUrl) {
              const length = Object.keys(content).length
              if (content[length - 1].type !== Button) {
                content[length] = (
                  <Button
                    className="-delay-medium"
                    key={length}
                    onClick={() => {
                      if (buttonUrl === "#values") {
                        setValuesDrawerOpen(true)
                      } else if (buttonUrl === "#tone") {
                        setToneDrawerOpen(true)
                      }
                    }}
                  >
                    {buttonText}
                  </Button>
                )
              }
            }
            return {
              buttonUrl,
              alignCenter,
              content,
            }
          }),
        ]
      })
      .flat() || []

  const slides = [
    {
      content: (
        <InitialScreen>
          <Heading moleculeMedium>{title}</Heading>
          <Paragraph>
            <strong>Hover over each layer</strong> to view the molecule parts
            and then scroll to learn in depth below.
          </Paragraph>

          {/* <Button
            iconBox="download"
            to={typeof getButtonUrl === "function" ? getButtonUrl() : ""}
            className="-delay-small"
          >
            {buttonText}
          </Button>
          <HoverIndicator className="-slide-in-left -delay-medium">
            {hoverIndicatorText}
          </HoverIndicator> */}
        </InitialScreen>
      ),
    },
    ...processedSlides,
    {
      content: (
        <Legend
          items={mainParts.filter((part) => part.title !== "At a Glance")}
          buttonText={buttonText}
          buttonUrl={typeof getButtonUrl === "function" ? getButtonUrl() : ""}
          finalButtonText={finalButtonText}
          finalButtonUrl={finalButtonUrl}
          activeItemIndex={activeLegendItemIndex}
          className="-slide-in-left"
          onItemMouseEnter={(index) => {
            handleLegendHover(index)
          }}
          onItemMouseLeave={(index) => {
            handleLegendHover()
          }}
        />
      ),
    },
  ]

  function getSlideIndex(sectionIndex) {
    if (!parts) {
      return 0
    }
    const initialIndex = 1
    let index = 0
    let sum = initialIndex
    for (let i = 0; i < sectionIndex; i++) {
      sum = sum + parts[i].slidesLength + 1
    }
    index = sum
    return index
  }

  function getLayeredFigureBlurred() {
    if (!sections || !sections[1] || !parts) {
      return
    }
    return (
      activeSlideIndex !== 0 &&
      activeSlideIndex !== 1 &&
      activeSlideIndex !== sections[0].getSlideIndex() &&
      activeSlideIndex !== sections[1].getSlideIndex() &&
      activeSlideIndex !== sections[2].getSlideIndex() &&
      activeSlideIndex !== sections[3].getSlideIndex() &&
      activeSlideIndex !== sections[4].getSlideIndex() &&
      activeSlideIndex !== sections[5].getSlideIndex()
    )
  }

  function getIsolatedLayerIndex() {
    if (!sections || !sections[1] || !parts) {
      return 0
    }
    if (
      activeSlideIndex >= sections[0].getSlideIndex() &&
      activeSlideIndex < sections[1].getSlideIndex()
    ) {
      return 4
    } else if (
      activeSlideIndex >= sections[1].getSlideIndex() &&
      activeSlideIndex < sections[2].getSlideIndex()
    ) {
      return 3
    } else if (
      activeSlideIndex >= sections[2].getSlideIndex() &&
      activeSlideIndex < sections[3].getSlideIndex()
    ) {
      return 2
    } else if (
      activeSlideIndex >= sections[3].getSlideIndex() &&
      activeSlideIndex < sections[4].getSlideIndex()
    ) {
      return 1
    } else if (
      activeSlideIndex >= sections[4].getSlideIndex() &&
      activeSlideIndex < sections[5].getSlideIndex()
    ) {
      return 0
    } else {
      return null
    }
  }

  function getFocusedLayerIndex() {
    if (!sections || !sections[1] || !parts) {
      return
    }
    if (activeSlideIndex === 1) {
      return 4
    } else if (activeSlideIndex === sections[1].getSlideIndex()) {
      return 3
    } else if (activeSlideIndex === sections[2].getSlideIndex()) {
      return 2
    } else if (activeSlideIndex === sections[3].getSlideIndex()) {
      return 1
    } else if (activeSlideIndex === sections[4].getSlideIndex()) {
      return 0
    }
  }

  function getActiveSectionIndex() {
    if (!sections || !sections[1] || !parts) {
      return
    }
    let sectionIndex
    for (let i = 0; i < sections.length; i++) {
      if (activeSlideIndex < sections[i].getSlideIndex()) {
        sectionIndex = i - 1
        break
      }
    }
    return sectionIndex
  }

  function goToSlideIndex(index) {
    setActiveSlideIndex(sections[index].getSlideIndex())
  }

  function handleScroll(direction) {
    let index =
      direction === "down" ? activeSlideIndex + 1 : activeSlideIndex - 1
    if (index < 0) {
      index = 0
    } else if (index >= slides.length) {
      index = slides.length - 1
    }
    setPrevActiveSlideIndex(activeSlideIndex)
    setActiveSlideIndex(index)
  }

  function handleLegendHover(index) {
    setActiveLegendItemIndex(index)
  }

  useScrollLock()

  return isLoading || arePartsLoading || error ? (
    <Loader error={error} />
  ) : (
    <ScrollWheelHandler
      upHandler={(e) => handleScroll("up")}
      downHandler={(e) => handleScroll("down")}
      disableSwipe={!isTouchDevice()}
      timeout={1200}
      wheelConfig={[7, 10, 0.05]}
    >
      <DownloadButton
        iconBox="download"
        to={getButtonUrl()}
        toTextOnHover="download the molecule"
        toTextOnHoverWidth={230}
      />
      <Element>
        {slides?.map(({ alignCenter, content }, index) => (
          <Slide
            alignCenter={alignCenter}
            active={activeSlideIndex === index}
            prevActive={prevActiveSlideIndex === index}
            className={
              (activeSlideIndex === index && "-active") ||
              (prevActiveSlideIndex === index && "-prev-active")
            }
            key={index}
          >
            {content}
          </Slide>
        ))}
        <StyledLayeredFigure
          size="large"
          alignLeft={activeSlideIndex === lastSlideIndex}
          hasHotspots={activeSlideIndex === 0}
          hasNumbers={activeSlideIndex === lastSlideIndex}
          isBlurred={isLayeredFigureBlurred}
          isolatedLayerIndex={isolatedLayerIndex}
          focusedLayerIndex={focusedLayerIndex}
          activeNumberIndex={activeLegendItemIndex}
          onNumberMouseEnter={(index) => {
            handleLegendHover(index)
          }}
          onNumberMouseLeave={(index) => {
            handleLegendHover()
          }}
        />

        <ScrollIndicator isVisible={activeSlideIndex < 1}>
          <button onClick={() => goToSlideIndex(activeSlideIndex)}>
            Take me on the journey
          </button>
          <button onClick={() => goToSlideIndex(sections.length - 1)}>
            Cut to the chase!
          </button>
        </ScrollIndicator>

        <Navigation isVisible={isNavigationVisible}>
          {sections?.map(({ title }, index) => (
            <NavigationItem key={index}>
              <NavigationLink type="link" onClick={() => goToSlideIndex(index)}>
                <div dangerouslySetInnerHTML={{ __html: title }} />
                <NavigationLinkHelper isVisible={activeSectionIndex === index}>
                  <NavigationLinkHelperText>{title}</NavigationLinkHelperText>
                </NavigationLinkHelper>
              </NavigationLink>
            </NavigationItem>
          ))}
        </Navigation>
        <Drawer
          type="grid"
          title="Our values"
          content={values}
          open={valuesDrawerOpen}
          onClose={() => {
            setValuesDrawerOpen(false)
          }}
        />
        <Drawer
          type="columns"
          title="Our tone"
          content={tones}
          open={toneDrawerOpen}
          onClose={() => {
            setToneDrawerOpen(false)
          }}
        />
        <Background isSemiTransparent={activeSlideIndex === lastSlideIndex} />
      </Element>
    </ScrollWheelHandler>
  )
}

const Element = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const StyledLayeredFigure = styled(LayeredFigure)`
  width: 559px;
  height: 364px;
  position: absolute;
  top: calc(38% - 182px);
  left: calc(50% - 279.5px);

  ${(p) =>
    !p.hasHotspots &&
    css`
      transform: translateY(20%);
    `}

  ${(p) =>
    p.alignLeft &&
    css`
      transform: translateX(-26vw);

      @media (min-width: 1150px) {
        transform: translateX(-385px);
      }

      @media (max-width: 980px) {
        opacity: 0;
        pointer-events: none;
      }
    `}
`

const Navigation = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  margin-right: -8px;
  position: absolute;
  top: 0;
  right: ${(p) => p.theme.sidePadding.small}px;
  z-index: 1;
  transition: 1s ${(p) => p.easeOutQuart};
  transition-property: visibility, opacity, transform;

  ${(p) =>
    !p.isVisible &&
    css`
      visibility: hidden;
      opacity: 0;
      transform: translateX(50px);
    `}

  ${media.smallDesktop} {
    right: ${(p) => p.theme.sidePadding.medium}px;
  }

  ${media.mediumDesktop} {
    right: ${(p) => p.theme.sidePadding.large}px;
  }

  @media (min-width: ${(p) =>
      p.theme.containerWidth + p.theme.sidePadding.large * 2}px) {
    right: calc((100vw - ${(p) => p.theme.containerWidth}px) / 2);
  }

  ${media.ltMediumTablet} {
    flex-direction: row;
    height: auto;
    top: auto;
    right: 0;
    bottom: 20px;
    left: 0;
  }
`

const NavigationItem = styled.li`
  &:last-child {
    max-width: 100px;
    margin-top: 36px;
    position: relative;
    &::before {
      content: "";
      right: 10px;
      top: -25px;
      height: 1px;
      width: 29px;
      background-color: ${(p) => p.theme.color.black};
      position: absolute;
    }
    button {
      text-align: right;
      height: auto;
      white-space: normal;
    }
  }
  ${media.ltMediumTablet} {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`

const NavigationLink = styled(Button)`
  position: relative;
  border: none;
  transition: none;

  &:hover {
    background-color: transparent;
    color: currentColor;
  }

  ${media.ltMediumTablet} {
    font-size: 12px;
  }
`

const NavigationLinkHelper = styled.span`
  width: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${(p) => p.theme.color.black};
  color: ${(p) => p.theme.color.white};
  transition: width 0.6s;
  will-change: width;

  ${(p) =>
    p.isVisible &&
    css`
      width: 100%;
      transition-delay: 0.6s;
    `}
`

const NavigationLinkHelperText = styled.span`
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  position: absolute;
  top: 0;
  right: 0;
`

const InitialScreen = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Heading} {
    font-weight: 700;
  }
`

const DownloadButton = styled(Button)`
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 10;
`

export default MoleculeStory
