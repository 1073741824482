import styled from "styled-components"
import { default as GlobalContainer } from "components/Container"

export const Container = styled(GlobalContainer)`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 40px;
`

export const Article = styled.article`
  div {
    background-size: cover;
    background-position: center;
    aspect-ratio: 1/1;
    margin-bottom: 16px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
  }
  h3 {
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 16px;
    margin-top: 16px;
    max-width: 400px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    max-width: 480px;
  }
`
