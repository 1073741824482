const colors = {
  white: "#fff",
  black: "#000",
  darkGrey: "#242424",
  green: "#ADDDB7",
  celadon: "#f2fcf1",
}

const theme = {
  // Colors
  color: {
    ...colors,

    text: colors.black,
    background: colors.white,
  },

  // Typography
  font: {
    primary: "Clinique’s Helvetica Neue wf, Helvetica Neue, sans-serif",
  },
  basefontWeight: 400,
  baseFontSize: "18px",
  baseLineHeight: 1.3333333,

  // Layout
  containerWidth: 1520,
  sidePadding: {
    extraSmall: 16,
    small: 25,
    medium: 60,
    large: 104,
  },
  headerHeight: 88,

  // Easing functions
  easeInQuad: "cubic-bezier(0.11, 0, 0.5, 0)",
  easeOutQuad: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
  easeInOutQuad: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
  easeInCubic: "cubic-bezier(0.32, 0, 0.67, 0)",
  easeOutCubic: "cubic-bezier(0.33, 1, 0.68, 1)",
  easeInOutCubic: "cubic-bezier(0.65, 0, 0.35, 1)",
  easeInQuart: "cubic-bezier(0.5, 0, 0.75, 0)",
  easeOutQuart: "cubic-bezier(0.165, 0.84, 0.44, 1)",
  easeInOutQuart: "cubic-bezier(0.77, 0, 0.175, 1)",
  easeInExpo: "cubic-bezier(0.7, 0, 0.84, 0)",
  easeOutExpo: "cubic-bezier(0.19, 1, 0.22, 1)",
  easeInOutExpo: "cubic-bezier(1, 0, 0, 1)",
  easeInCirc: "cubic-bezier(0.55, 0, 1, 0.45)",
  easeOutCirc: "cubic-bezier(0, 0.55, 0.45, 1)",
  easeInOutCirc: "cubic-bezier(0.85, 0, 0.15, 1)",
}

export default theme
