import { Helmet } from "react-helmet"
import { MinimalLayout } from "layouts"
import MoleculeStory from "components/MoleculeStory"
import { ROUTE_TITLES } from "config/constants"

function Molecule() {
  return (
    <MinimalLayout>
      <Helmet>
        <title>{ROUTE_TITLES["/molecule"]}</title>
      </Helmet>
      <MoleculeStory />
    </MinimalLayout>
  )
}

export default Molecule
