import { ROUTE_TITLES } from "config/constants"
import { trackPageView } from "utils/analytics"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

function Analytics() {
  const { pathname } = useLocation()

  useEffect(() => {
    if (ROUTE_TITLES[pathname]) {
      trackPageView(ROUTE_TITLES[pathname])
    }
  }, [pathname])

  return <></>
}

export default Analytics
