import "@egjs/react-flicking/dist/flicking.css"

import styled, { css } from "styled-components/macro"
import { useRef, useState } from "react"

import Flicking from "@egjs/react-flicking"
import { Heading } from "components/Typography"
import Pagination from "components/Pagination"
import { ReactComponent as PlaySvg } from 'assets/icons/play.svg'
import { VideoPlayer } from "components/VideoPlayer"
import { ReactComponent as XIcon } from "assets/icons/x.svg"
import { media } from "styles/breakpoints"

function GallerySlider({ items, className }) {
  const slider = useRef()
  const itemsPerPage = 1
  const pageCount = Math.ceil(items.length / itemsPerPage)
  const [activePageIndex, setActivePageIndex] = useState(0)
  const [isZoomActive, setZoomActive] = useState(false)
  const [zoomedImageIndex, setZoomedImageIndex] = useState(null)
  const fullSizeItem = items[zoomedImageIndex]

  const sliderOptions = {
    align: "prev",
    circular: true,
    renderOnlyVisible: false,
    autoResize: true,
    onWillChange: (e) => {
      setActivePageIndex(Math.floor(e.index / itemsPerPage))
    },
  }

  async function slideTo({ direction, page }) {
    try {
      if (direction) {
        await slider.current[direction]()
      } else if (typeof page === "number") {
        await slider.current.moveTo(page * itemsPerPage)
      }
    } catch (e) {}
  }

  function onImageLoad() {
    // refresh the slider
    window.dispatchEvent(new Event("resize"))
  }

  function zoomIn(index) {
    if (!slider.current.animating) {
      setZoomActive(true)
      setZoomedImageIndex(index)
    }
  }

  function zoomOut() {
    setZoomActive(false)
    setTimeout(() => {
      setZoomedImageIndex(null)
    }, 250)
  }

  return (
    <>
      <Items ref={slider} {...sliderOptions} className={className}>
        {items.map(({ category, imageUrl, videoThumbnail }, index) => (
          <Item key={index} onClick={() => zoomIn(index)}>
            <Category>{category}</Category>
            <Image src={videoThumbnail ? videoThumbnail : imageUrl} alt="" loading="lazy" onLoad={onImageLoad} />
            {videoThumbnail && (
              <>
                <PlayButton>
                  <PlaySvg />
                </PlayButton>
              </>
            )}
          </Item>
        ))}
      </Items>
      <Pagination
        pageCount={pageCount}
        activePageIndex={activePageIndex}
        onPrevClick={() => {
          slideTo({ direction: "prev" })
        }}
        onNextClick={() => {
          slideTo({ direction: "next" })
        }}
        onPageClick={(index) => {
          slideTo({ page: index })
        }}
      />
      <Overlay isActive={isZoomActive} onClick={zoomOut} />
      {fullSizeItem && (
        <>
        {fullSizeItem?.videoUrl ? (
          <>
            <CloseButton onClick={zoomOut} isActive={isZoomActive}>
              <CloseButtonIcon />
            </CloseButton>
            <Player isActive={isZoomActive} videoUrl={fullSizeItem.videoUrl} thumbnailUrl={fullSizeItem.thumbnailUrl}  />
          </>
        ) : (
          <FullSizeImage
            isActive={isZoomActive}
            src={fullSizeItem.imageUrl}
            alt=""
            onClick={zoomOut}
          />
        )}
        </>
      )}
    </>
  )
}

const PlayButton = styled.button`
  position: absolute;
  width: 96px;
  height: 96px;
  border-radius: 100%;
  background-color: ${p => p.theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    cursor: pointer;
    background-color: ${p => p.theme.color.black};
    svg {
      color: ${p => p.theme.color.white};
    }
  }
  svg {
    height: 34px;
    width: 34px;
    transform: translateX(3px);
    color: ${p => p.theme.color.black};
  }
`

const Items = styled(Flicking)`
  margin-left: -${(p) => p.theme.sidePadding.small}px;
  margin-right: -${(p) => p.theme.sidePadding.small}px;
  padding-left: ${(p) => p.theme.sidePadding.small - 1}px;
  padding-top: 48px;
  padding-bottom: 65px;
  position: relative;
  white-space: nowrap;

  ${media.smallDesktop} {
    margin-left: -${(p) => p.theme.sidePadding.medium}px;
    margin-right: -${(p) => p.theme.sidePadding.medium}px;
    padding-left: ${(p) => p.theme.sidePadding.medium - 1}px;
  }

  ${media.mediumDesktop} {
    margin-left: -${(p) => p.theme.sidePadding.large}px;
    margin-right: -${(p) => p.theme.sidePadding.large}px;
    padding-left: ${(p) => p.theme.sidePadding.large - 1}px;
  }

  @media (min-width: ${(p) =>
      p.theme.containerWidth + p.theme.sidePadding.large * 2}px) {
    width: 100vw;
    margin-left: calc((100vw - ${(p) => p.theme.containerWidth}px) / -2);
    margin-right: calc((100vw - ${(p) => p.theme.containerWidth}px) / -2);
    padding-left: calc((100vw - ${(p) => p.theme.containerWidth}px) / 2 - 1px);
  }
`

const Item = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 570px;
  margin-right: 16px;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    opacity: 0.15;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid;
    pointer-events: none;
    transition: opacity 0.25s;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
    ${PlayButton} {
      background-color: ${p => p.theme.color.black};
      svg {
        color: ${p => p.theme.color.white};
      }
    }
  }
`

const Category = styled(Heading).attrs({
  small: true,
  as: "h3",
})`
  position: absolute;
  top: -48px;
  left: 0;
  pointer-events: none;

  &:not(:last-child) {
    margin-bottom: 19px;
  }
`

const Image = styled.img`
  display: block;
  height: 100%;
  image-rendering: -webkit-optimize-contrast;
  pointer-events: none;
`

const Overlay = styled.div`
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: ${(p) => p.theme.color.black};
  transition: 0.4s ${(p) => p.theme.easeInOutQuad};
  transition-property: visibility, opacity;

   ${(p) =>
    !p.isActive &&
    css`
      visibility: hidden;
      opacity: 0;
      transition-duration: 0.25s;
    `}
`

const FullSizeImage = styled.img`
  max-width: calc(100vw - 70px);
  max-height: calc(100vh - 70px);
  margin: auto;
  position: fixed;
  top: 35px;
  left: 35px;
  right: 35px;
  bottom: 35px;
  z-index: 11;
  transition: 0.4s ${(p) => p.theme.easeInOutQuad};
  transition-property: opacity, transform;

   ${(p) =>
    !p.isActive &&
    css`
      opacity: 0;
      transform: scale(0.9);
      pointer-events: none;
      transition-duration: 0.25s;
    `}
`

const Player = styled(VideoPlayer)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  transition: 0.4s ${(p) => p.theme.easeInOutQuad};
  transition-property: opacity, transform;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  
   ${(p) =>
    !p.isActive &&
    css`
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.9);
      pointer-events: none;
      transition-duration: 0.25s;
    `}
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 100;
  border-radius: 50%;
  color: ${(p) => p.theme.color.black};
  cursor: pointer;
  transition: 0.6s ${(p) => p.theme.easeOutQuart};
  transition-property: opacity, transform;
  
  ${(p) =>
    !p.isActive &&
    css`
      opacity: 0;
    `}
    
  &::before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: -1;
    background-color: ${(p) => p.theme.color.white};
    border-radius: inherit;
  }

  &::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    border: 2px solid;
    border-radius: inherit;
  }

  &:hover {
    color: ${(p) => p.theme.color.white};

    &::before {
      background-color: ${(p) => p.theme.color.black};
    }

    &::after {
      border-color: currentColor;
    }
  }
`

const CloseButtonIcon = styled(XIcon)`
  transition: transform 0.6s ${(p) => p.theme.easeOutQuart};

  ${CloseButton}:hover & {
    transform: rotate(90deg);
  }
`

export default GallerySlider
