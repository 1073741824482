import { useState } from "react"
import styled, { css } from "styled-components/macro"
import { media } from "styles/breakpoints"
import Item from "./Item"
import ShowcaseStory from "components/ShowcaseStory"

function ImageMosaic({ items, layout, hasStories }) {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(null)
  const stories = hasStories && items.map((item) => item.story)
  const currentStory = hasStories && stories[currentStoryIndex]
  const isStoryVisible = typeof currentStoryIndex === "number"

  function showStory(index) {
    setCurrentStoryIndex(index)
  }

  return (
    <Element layout={layout}>
      {items.map(({ title, category, url, imageUrl, story, theme }, index) => {
        return (
          <Item
            title={title}
            category={category}
            url={url}
            imageUrl={imageUrl}
            theme={theme}
            layout={layout}
            onLinkClick={() => {
              if (story) {
                showStory(index)
              }
            }}
            key={index}
          />
        )
      })}
      {hasStories && (
        <ShowcaseStory
          isVisible={isStoryVisible}
          {...currentStory}
          onHide={() => setCurrentStoryIndex(null)}
        />
      )}
    </Element>
  )
}

const Element = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  overflow: hidden;
  position: relative;

  ${(p) =>
    p.layout === "mixed" &&
    css`
      gap: 16px;
    `}

  ${(p) =>
    p.layout === "horizontal" &&
    css`
      gap: 8px;
    `}
    
    ${media.mediumTablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default ImageMosaic
