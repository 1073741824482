import styled, { css } from "styled-components/macro"

import { ReactComponent as ArrowRightIcon } from "assets/icons/arrowRight.svg"
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg"
import { ReactComponent as ExternalLinkIcon } from "assets/icons/externalLink.svg"
import { Link } from "react-router-dom"

function Button({
  type,
  icon,
  iconBox,
  hasInvertedColors,
  to,
  className,
  children,
  onClick,
  toTextOnHover,
  toTextOnHoverWidth
}) {
  if (icon || iconBox) {
    type = "icon"
  }
  const isLinkExternal = to && to.includes("http")
  const tag = to && (isLinkExternal ? "a" : Link)
  return (
    <Element
      as={tag}
      $type={type}
      icon={icon}
      $hasInvertedColors={hasInvertedColors}
      $toTextOnHover={!!toTextOnHover}
      $toTextOnHoverWidth={toTextOnHoverWidth}
      to={isLinkExternal ? null : to}
      href={isLinkExternal ? to : null}
      onClick={onClick}
      className={className}
    >
      {toTextOnHover && (
        <TextOnHover $toTextOnHoverWidth={toTextOnHoverWidth}>
          <div>{toTextOnHover}</div>
        </TextOnHover>
      )}
      {iconBox && (
        <IconBox $hasInvertedColors={hasInvertedColors} hoverAnimation={!toTextOnHover}>
          {iconBox === "download" && <DownloadIcon />}
          {iconBox === "externalLink" && <ExternalLinkIcon />}
        </IconBox>
      )}
      {children}
      {icon === "arrowRight" && <ArrowRightIcon />}
    </Element>
  )
}


const TextOnHover = styled.div`
  position: absolute;
  left: 0;
  top: 2px;
  bottom: 2px;
  width: auto;
  background-color: ${(p) => p.theme.color.black};
  color: ${(p) => p.theme.color.white};
  display: flex;
  text-align: center;
  align-items: center;
  transition: .3s;
  visibility: hidden;
  z-index: 10;
  overflow: hidden;
  div {
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Element = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 1.92857143em;
  position: relative;
  background-color: ${(p) => p.theme.color.black};
  border: 1px solid transparent;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.015em;
  white-space: nowrap;
  color: ${(p) => p.theme.color.white};
  user-select: none;
  cursor: pointer;
  transition: 0.15s;
  transition-property: background, border, color;

  ${p => !p.$toTextOnHover && css`
    &:hover {
      background-color: transparent;
      border-color: currentColor;
      color: ${(p) => p.theme.color.black};
    }
  `}

  ${(p) =>
    p.$type === "link" &&
    css`
      height: 40px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: transparent;
      color: ${(p) => p.theme.color.black};

      &:hover {
        background-color: ${(p) => p.theme.color.black};
        border-color: transparent;
        color: ${(p) => p.theme.color.white};
      }
    `}

  ${(p) =>
    p.$type === "icon" &&
    css`
      justify-content: flex-start;
      height: 40px;
      padding-left: 0;
      padding-right: 8px;
      background-color: transparent;
      color: ${(p) => p.theme.color.black};

      > svg {
        margin-left: 16px;
        transition: transform 0.35s ${(p = p.theme.easeOutQuart)};
        will-change: transform;
      }

      &:hover {
        border-color: transparent;

        > svg {
          transform: translateX(5px);
        }
      }
    `}
    
  ${(p) =>
    p.$hasInvertedColors &&
    css`
      &,
      &:hover {
        color: ${(p) => p.theme.color.white};
      }
    `}

    ${p => p.$toTextOnHover && css`
      ${TextOnHover} {
        width: 0;
        div {
          width: ${p.$toTextOnHoverWidth}px;
        }
      }
      &:hover {
        ${TextOnHover} {
          visibility: visible;
          opacity: 1;
          width: ${p.$toTextOnHoverWidth}px !important;
        }
      }
    `}

`

const IconBox = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-right: 16px;
  padding-bottom: 1px;
  background-color: ${(p) => p.theme.color.black};
  border: 1px solid transparent;
  color: ${(p) => p.theme.color.white};
  transition: 0.15s;

  ${p => p.hoverAnimation && css`
    ${Element}:hover & {
      background-color: transparent;
      border-color: currentColor;
      color: ${(p) => p.theme.color.black};
    }
  `}

  ${(p) =>
    p.$hasInvertedColors &&
    css`
      &,
      ${Element}:hover & {
        background-color: ${(p) => p.theme.color.white};
        border-color: ${(p) => p.theme.color.white};
        color: ${(p) => p.theme.color.black};
      }
    `}
`

export { Button as default, Element }
