import { useState, useRef } from "react"
import styled from "styled-components/macro"
import { rgba } from "polished"
import { media } from "styles/breakpoints"
import Observer from "@researchgate/react-intersection-observer"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import Header from "./Header"
import Container from "components/Container"
import QuickLinks from "components/QuickLinks"
import background from "assets/images/ovalsBlurred.png"
import backgroundWebp from "assets/images/ovalsBlurred.webp"

function SectionFigureWithQuickLinks({ title, text, linksTitle, links }) {
  const [isInView, setInView] = useState(false)
  const [bgScrollProgress, setBgScrollProgress] = useState(0)
  const ref = useRef()

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  useScrollPosition(
    ({ currPos }) => {
      if (!isInView) return
      const bgTrackLength = 800 + window.innerHeight
      const progress = Math.min(
        Math.max((window.innerHeight - currPos.y) / bgTrackLength, 0),
        1
      )
      setBgScrollProgress(progress)
    },
    [isInView],
    ref
  )

  return (
    <Observer onChange={handleIntersection}>
      <Element
        ref={ref}
        style={{
          "--bg-scroll-progress": bgScrollProgress,
        }}
      >
        <StyledContainer>
          <Header title={title} text={text} />
          <QuickLinks title={linksTitle} links={links} />
        </StyledContainer>
        <Background>
          <source srcSet={`${backgroundWebp}`} type="image/webp" />
          <BackgroundImage src={background} alt="" />
        </Background>
      </Element>
    </Observer>
  )
}

const Element = styled.section`
  overflow: hidden;
  padding: 125px 0 104px;
  position: relative;
  background-image: linear-gradient(#c5ddc3, ${rgba("#c5ddc3", 0)});
`

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 1;
`

const Background = styled.picture``

const BackgroundImage = styled.img`
  max-width: none;
  position: absolute;
  top: -200px;
  left: calc(50% + 30px);
  transform: translateX(-50%);
  pointer-events: none;

  ${media.smallDesktop} {
    top: -300px;
    transform: translate(-50%, calc(var(--bg-scroll-progress, 0) * 300px));
  }
`

export default SectionFigureWithQuickLinks
