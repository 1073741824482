function calculateHeights(refsArray) {
  refsArray?.forEach(({ current: element }, index) => {
    element.style.maxHeight = "none"
    const maxHeight = element.offsetHeight
    element.dataset.maxHeight = maxHeight
    element.removeAttribute("style")
  })
}

export default calculateHeights
