import Container from "components/Container"
import { Heading } from "components/Typography"
import ImageMosaic from "components/ImageMosaic"
import Observer from "@researchgate/react-intersection-observer"
import { getNonceValue } from "helpers/getNonceValue"
import onScrollAnimations from "styles/onScrollAnimations"
import slugify from "helpers/slugify"
import styled from "styled-components/macro"
import { useQuery } from "jsonapi-react"
import { useState } from "react"

function SectionShowcase({ title, subtitle }) {
  const { data } = useQuery([
    "node/showcase_detail",
    {
      include: ["field_showcase_category", "field_thumbnail_small"],
      nonce: getNonceValue(),
    },
  ])
  const [isInView, setInView] = useState(false)

  const caseStudies = data?.map((item) => {
    return {
      title: item.title,
      category: item.getCategory(),
      url: `/showcases/${slugify(item.title)}`,
      imageUrl:
        process.env.REACT_APP_BACKEND_URL +
        item.field_thumbnail_small?.uri?.url,
    }
  })

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  return (
    <Observer onChange={handleIntersection}>
      <Element className={isInView && "-in-view"}>
        <Container>
          <Header>
            <Headings>
              <Heading extraLarge>{title}</Heading>
              <Subtitle>{subtitle}</Subtitle>
            </Headings>
          </Header>
          {data && <ImageMosaic items={caseStudies} layout="mixed" />}
        </Container>
      </Element>
    </Observer>
  )
}

const Element = styled.section``

const Subtitle = styled.h3`
  margin-top: -12px;
  font-size: 30px;
  line-height: 1.2;
`

const Header = styled.header`
  margin-bottom: 78px;
  text-align: center;
`

const Headings = styled.hgroup`
  ${onScrollAnimations(Element)};
`

export default SectionShowcase
