import { breakpoints, media } from "styles/breakpoints"
import { useRef, useState } from "react"

import { Heading } from "components/Typography"
import Observer from "@researchgate/react-intersection-observer"
import SegmentedCircle from "components/SegmentedCircle"
import onScrollAnimations from "styles/onScrollAnimations"
import { rgba } from "polished"
import styled from "styled-components/macro"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

function Map({ title, legend, ballPosition }) {
  const [isInView, setInView] = useState(false)
  const [circleRotation, setCircleRotation] = useState(0)
  const element = useRef()
  const trackLength = window.innerHeight + 650

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  useScrollPosition(
    ({ currPos }) => {
      if (!isInView || window.innerWidth <= breakpoints.mediumTablet) {
        return
      }
      const progress = Math.min(
        Math.max((window.innerHeight - currPos.y) / trackLength, 0),
        1
      )
      setCircleRotation(60 * (1 - progress) - 30)
    },
    [isInView],
    element
  )

  return (
    <Observer onChange={handleIntersection}>
      <Element
        ref={element}
        className={isInView && "-in-view"}
        style={{
          "--circle-rotation": `${circleRotation}deg`,
        }}
      >
        <Heading extraLarge>{title}</Heading>
        <Legend>
          {legend.map(({ title, description }, index) => (
            <LegendItem key={index}>
              <LegendItemTitle>{title}</LegendItemTitle>
              {description.map((paragraph, index) => (
                <LegendItemDescriptionLine key={index}>
                  {paragraph}
                </LegendItemDescriptionLine>
              ))}
              <LegendItemLine />
            </LegendItem>
          ))}
        </Legend>
        <Circle size="small" ballPosition={ballPosition} />
      </Element>
    </Observer>
  )
}

const Element = styled.section`
  height: 640px;
  margin-bottom: 110px;
  padding: 48px 35px;
  position: relative;
  background-color: ${(p) => p.theme.color.background};

  ${media.mediumTablet} {
    height: 780px;
  }

  ${media.smallDesktop} {
    padding-left: 74px;
    padding-right: 74px;
  }
`

const Legend = styled.div`
  ${onScrollAnimations(Element)};
`

const LegendItem = styled.div`
  width: 242px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;

  &:nth-child(1) {
    top: calc(50% - 150px);
    left: calc(50% + 21vw);
  }

  &:nth-child(2) {
    top: calc(50% + 120px);
    left: calc(50% - 43vw);
  }

  &:nth-child(3) {
    top: calc(50% + 120px);
    left: calc(50% + 19vw);
  }

  ${media.mediumTablet} {
    &:nth-child(1) {
      top: calc(50% - 200px);
    }

    &:nth-child(2) {
      top: calc(50% + 10px);
    }

    &:nth-child(3) {
      top: calc(50% + 90px);
    }
  }

  ${media.smallDesktop} {
    &:nth-child(1) {
      left: calc(50% + 310px);
    }

    &:nth-child(2) {
      left: calc(50% - 528px);
    }

    &:nth-child(3) {
      left: calc(50% + 310px);
    }
  }
`

const LegendItemTitle = styled.h3`
  margin-bottom: 17px;
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
`

const LegendItemDescriptionLine = styled.p`
  position: relative;
  padding-left: 15px;
  line-height: 1.5;
  margin-bottom: 5px;
  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: currentColor;
    border-radius: 50%;
  }
`

const LegendItemLine = styled.div`
  width: 9px;
  height: 9px;
  position: absolute;
  top: 17px;
  left: -25px;
  border: 3px solid;
  border-radius: 50%;

  &::before,
  &::after {
    content: "";
    width: 55px;
    height: 1px;
    position: absolute;
    top: 1px;
    right: 5px;
    background-color: currentColor;
  }

  &::after {
    transform-origin: 0 50%;
  }

  ${LegendItem}:nth-child(1) & {
    &::before,
    &::after {
      width: 95px;
    }

    &::after {
      transform: rotate(135deg);
    }
  }

  ${LegendItem}:nth-child(2) & {
    left: 90%;

    &::before,
    &::after {
      left: 5px;
      right: auto;
    }

    &::after {
      transform: rotate(140deg);
      transform-origin: 100% 50%;
    }
  }

  ${LegendItem}:nth-child(3) & {
    &::before,
    &::after {
      width: 45px;
    }

    &::after {
      transform: rotate(206deg);
    }
  }

  ${media.mediumTablet} {
    ${LegendItem}:nth-child(2) & {
      &::after {
        transform: rotate(-170deg);
      }
    }
  }
`

const Circle = styled(SegmentedCircle)`
  position: absolute;
  top: 175px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  pointer-events: none;
`

export default Map
