export const APP_VERSION = "1.0.1"

export const ROUTE_TITLES = {
  "/molecule": "Molecule",
  "/field-guide": "Field Guide",
  "/showcases": "Showcases",
  "/logos": "Logos",
  "/typography": "Typography",
  "/colors": "Colors",
  "/resources": "Resources",
  "/search": "Search",
  "/news": "News",
  "/": "The Foundation",
}

export const APP_INSIGHSTS_PROPERTIES = {
  App_Id: process.env.REACT_APP_ANALYTICS_APP_ID,
  Region_Id: process.env.REACT_APP_ANALYTICS_REGION_ID,
  Brand_Id: process.env.REACT_APP_ANALYTICS_BRAND_ID,
  Door_Id: "N/A",
  Counter_Id: "N/A",
  Affiliate_Id: "N/A",
  App_Env: process.env.REACT_APP_ANALYTICS_APP_ENV,
}

export const GUIDELINES_TABS = [
  {
    title: "Color",
    slug: "color",
  },
  {
    title: "Logos",
    slug: "logos",
  },
  {
    title: "The C icon",
    slug: "the-c-icon",
  },
  {
    title: "Typography",
    slug: "typography",
  },
  {
    title: "Layout",
    slug: "layout",
  },
]
