import { Heading, Paragraph } from "components/Typography"
import { breakpoints, media } from "styles/breakpoints"
import { useEffect, useRef, useState } from "react"

import Container from "components/Container"
import ScrollIndicator from "components/ScrollIndicator"
import SegmentedCircle from "components/SegmentedCircle"
import styled from "styled-components/macro"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

function HeroWithSegmentedCircle({ title, textColumns, ctaText }) {
  const [isVisible, setVisibility] = useState(false)
  const [circleRotation, setCircleRotation] = useState(0)
  const element = useRef()
  const trackLength = 1150

  useEffect(() => {
    setVisibility(true)
  }, [])

  useScrollPosition(
    ({ currPos }) => {
      if (
        -currPos.y > trackLength ||
        window.innerWidth <= breakpoints.mediumTablet
      ) {
        return
      }
      const progress = Math.min(Math.max(-currPos.y / trackLength, 0), 1)
      setCircleRotation(30 * progress)
    },
    [],
    element
  )

  return (
    <Element
      ref={element}
      className={isVisible && "-in-view"}
      style={{
        "--circle-rotation": `${circleRotation}deg`,
      }}
    >
      <Container>
        <Header>
          <Heading moleculeLarge as="h1">
            {title}
          </Heading>
        </Header>
        <Columns>
          {textColumns.map(({ title, text }, index) => (
            <Column key={index}>
              <Heading small>{title}</Heading>
              <Paragraph>{text}</Paragraph>
            </Column>
          ))}
        </Columns>
      </Container>
      <SegmentedCircleWrapper>
        <StyledSegmentedCircle
          size="large"
          moveBallOnPointer
          isParentInView={isVisible}
        />
      </SegmentedCircleWrapper>
      <StyledScrollIndicator parentRef={element}>
        {ctaText}
      </StyledScrollIndicator>
    </Element>
  )
}

const Element = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  margin-bottom: 230px;
  padding-top: 50px;
  position: relative;
  transition: transform 1.5s ${(p) => p.easeOutExpo};
  will-change: transform;

  &:not(.-in-view) {
    transform: translateY(100%);
  }

  @media (min-height: 680px) {
    padding-top: 0;
    padding-bottom: 60px;
  }
`

const Header = styled.div`
  max-width: 750px;
  margin-bottom: 8vh;
  position: relative;
  z-index: 1;

  @media (min-height: 1000px) {
    margin-bottom: 80px;
  }
`

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  width: 760px;
  max-width: 100%;
  position: relative;
  z-index: 1;
  transition: 1.5s ${(p) => p.easeOutExpo};

  ${Element}:not(.-in-view) & {
    transform: translateY(100px);
  }
`

const Column = styled.div`
  width: 280px;

  &:last-child {
    width: 400px;
  }
`

const SegmentedCircleWrapper = styled.div`
  width: 100%;
  height: 2000px;
  overflow: hidden;
  position: absolute;
  top: -385px;
  left: 0;
`

const StyledSegmentedCircle = styled(SegmentedCircle)`
  position: absolute;
  top: 0;
  left: calc(${(p) => p.theme.sidePadding.small}px + 255px);

  ${media.smallDesktop} {
    left: calc(${(p) => p.theme.sidePadding.medium}px + 255px);
  }

  ${media.mediumDesktop} {
    left: calc(${(p) => p.theme.sidePadding.large}px + 255px);
  }

  @media (min-width: ${(p) =>
      p.theme.containerWidth + p.theme.sidePadding.large * 2}px) {
    left: calc(50% - 505px);
  }
`

const StyledScrollIndicator = styled(ScrollIndicator)`
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-height: 680px) {
    display: none;
  }
`

export default HeroWithSegmentedCircle
