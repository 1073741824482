import { useCallback, useEffect } from "react"

import { client } from "App"
import { useState } from "react"

export const LS_FR_TOKEN = "FR_TOKEN"

export function ForgeRockGate({ children }) {
  const [loading, setLoading] = useState(true)

  function redirectToAuthPage() {
    window.location.href = `${process.env.REACT_APP_AUTH_URL}?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&response_type=code&scope=openid&state=abc123&redirect_uri=${window.location.origin}/`
  }

  const authFlow = useCallback(async () => {
    const lsToken = localStorage.getItem(LS_FR_TOKEN)
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get("code")

    if (!loading) return

    if (code) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/forgerock/auth?_format=json`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: code,
              redirect_uri: `${window.location.origin}/`,
            }),
          }
        )
        const { id_token } = await res.json()
        localStorage.setItem(LS_FR_TOKEN, id_token)
        client.addHeader("Authorization", `Bearer ${id_token}`)
        setTimeout(() => {
          setLoading(false)
          window.history.pushState("", "/", window.location.pathname)
        }, 1)
      } catch (err) {
        redirectToAuthPage()
      }
    } else {
      if (lsToken) {
        client.addHeader("Authorization", `Bearer ${lsToken}`)
        setTimeout(() => {
          setLoading(false)
          window.history.pushState("", "/", window.location.pathname)
        }, 1)
      } else {
        redirectToAuthPage()
      }
    }
  }, [loading])

  useEffect(() => {
    return client.subscribe((action) => {
      if (!loading && action.error && action.error.status === "403") {
        redirectToAuthPage()
      }
    })
  }, [loading])

  useEffect(() => {
    authFlow()
  }, [authFlow])

  return loading ? null : children
}
