import { css } from "styled-components/macro"

const invertedSelection = css`
  * ::selection {
    color: red;
    background: ${(p) => p.theme.color.background};
    color: ${(p) => p.theme.color.text};
  }
`

export { invertedSelection }
