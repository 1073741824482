import { Helmet } from "react-helmet"
import Hero from "components/Hero"
import { HomepageNews } from "components/HomepageNews/HomepageNews"
import { IntroducingGreen } from "components/IntroducingGreen"
import Layout from "layouts"
import Loader from "components/Loader"
import { ROUTE_TITLES } from "config/constants"
import SectionFigureWithQuickLinks from "components/SectionFigureWithQuickLinks"
import SectionMolecule from "components/SectionMolecule"
import SectionTileRow from "components/SectionTileRow"
import { getNonceValue } from "helpers/getNonceValue"
import { useQuery } from "jsonapi-react"

function HomeDesktop() {
  const { data, isLoading, error } = useQuery([
    "node/homepage",
    {
      include: [
        "field_hero_img",
        "field_carousel",
        "field_carousel.field_p_cp_img",
        "field_s_time_links",
        "field_s_green_background",
      ],
      nonce: getNonceValue(),
    },
  ])
  const page = data && data[0]
  const {
    hero,
    sectionTileRow,
    sectionFigure,
    sectionMolecule,
    introducingGreen,
    newsSection,
  } = { ...page }

  const { data: articles } = useQuery([
    "node/article",
    {
      include: ["field_thumbnail_image"],
      nonce: getNonceValue(),
    },
  ])
  const newsList =
    articles?.slice(0, 2).sort((a, b) => {
      return new Date(b.created) - new Date(a.created)
    }) || []

  return (
    <>
      <Helmet>
        <title>{ROUTE_TITLES["/"]}</title>
      </Helmet>
      {isLoading || error ? (
        <Loader error={error} />
      ) : (
        <Layout>
          <Hero
            title={hero.title}
            subtitle={hero.subtitle}
            ctaButtonText={hero.ctaButtonText}
            ctaButtonUrl={hero.ctaButtonUrl}
            backgroundImageUrl={hero.getBackgroundImageUrl()}
            backgroundSizeCover
            size="large"
          />
          <SectionTileRow tiles={sectionTileRow.getTiles()} />
          <SectionFigureWithQuickLinks
            title={sectionFigure.title}
            text={sectionFigure.text}
            linksTitle={sectionFigure.linksTitle}
            links={sectionFigure.getLinks()}
          />
          <SectionMolecule
            title={sectionMolecule.title}
            subtitle={sectionMolecule.subtitle}
            buttonText={sectionMolecule.buttonText}
            buttonUrl={sectionMolecule.buttonUrl}
          />
          <IntroducingGreen
            title={introducingGreen.title}
            description={introducingGreen.description}
            ctaTitle={introducingGreen.ctaTitle}
            ctaUrl={introducingGreen.ctaUrl}
            backgroundImageUrl={introducingGreen.getBackgroundImageUrl()}
          />
          {newsSection.showSection && <HomepageNews data={newsList} />}
        </Layout>
      )}
    </>
  )
}

export default HomeDesktop
