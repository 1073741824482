import styled from "styled-components"
import { default as AppContainer } from "components/Container"

export const Container = styled(AppContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  transition: 1s ${(p) => p.theme.easeOutQuart};
  transition-property: opacity, transform;

  :not(.-in-view) {
    opacity: 0;
    transform: translateY(120px);
    transition: 0s;
  }
`
export const Heading = styled.h2`
  font-weight: bold;
  font-size: 62px;
  line-height: 68px;
  margin-bottom: 16px;
`

export const Paragraph = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 75px;
`

export const Main = styled.main`
  display: flex;
`

export const Iframe = styled.iframe`
  flex: 1;
  height: 1230px;
`

export const Downloads = styled.div`
  width: 330px;
  margin-left: 90px;
  border: 1px solid black;
  h3 {
    width: 100%;
    padding: 23px 28px;
    border-bottom: 1px solid black;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
  }
  ul {
    padding: 44px 38px;
    li {
      margin-bottom: 20px;
      a {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
      p {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
`
