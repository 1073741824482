import { Helmet } from "react-helmet"
import Hero from "components/Hero"
import Layout from "layouts"
import Loader from "components/Loader"
import PageNavigation from "components/PageNavigation"
import { ROUTE_TITLES } from "config/constants"
import SectionAssets from "components/SectionAssets"
import SectionFaq from "components/SectionFaq"
import SectionFeaturedAsset from "components/SectionFeaturedAsset"
import { appInsights } from "index"
import { getNonceValue } from "helpers/getNonceValue"
import { trackEvent } from "utils/analytics"
import { useQuery } from "jsonapi-react"

function Resources() {
  const { data, isLoading, error } = useQuery([
    "node/resources_page",
    {
      include: ["field_hero_img"],
      nonce: getNonceValue(),
    },
  ])
  const page = data && data[0]
  const { hero, navigation, sectionAssets, sectionFeaturedAsset, sectionFaq } =
    { ...page }

  return (
    <>
      <Helmet>
        <title>{ROUTE_TITLES["/resources"]}</title>
      </Helmet>
      {isLoading || error ? (
        <Loader error={error} />
      ) : (
        <Layout>
          <Hero
            title={hero.title}
            subtitle={hero.subtitle}
            backgroundColor={hero.backgroundColor}
            backgroundImageUrl={hero.getBackgroundImageUrl()}
            backgroundSizeCover
            titleSize="large"
            align="center"
          />
          <PageNavigation
            items={navigation}
            onItemClick={(name) => {
              trackEvent("click_navigation", {
                navigation_button_name: name,
                navigation_category: "resources",
              })
            }}
          />
          <SectionAssets
            id="assets"
            title={sectionAssets.title}
            subtitle={sectionAssets.subtitle}
          />
          <SectionFeaturedAsset
            id="templates"
            title={sectionFeaturedAsset.title}
            subtitle={sectionFeaturedAsset.subtitle}
            backgroundColor={sectionFeaturedAsset.backgroundColor}
          />
          <SectionFaq
            id="faqs"
            title={sectionFaq.title}
            subtitle={sectionFaq.subtitle}
          />
        </Layout>
      )}
    </>
  )
}

export default Resources
