import { Heading } from "components/Typography"
import { Link } from "react-router-dom"
import { rgba } from "polished"
import styled from "styled-components/macro"

function Item({ title, date, excerpt, imageUrl, url, isCompact }) {

  const isLinkExternal = url && url.includes("http")
  const tag = url && (isLinkExternal ? "a" : Link)

  return (
    <Element as={tag} to={!isLinkExternal ? url : null} href={isLinkExternal ? url : null} className={isCompact && "-compact"}>
      <ImageWrapper>
        <Image src={imageUrl} alt={title} />
      </ImageWrapper>
      <Date>{date}</Date>
      <Title>{title}</Title>
      {!isCompact && <Excerpt>{excerpt}</Excerpt>}
    </Element>
  )
}

const Element = styled(Link)`
  display: block;
`

const ImageWrapper = styled.figure`
  margin-bottom: 18px;
  padding-top: 91.836735%;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid;
    transition: opacity 0.15s;
  }

  &:not(:hover) {
    &::after {
      opacity: 0.2;
    }
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Date = styled.p`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${(p) => rgba(p.theme.color.black, 0.5)};
`

const Title = styled(Heading).attrs({
  moleculeSubheading: true,
  "data-size": "moleculeSubheading",
  as: "h3",
})`
  font-weight: 500;

  ${Element}.-compact & {
    margin-top: -8px;
    font-size: 18px;
  }

  &&:not(:last-child) {
    margin-bottom: 8px;
  }
`

const Excerpt = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: ${(p) => rgba(p.theme.color.black, 0.5)};
`

export default Item
