import { ApiClient, ApiProvider } from "jsonapi-react"
import { ForgeRockGate, LS_FR_TOKEN } from "components/ForgeRockGate"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"

import Analytics from "components/Analytics"
import FieldGuide from "pages/FieldGuide"
import { GlobalStyle } from "styles"
import Guidelines from "pages/Guidelines"
import Home from "pages/Home"
import { LastLocationProvider } from "react-router-last-location"
import LimitedExperienceNotice from "components/LimitedExperienceNotice"
import Molecule from "pages/Molecule"
import News from "pages/News"
import Resources from "pages/Resources"
import Search from "pages/Search"
import Showcase from "pages/Showcase"
import { ThemeProvider } from "styled-components"
import { breakpoints } from "styles/breakpoints"
import schema from "./schema"
import theme from "styles/theme"
import useWindowSize from "hooks/useWindowSize"
import React from "react"
import { GUIDELINES_TABS } from "config/constants"

const url =
  process.env.NODE_ENV === "development"
    ? "/jsonapi"
    : `${process.env.REACT_APP_BACKEND_URL}/jsonapi`

const token = localStorage.getItem(LS_FR_TOKEN)

export const client = new ApiClient({
  url,
  schema,
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

function App() {
  const { width } = useWindowSize()
  const isFullExperience = width >= breakpoints.smallTablet

  return (
    <ForgeRockGate>
      <ApiProvider client={client}>
        <ThemeProvider theme={theme}>
          <Router>
            <LastLocationProvider>
              <GlobalStyle />
              {isFullExperience ? (
                <Switch>
                  <Route path="/molecule">
                    <Molecule />
                  </Route>
                  <Route path="/field-guide">
                    <FieldGuide />
                  </Route>
                  <Route path="/showcases">
                    <Showcase />
                  </Route>
                  {GUIDELINES_TABS.map((item, i) => (
                    <Route path={`/${item.slug}`}>
                      <Guidelines type={item.slug} />
                    </Route>
                  ))}
                  <Route path="/resources">
                    <Resources />
                  </Route>
                  <Route path="/search">
                    <Search />
                  </Route>
                  <Route path="/news">
                    <News />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              ) : window.location.pathname === "/" ? (
                <Home />
              ) : (
                <LimitedExperienceNotice />
              )}
              <Analytics />
            </LastLocationProvider>
          </Router>
        </ThemeProvider>
      </ApiProvider>
    </ForgeRockGate>
  )
}

export default App
