import { Link, useHistory } from "react-router-dom"
import styled, { css } from "styled-components/macro"

import Observer from "@researchgate/react-intersection-observer"
import { media } from "styles/breakpoints"
import { useState } from "react"

function Item({
  title,
  category,
  url,
  imageUrl,
  layout,
  isWide,
  theme = "dark",
  onLinkClick,
}) {
  const [isInView, setInView] = useState(false)
  const history = useHistory()
  const className = `-${layout} -${theme} ${isWide ? ` -wide` : ``}`

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  function onClick(event) {
    onLinkClick()
    if (url) {
      const path = event.target.getAttribute("href")
      event.preventDefault()
      setTimeout(() => {
        history.push(path)
      }, 500)
    }
  }

  return (
    <Observer onChange={handleIntersection}>
      <Element isInView={isInView} className={className}>
        <ItemLink as={url && Link} to={url} onClick={onClick}>
          {imageUrl && <Image src={imageUrl} loading="lazy" />}
          <Caption>
            <Category>{layout === "mixed" ? category : title}</Category>
            <Title>{layout === "mixed" ? title : category}</Title>
          </Caption>
        </ItemLink>
      </Element>
    </Observer>
  )
}

const Element = styled.figure`
  position: relative;
  transition: transform 0.7s ${(p) => p.theme.easeOutQuart};
  will-change: transform;

  &.-wide {
    grid-column: span 2;
  }

  ${(p) =>
    !p.isInView &&
    css`
      transform: translateY(70px);
    `}
`

const ItemLink = styled.a`
  display: block;
  cursor: pointer;

  &::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0.4) 0%,
      hsla(0, 0%, 0%, 0.405) 13.4%,
      hsla(0, 0%, 0%, 0.419) 25%,
      hsla(0, 0%, 0%, 0.442) 34.9%,
      hsla(0, 0%, 0%, 0.47) 43.4%,
      hsla(0, 0%, 0%, 0.504) 50.6%,
      hsla(0, 0%, 0%, 0.541) 56.8%,
      hsla(0, 0%, 0%, 0.58) 62.2%,
      hsla(0, 0%, 0%, 0.62) 66.9%,
      hsla(0, 0%, 0%, 0.659) 71.3%,
      hsla(0, 0%, 0%, 0.696) 75.4%,
      hsla(0, 0%, 0%, 0.73) 79.5%,
      hsla(0, 0%, 0%, 0.758) 83.8%,
      hsla(0, 0%, 0%, 0.781) 88.6%,
      hsla(0, 0%, 0%, 0.795) 93.9%,
      hsla(0, 0%, 0%, 0.8) 100%
    );
    transition: opacity 0.25s;
  }

  ${Element}.-mixed & {
    padding-top: 116.25%;

    &::after {
      opacity: 0.5;
    }

    &:hover {
      &::after {
        opacity: 0.8;
      }
    }
  }

  ${Element}.-wide & {
    padding-top: 56.985294%;
  }

  ${Element}.-horizontal & {
    padding-top: 80.428293%;

    &::after {
      opacity: 0;
    }

    &:hover {
      &::after {
        opacity: 0.2;
      }
    }
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Caption = styled.figcaption`
  position: absolute;
  z-index: 1;
  line-height: 1.33333333;

  ${Element}.-mixed & {
    bottom: 20px;
    right: 20px;
    left: 20px;
    font-size: 18px;
  }

  ${Element}.-horizontal & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 36px;
    right: 36px;
    bottom: 32px;
    left: 36px;
  }

  ${Element}.-dark & {
    color: ${(p) => p.theme.color.white};
  }

  ${media.mediumTablet} {
    ${Element}.-mixed & {
      bottom: 50px;
      right: 40px;
      left: 40px;
    }
  }
`

const Category = styled.h5`
  ${Element}.-horizontal & {
    max-width: 165px;
    font-weight: 300;
    font-size: 32px;
    line-height: 1.25;
  }
  ${Element}.-mixed & {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.03em;
  }
`

const Title = styled.h4`
  ${Element}.-mixed & {
    font-weight: 300;
    font-size: 32px;
    line-height: 1.25;
  }

  ${Element}.-horizontal & {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.03em;
  }
`

export default Item
