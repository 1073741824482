import { useState, useRef } from "react"
import styled from "styled-components/macro"
import { media } from "styles/breakpoints"
import Observer from "@researchgate/react-intersection-observer"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import Container from "components/Container"
import { Heading } from "components/Typography"
import Button from "components/Button"
import background from "assets/images/moleculeBg.jpg"

function SectionMolecule({ title, subtitle, buttonText, buttonUrl }) {
  const [isInView, setInView] = useState(false)
  const [scrollProgress, setScrollProgress] = useState(0)
  const element = useRef()
  const trackLength = 1090

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  useScrollPosition(
    ({ currPos }) => {
      if (!isInView) {
        return
      }
      const progress = Math.min(
        Math.max((window.innerHeight - currPos.y) / trackLength, 0),
        1
      )
      setScrollProgress(progress)
    },
    [isInView],
    element
  )

  return (
    <Observer onChange={handleIntersection}>
      <Element
        ref={element}
        style={{
          "--scroll-progress": scrollProgress,
        }}
      >
        <StyledContainer>
          <Content>
            <Heading extraLarge>{title}</Heading>
            <Heading medium as="p">
              {subtitle}
            </Heading>
            <Button to={buttonUrl}>{buttonText}</Button>
          </Content>
        </StyledContainer>
        <Background src={background} alt="" loading="lazy" />
      </Element>
    </Observer>
  )
}

const Element = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 712px;
  overflow: hidden;
  position: relative;
  background: #eee;
`

const StyledContainer = styled(Container)`
  display: inherit;
  justify-content: inherit;
`

const Content = styled.div`
  width: 100%;
  max-width: 816px;
  padding: 72px 0 66px;
  position: relative;
  z-index: 1;
  background: ${(p) => p.theme.color.background};
  text-align: center;
`

const Background = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  ${media.smallDesktop} {
    transform: scale(calc((1 - var(--scroll-progress, 0)) * 0.3 + 1));
  }
`

export default SectionMolecule
