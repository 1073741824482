import { default as GlobalContainer } from "components/Container"
import styled from "styled-components"

export const Container = styled.div`
  background-color: #d1e8c8;
  width: 100%;
  height: 700px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
`

export const CImage = styled.img`
  position: absolute;
  top: 0;
  right: 280px;
`

export const Content = styled(GlobalContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 100;
  h1 {
    font-weight: bold;
    font-size: 64px;
    line-height: 77px;
    max-width: 530px;
  }
  p {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    max-width: 450px;
    margin-bottom: 90px;
  }
`
