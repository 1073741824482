import { Switch, Route, useRouteMatch, useParams } from "react-router-dom"
import Main from "./Main"
import Article from "./Article"

function News() {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/:slug`}>
        <SingleArticle />
      </Route>
      <Route path={match.path}>
        <Main />
      </Route>
    </Switch>
  )
}

function SingleArticle() {
  const { slug } = useParams()
  return <Article slug={slug} />
}

export default News
