import styled from "styled-components/macro"

function LimitedExperienceNotice() {
  return (
    <Element>
      <Paragraph>
        For the full experience, please visit The Foundation on your desktop.
      </Paragraph>
    </Element>
  )
}

const Element = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${(p) => p.theme.color.black};
  color: ${(p) => p.theme.color.white};
`

const Paragraph = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.42857143;
`

export default LimitedExperienceNotice
