import styled from "styled-components/macro"
import { getLuminance } from "polished"
import Slideshow from "components/Slideshow"
import { Heading } from "components/Typography"
import { ReactComponent as XIcon } from "assets/icons/x.svg"

function ShowcaseStory({ title, category, items, isVisible, onHide }) {
  const storyTheme = items?.find((item) => item.theme === "light")
    ? "light"
    : "dark"
  const className = `-${storyTheme} ${isVisible ? `-visible` : ``}`

  return (
    <Element className={className}>
      <Title>{title}</Title>
      <Category>{category}</Category>
      {items && (
        <Slideshow hasPagination hasKeyBoardSupport>
          {items.map(
            (
              { title, description, imageUrl, colors, theme = "dark" },
              index
            ) => (
              <Slide className={`-${theme}`} key={index}>
                <SlideDetails>
                  <SlideTitle>{title}</SlideTitle>
                  <SlideDescription
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </SlideDetails>
                {colors && colors.length && (
                  <SlideColors>
                    {colors.map((color, index) => (
                      <SlideColor color={color} key={index} />
                    ))}
                  </SlideColors>
                )}
                <SlideImage src={imageUrl} alt="" />
              </Slide>
            )
          )}
        </Slideshow>
      )}
      <CloseButton onClick={onHide}>
        <CloseButtonIcon />
      </CloseButton>
    </Element>
  )
}

const Element = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  background: #333;
  transition: transform 1s ${(p) => p.theme.easeOutQuart};
  will-change: transform;

  &.-dark {
    color: ${(p) => p.theme.color.white};
  }

  &:not(.-visible) {
    transform: translateX(100%);
    transition-duration: 0.7s;
  }

  > *,
  .flicking-viewport {
    height: 100%;
  }
`

const Title = styled.h2`
  position: absolute;
  top: 36px;
  left: 37px;
  z-index: 2;
  max-width: 165px;
  font-weight: 300;
  font-size: 32px;
  line-height: 1.25;
`

const Category = styled(Heading).attrs({
  extraSmall: true,
  "data-size": "extraSmall",
  as: "h4",
})`
  position: absolute;
  left: 37px;
  bottom: 13px;
  z-index: 2;
  text-transform: none;
`

const Slide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 60px 37px 0;
  position: relative;

  &.-dark {
    color: ${(p) => p.theme.color.white};

    &::before {
      content: "";
      opacity: 0.6;
      width: 468px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: ${(p) => p.theme.color.black};
    }
  }
`

const SlideDetails = styled.div`
  position: relative;
`

const SlideTitle = styled(Heading).attrs({
  extraSmall: true,
  "data-size": "extraSmall",
  as: "h3",
})``

const SlideDescription = styled.p`
  max-width: 305px;
  margin-top: 21px;
`

const SlideColors = styled.ul`
  display: flex;
  position: absolute;
  bottom: 72px;
  left: 37px;
`

const SlideColor = styled.li`
  width: 32px;
  height: 32px;
  margin-right: 6px;
  background-color: ${(p) => p.color};
  border: 1px solid ${(p) => getLuminance(p.color) < 0.7 && "transparent"};
  border-radius: 50%;
`

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
  pointer-events: none;
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
  border-radius: 50%;
  color: ${(p) => p.theme.color.black};
  cursor: pointer;
  transition: 0.6s ${(p) => p.theme.easeOutQuart};
  transition-property: opacity, transform;

  &::before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: -1;
    background-color: ${(p) => p.theme.color.white};
    border-radius: inherit;
  }

  &::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    border: 2px solid;
    border-radius: inherit;
  }

  &:hover {
    color: ${(p) => p.theme.color.white};

    &::before {
      background-color: ${(p) => p.theme.color.black};
    }

    &::after {
      border-color: currentColor;
    }
  }

  ${Element}:not(.-visible) & {
    transition-duration: 0s;
    transition-delay: 0.7s;
    opacity: 0;
    transform: translateX(100px);
  }
`

const CloseButtonIcon = styled(XIcon)`
  transition: transform 0.6s ${(p) => p.theme.easeOutQuart};

  ${CloseButton}:hover & {
    transform: rotate(90deg);
  }
`

export default ShowcaseStory
