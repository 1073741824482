import { Heading } from "components/Typography"
import { rgba } from "polished"
import styled from "styled-components/macro"

function Header({ title, category, description, imageUrl, videoUrl }) {
  return (
    <Element>
      <Text>
        <Title>{title}</Title>
        <Category>{category}</Category>
        <Description>{description}</Description>
      </Text>
        {!!videoUrl ? (
          <Video>
            <source src={videoUrl} type="video/mp4" />
          </Video>
        ) : imageUrl && (
          <Figure>
            <Image src={imageUrl} alt="" />
          </Figure>
        )}
    </Element>
  )
}

const Element = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 200px;
  padding-top: 220px;
`

const Title = styled(Heading).attrs({
  extraLarge: true,
  "data-size": "extraLarge",
  as: "h1",
})`
  max-width: 7.2em;
`

const Text = styled.div`
  width: 44.318182%;
`

const Category = styled(Heading).attrs({
  small: true,
  "data-size": "small",
})`
  color: ${(p) => rgba(p.theme.color.black, 0.5)};
`

const Description = styled(Heading).attrs({
  semiMedium: true,
  "data-size": "semiMedium",
  as: "p",
})`
  color: ${(p) => rgba(p.theme.color.black, 0.7)};
`

const Figure = styled.figure`
  width: 41%;
`

const Image = styled.img``

const Video = styled.video.attrs({
  loop: true,
  muted: true,
  autoPlay: true
})`
  width: 41%;
`

export default Header
