import "@egjs/react-flicking/dist/flicking.css"
import "@egjs/flicking-plugins/dist/pagination.css";

import styled, { css } from "styled-components/macro"
import { useRef, useState } from "react"

import { ReactComponent as ChevronLeft } from "assets/icons/chevronLeft.svg"
import { ReactComponent as ChevronRight } from "assets/icons/chevronRight.svg"
import Flicking from "@egjs/react-flicking"
import useKeyPress from "hooks/useKeyPress"

function Slideshow({
  hasNavigation,
  hasPagination,
  hasKeyBoardSupport,
  className,
  children,
  paginationStyle
}) {
  const [activePageIndex, setActivePageIndex] = useState(0)
  const slideshow = useRef()
  const slideshowOptions = {
    align: "prev",
    duration: 900,
    circular: true,
    renderOnlyVisible: true,
    onWillChange: (e) => {
      setActivePageIndex(e.index)
    },
  }

  async function slideTo({ direction, page }) {
    try {
      if (direction) {
        await slideshow.current[direction]()
      } else if (typeof page === "number") {
        await slideshow.current.moveTo(page)
      }
    } catch (e) {}
  }

  function handleKeyboard(key) {
    if (!hasKeyBoardSupport) {
      return
    }
    key === "ArrowLeft" && slideTo({ direction: "prev" })
    key === "ArrowRight" && slideTo({ direction: "next" })
  }

  useKeyPress({
    key: "ArrowLeft",
    onKeyDown: () => handleKeyboard("ArrowLeft"),
  })
  useKeyPress({
    key: "ArrowRight",
    onKeyDown: () => handleKeyboard("ArrowRight"),
  })

  return (
    <Element className={className}>
      <Items ref={slideshow} {...slideshowOptions}>
        {children}
      </Items>
      {hasNavigation && (
        <Navigation>
          <NavigationButton
            isHidden={activePageIndex === 0}
            onClick={() => slideTo({ direction: "prev" })}
          >
            <ChevronLeft />
          </NavigationButton>
          <NavigationButton onClick={() => slideTo({ direction: "next" })}>
            <ChevronRight />
          </NavigationButton>
        </Navigation>
      )}
      {hasPagination && (
        <Pagination className="pagination" style={paginationStyle}>
          {hasPagination === "extended" && (
            <PaginationButton
              aria-label="Previous"
              onClick={() => slideTo({ direction: "prev" })}
            >
              <ChevronLeft />
            </PaginationButton>
          )}
          {children.map((item, index) => (
            <PaginationItem
              className={activePageIndex === index ? 'active' : undefined}
              isActive={activePageIndex === index}
              key={index}
              onClick={() => slideTo({ page: index })}
            />
          ))}
          {hasPagination === "extended" && (
            <PaginationButton
              aria-label="Next"
              onClick={() => slideTo({ direction: "next" })}
            >
              <ChevronRight />
            </PaginationButton>
          )}
        </Pagination>
      )}
    </Element>
  )
}

const Element = styled.div`
  position: relative;
`

const Items = styled(Flicking)``

const Navigation = styled.div``

const NavigationButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  z-index: 1;
  cursor: pointer;
  transition: 0.5s;
  transition-property: visibility, opacity;

  svg {
    width: 16px;
    height: 29px;
  }

  &:first-child {
    left: 0;
  }

  &:last-child {
    right: 0;
    color: ${(p) => p.theme.color.white};
  }

  ${(p) =>
    p.isHidden &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`

const Pagination = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 31px;
  bottom: 12px;
  z-index: 2;
`

const PaginationButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.25;
  }
`

const PaginationItem = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 40px;
  cursor: pointer;
  transition: 0.15s;

  &::before {
    content: "";
    width: 18px;
    height: 18px;
    border: 2px solid;
    border-radius: 50%;
  }

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.25;
  }

  ${(p) =>
    p.isActive &&
    css`
      &::before {
        background-color: currentColor;
      }

      &:hover {
        opacity: 1;
      }
    `}
`

export default Slideshow
