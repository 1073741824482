function splitText(split, children, className) {
  const text = children?.toString()
  return (
    <span aria-label={text} className={className}>
      {text?.split(split).map((fragment, index) => {
        return (
          <span aria-hidden="true" key={index}>
            {fragment}
            {split}
          </span>
        )
      })}
    </span>
  )
}

function SplitLetters({ children, className }) {
  return splitText("", children, className)
}

function SplitWords({ children, className }) {
  return splitText(" ", children, className)
}

export { SplitLetters, SplitWords }
