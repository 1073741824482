import styled, { css } from "styled-components/macro"
import { media } from "styles/breakpoints"

const Container = styled.div(() => {
  return css`
    width: 100%;
    max-width: ${(p) =>
      p.theme.containerWidth + p.theme.sidePadding.small * 2}px;
    padding-left: ${(p) => p.theme.sidePadding.extraSmall}px;
    padding-right: ${(p) => p.theme.sidePadding.extraSmall}px;
    margin-left: auto;
    margin-right: auto;

    ${media.smallTablet} {
      max-width: ${(p) =>
        p.theme.containerWidth + p.theme.sidePadding.medium * 2}px;
      padding-left: ${(p) => p.theme.sidePadding.small}px;
      padding-right: ${(p) => p.theme.sidePadding.small}px;
    }

    ${media.smallDesktop} {
      max-width: ${(p) =>
        p.theme.containerWidth + p.theme.sidePadding.medium * 2}px;
      padding-left: ${(p) => p.theme.sidePadding.medium}px;
      padding-right: ${(p) => p.theme.sidePadding.medium}px;
    }

    ${media.mediumDesktop} {
      max-width: ${(p) =>
        p.theme.containerWidth + p.theme.sidePadding.large * 2}px;
      padding-left: ${(p) => p.theme.sidePadding.large}px;
      padding-right: ${(p) => p.theme.sidePadding.large}px;
    }
  `
})

export default Container
