import Button from "components/Button"
import { Heading } from "components/Typography"
import { ReactComponent as arrowRightLong } from "assets/icons/arrowRightLong.svg"
import { getNonceValue } from "helpers/getNonceValue"
import logo from "assets/images/logo.svg"
import { rgba } from "polished"
import styled from "styled-components/macro"
import { useQuery } from "jsonapi-react"
import useToggle from "hooks/useToggle"

function Asset() {
  const { data } = useQuery([
    "node/template",
    {
      include: [
        "field_download_file",
        "field_thumbnail_image",
        "field_thumbnail_image_second",
      ],
      nonce: getNonceValue(),
    },
  ])
  const template = data && data[0]
  const {
    title,
    description,
    buttonText,
    fileName,
    date,
    getDownloadUrl = () => {},
    getFirstImageUrl = () => {},
    getSecondImageUrl = () => {},
  } = {
    ...template,
  }

  return (
    <Element className={"-expanded"}>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <DownloadButton iconBox="download" to={getDownloadUrl()}>
          {buttonText}
        </DownloadButton>
      </div>
      <div>
        <Image src={getFirstImageUrl()} loading="lazy" />
        <FileMeta>
          <Logo src={logo} alt="Clinique" loading="lazy" />
          <FileName>
            <strong>{fileName}</strong> / {date}
          </FileName>
        </FileMeta>
        <Image src={getSecondImageUrl()} loading="lazy" />
      </div>
      {/* <ArrowIcon /> */}
    </Element>
  )
}

const Element = styled.div`
  height: 316px;
  overflow: hidden;
  padding: 20px 34px;
  position: relative;
  border: 1px solid;
  display: flex;
  & > div {
    flex: 1;
    &:last-of-type {
      display: flex;
      align-items: center;
      border: 1px solid;
    }
  }
  && {
    transition: height 0.5s ${(p) => p.theme.easeOutQuart},
      transform 1s 0.1s ${(p) => p.theme.easeOutQuart};
  }
`

const Title = styled(Heading).attrs({
  moleculeSmall: true,
  "data-size": "moleculeSmall",
  as: "h3",
})`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  &::before {
    content: "01";
    display: block;
    width: 70px;
    font-weight: normal;
    font-size: 24px;
  }
`

const Description = styled(Heading).attrs({
  moleculeSubheading: true,
  "data-size": "moleculeSubheading",
  as: "p",
})`
  max-width: 450px;
  margin-left: 70px;
  color: ${(p) => rgba(p.theme.color.black, 0.6)};

  &&:not(:last-child) {
    margin-bottom: 6px;
  }
`

const DownloadButton = styled(Button)`
  margin-left: 70px;
  z-index: 1;
`

const Image = styled.img`
  width: 153px;
  height: 100%;
  object-fit: cover;
  top: 20px;
  pointer-events: none;

  &:first-of-type {
    right: 455px;
  }

  &:last-of-type {
    right: 20px;
  }

  @media (max-width: 1310px) {
    &:first-of-type {
      display: none;
    }
  }
`

const FileMeta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 302px;
  height: 100%;
  padding: 0 20px;
  top: 0;
  right: 163px;

  @media (max-width: 1070px) {
    display: none;
  }
`

const Logo = styled.img`
  width: 230px;
  margin-bottom: 4px;
`

const FileName = styled.p`
  font-size: 12px;
`

const ArrowIcon = styled(arrowRightLong)`
  position: absolute;
  top: calc(50% - 8px);
  right: 33px;
  transition: opacity 0.25s;

  ${Element}.-expanded & {
    opacity: 0;
  }
`

export default Asset
