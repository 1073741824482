import styled, { css } from "styled-components/macro"

import Container from "components/Container"
import { Heading } from "components/Typography"
import { ReactComponent as LogoMark } from "assets/images/logoMark.svg"
import { pulse } from "styles/keyframes"

function Loader({ error }) {
  return (
    <Element>
      <Container>
        <StyledLogoMark $isAnimating={!error} />
      </Container>
      {error && <ErrorMessage>The page can’t be loaded.</ErrorMessage>}
    </Element>
  )
}

const Element = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${(p) => p.theme.color.background};
`

const StyledLogoMark = styled(LogoMark)`
  margin: 28px 0 0 9px;

  ${(p) =>
    p.$isAnimating &&
    css`
      animation: 1s ease-in-out infinite alternate ${pulse};
    `}
`

const ErrorMessage = styled(Heading).attrs({
  extraSmall: true,
  as: "p",
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`

export default Loader
