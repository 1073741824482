import { useState, useRef } from "react"
import styled from "styled-components/macro"
import Flicking, { WillChangeEvent } from "@egjs/react-flicking"
import "@egjs/react-flicking/dist/flicking.css"
import Pagination from "components/Pagination"
import Tile from "./Tile"

function Slider({ items }) {
  const slider = useRef()
  const itemsPerPage = 1
  const pageCount = Math.ceil(items.length / itemsPerPage)
  const [activePageIndex, setActivePageIndex] = useState(0)

  const sliderOptions = {
    align: "prev",
    circular: true,
    renderOnlyVisible: true,
    onWillChange: (e: WillChangeEvent) => {
      setActivePageIndex(Math.floor(e.index / itemsPerPage))
    },
  }

  async function slideTo({ direction, page }) {
    try {
      if (direction) {
        await slider.current[direction]()
      } else if (typeof page === "number") {
        await slider.current.moveTo(page * itemsPerPage)
      }
    } catch (e) {}
  }

  return (
    <>
      <Items ref={slider} {...sliderOptions}>
        {items.map(({ title, text, imageUrl, url }, index) => (
          <Item key={index}>
            <Tile title={title} text={text} imageUrl={imageUrl} url={url} />
          </Item>
        ))}
      </Items>
      <Pagination
        pageCount={pageCount}
        activePageIndex={activePageIndex}
        onPrevClick={() => {
          slideTo({ direction: "prev" })
        }}
        onNextClick={() => {
          slideTo({ direction: "next" })
        }}
        onPageClick={(index) => {
          slideTo({ page: index })
        }}
      />
    </>
  )
}

const Items = styled(Flicking)`
  padding-bottom: 65px;
  position: relative;
  white-space: nowrap;
`

const Item = styled.div``

export default Slider
