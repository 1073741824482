import Container from "components/Container"
import { Heading } from "components/Typography"
import Scrollspy from "react-scrollspy"
import { animateScroll } from "react-scroll"
import { rgba } from "polished"
import styled from "styled-components/macro"
import theme from "styles/theme"

function PageNavigation({ items, onItemClick }) {
  const onClick = (name) => () => {
    scrollToElement(name.toLowerCase())
    onItemClick && onItemClick(name)
  }

  function scrollToElement(id) {
    const element = document.getElementById(id)
    if (!element) {
      return
    }
    const position = element.offsetTop - theme.headerHeight
    animateScroll.scrollTo(position, {
      duration: 1200,
      smooth: "easeInOutCubic",
    })
    window.location.hash = `#${id}`
  }

  return (
    <Element>
      <Container>
        <List
          items={["assets", "templates", "faqs"]}
          currentClassName="-active"
          offset={-100}
        >
          {items.map(({ title }, index) => (
            <Item key={index}>
              <Link href={`#${title.toLowerCase()}`} onClick={onClick(title)}>
                {title}
              </Link>
            </Item>
          ))}
        </List>
      </Container>
    </Element>
  )
}

const Element = styled.nav`
  position: sticky;
  top: ${(p) => p.theme.headerHeight - 10}px;
  z-index: 9;
  background-color: ${(p) => p.theme.color.background};
  box-shadow: 0 2px 0 ${(p) => rgba(p.theme.color.black, 0.1)};
`

const List = styled(Scrollspy)`
  display: flex;
  height: 66px;
  list-style: none;
`

const Item = styled(Heading).attrs({
  small: true,
  as: "li",
})`
  display: inline-flex;
  position: relative;
  color: ${(p) => rgba(p.theme.color.black, 0.32)};
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background-color: currentColor;
    transition: width 0s ${(p) => p.theme.easeOutCubic};
  }

  &&:not(:last-child) {
    margin-bottom: 0;
    margin-right: 80px;
  }

  &.-active,
  &:hover {
    color: ${(p) => p.theme.color.black};
  }

  &.-active {
    &::before {
      width: 100%;
      transition-duration: 0.9s;
    }
  }
`

const Link = styled.a`
  display: inherit;
  align-items: center;
  height: 100%;
`

export default PageNavigation
