import { css } from "styled-components/macro"

function onScrollAnimations(parentElement) {
  return css`
    > * {
      transition: transform 1s ${(p) => p.theme.easeOutQuart};
      will-change: transform;
    }

    ${parentElement}.-in-view & {
      > :nth-child(2) {
        transition-delay: 0.1s;
      }
      > :nth-child(3) {
        transition-delay: 0.2s;
      }
      > :nth-child(4) {
        transition-delay: 0.3s;
      }
    }

    ${parentElement}:not(.-in-view) & {
      > * {
        transform: translateY(120px);
      }
    }
  `
}

export default onScrollAnimations
