import Header from "components/Header"
import ScrollToTop from "components/ScrollToTop"

function MinimalLayout({ children }) {
  return (
    <div className="App">
      <Header isTransparent />
      {children}
      <ScrollToTop />
    </div>
  )
}

export default MinimalLayout
