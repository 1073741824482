import { ReactComponent as Icon } from "assets/icons/arrowRightLong.svg"
import { Link } from "react-router-dom"
import { media } from "styles/breakpoints"
import styled from "styled-components/macro"

function QuickLinks({ title, links }) {
  
  return (
    <Element>
      <Title>{title}</Title>
      <Items>
        {links.map(({ title, url }, index) => {
          
          const isLinkExternal = url && url.includes("http")
          const tag = url && (isLinkExternal ? "a" : Link)
          return(
          
          <Item key={index}>
            <ItemLink
              to={isLinkExternal ? null : url}
              href={isLinkExternal ? url : null}
              target={isLinkExternal ? "_blank" : null}
              as={tag}>
              {title}
              <Arrow />
            </ItemLink>
          </Item>
        )})}
      </Items>
    </Element>
  )
}

const Element = styled.div``

const Title = styled.h3`
  margin-bottom: 48px;
  font-weight: 500;
  font-size: 40px;
  line-height: 1.2; // TODO: reuse h2?
`

const Items = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #393a39;

  ${media.mediumTablet} {
    font-size: 20px;
  }
`

const Item = styled.li``

const ItemLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 112px;
  padding: 0 ${(p) => p.theme.sidePadding.small}px;
  border: 1px solid;

  &:hover {
    background-color: ${(p) => p.theme.color.black};
    border-color: transparent;
    color: ${(p) => p.theme.color.white};
  }

  ${media.smallDesktop} {
    padding-left: 32px;
    padding-right: 32px;
  }
`

const Arrow = styled(Icon)`
  width: 0;
  min-width: 30px;
  margin-left: 20px;

  ${media.mediumTablet} {
    margin-left: 30px;
  }

  ${media.smallDesktop} {
    min-width: 56px;
    margin-left: 46px;
  }
`

export default QuickLinks
