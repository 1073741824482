import styled, { css } from "styled-components/macro"

import Hotspots from "./Hotspots"
import Numbers from "./Numbers"
import { media } from "styles/breakpoints"

const layers = ["Positioning", "Tone", "Values", "Promise", "Purpose"]

function getImage(name, ext, size) {
  return require(`assets/images/layeredFigure/${name.toLowerCase()}.${ext}`)
    ?.default
}

function Img({ size, ...rest }) {
  if (size === "small") {
    return <SmallImage {...rest} />
  } else {
    return <LargeImage {...rest} />
  }
}

function LayeredFigure({
  size,
  alignLeft,
  hasHotspots,
  hasNumbers,
  isBlurred,
  isolatedLayerIndex,
  focusedLayerIndex,
  activeNumberIndex,
  className,
  onNumberMouseEnter,
  onNumberMouseLeave,
}) {
  return (
    <Element
      size={size}
      isBlurred={isBlurred}
      data-align-left={alignLeft ? "true" : "false"}
      data-isolated-layer={isolatedLayerIndex}
      data-focused-layer={focusedLayerIndex}
      data-number-hover={activeNumberIndex}
      className={className}
    >
      {layers.map((name, index) => (
        <Layer key={index}>
          <source
            srcSet={`${getImage(name, "png", size)}, ${getImage(
              name,
              "png"
            )} 2x`}
            type="image/png"
          />
          <Img
            isBlurred={isBlurred}
            size={size}
            src={getImage(name, "png", size)}
            alt={name}
            loading="lazy"
            className={
              activeNumberIndex === layers.length - 1 - index &&
              "-number-active"
            }
          />
        </Layer>
      ))}
      <Hotspots isVisible={hasHotspots} items={layers} />
      <Numbers
        isVisible={hasNumbers}
        items={layers}
        activeItemIndex={activeNumberIndex}
        onItemMouseEnter={(index) => {
          onNumberMouseEnter(index)
        }}
        onItemMouseLeave={() => {
          onNumberMouseLeave()
        }}
      />
    </Element>
  )
}

const Element = styled.figure`
  isolation: isolate;
  transition: opacity 1s, transform 1.5s, filter 1s;
  fill-change: opacity, transform, filter, left, top, width, height;

  ${(p) =>
    p.isBlurred &&
    css`
      filter: blur(7px);
    `}
`

const Layer = styled.picture``

const Image = styled.img`
  max-width: none;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  will-change: opacity, transform, left, top, width, height;
`

const SmallImage = styled(Image)`
  ${Layer}:nth-child(1) & {
    width: 280px;

    ${media.smallDesktop} {
      transform: translateX(
        calc((1 - var(--header-scroll-progress, 0)) * -450px)
      );
    }
  }

  ${Layer}:nth-child(2) & {
    width: 140px;
    top: 63.31px;
    left: 113px;
    z-index: 1;

    ${media.smallDesktop} {
      transform: translateX(
        calc((1 - var(--header-scroll-progress, 0)) * -300px)
      );
    }
  }

  ${Layer}:nth-child(3) & {
    width: 166.75px;
    top: 66.88px;
    left: 150px;

    ${media.smallDesktop} {
      transform: translateX(
        calc((1 - var(--header-scroll-progress, 0)) * -150px)
      );
    }
  }

  ${Layer}:nth-child(4) & {
    width: 147.13px;
    top: 103.44px;
    left: 191px;
  }

  ${Layer}:nth-child(5) & {
    width: 112.36px;
    top: 156.05px;
    left: 317px;

    ${media.smallDesktop} {
      transform: translateX(
        calc((1 - var(--header-scroll-progress, 0)) * 85px)
      );
    }
  }
`

const LargeImage = styled(Image)`
  transition: opacity 0.35s, transform 1.7s ${(p) => p.theme.easeOutCubic};

  ${Layer} & {
    transition: 1s;
  }

  ${Layer}:nth-child(1) & {
    width: 242.67px;
    height: 364px;

    ${Element}[data-align-left="true"] & {
      width: 370.87px;
      height: 556.31px;
    }

    ${Element}[data-isolated-layer="1"] &,
    ${Element}[data-isolated-layer="2"] &,
    ${Element}[data-isolated-layer="3"] &,
    ${Element}[data-isolated-layer="4"] & {
      transform: translateX(calc(-50vw - 1000px));
    }

    ${Element}[data-focused-layer="0"][data-focused-layer="0"] & {
      transform: translateX(300px);
    }

    ${Element}[data-isolated-layer="0"] & {
      transform: translateX(60%);
    }

    ${Element}[data-number-hover="0"] & {
      transform: translateX(-30px);
    }

    ${Element}[data-number-hover="1"] & {
      transform: translateX(-20px);
    }

    ${Element}[data-number-hover="2"] &,
    ${Element}[data-number-hover="3"] & {
      transform: translateX(-10px);
    }
  }

  ${Layer}:nth-child(2) & {
    width: 121.33px;
    height: 255.03px;
    top: 54.87px;
    left: 137.56px;
    z-index: 1;

    ${Element}[data-align-left="true"] & {
      width: 185.44px;
      height: 389.77px;
      top: 82.68px;
      left: 128.74px;
    }

    ${Element}[data-isolated-layer="0"] & {
      transform: translateX(calc(50vw + 300px));
    }

    ${Element}[data-isolated-layer="2"] &,
    ${Element}[data-isolated-layer="3"] &,
    ${Element}[data-isolated-layer="4"] & {
      transform: translateX(calc(-50vw - 650px));
    }

    ${Element}[data-focused-layer="1"][data-focused-layer="1"] & {
      transform: translateX(200px) scale(1.5);
    }

    ${Element}[data-isolated-layer="1"] & {
      transform: scale(1.5) translateX(40%);
    }

    ${Element}[data-number-hover="0"] & {
      transform: translateX(-20px);
    }

    ${Element}[data-number-hover="1"] &,
    ${Element}[data-number-hover="2"] & {
      transform: translateX(-10px);
    }

    ${Element}[data-number-hover="4"] & {
      transform: translateX(10px);
    }
  }

  ${Layer}:nth-child(3) & {
    width: 144.52px;
    height: 251.94px;
    top: 57.96px;
    left: 223.35px;

    ${Element}[data-align-left="true"] & {
      width: 220.87px;
      height: 385.05px;
      top: 88.58px;
      left: 178.35px;
    }

    ${Element}[data-isolated-layer="0"] &,
    ${Element}[data-isolated-layer="1"] & {
      transform: translateX(calc(50vw + 300px));
    }

    ${Element}[data-isolated-layer="3"] &,
    ${Element}[data-isolated-layer="4"] & {
      transform: translateX(calc(-50vw - 650px));
    }

    ${Element}[data-focused-layer="2"][data-focused-layer="2"] & {
      transform: translateX(200px) scale(1.5);
    }

    ${Element}[data-isolated-layer="2"] & {
      transform: scale(1.5) translateX(-10%);
    }

    ${Element}[data-number-hover="0"] & {
      transform: translateX(-20px);
    }

    ${Element}[data-number-hover="1"] & {
      transform: translateX(-10px);
    }

    ${Element}[data-number-hover="3"] &,
    ${Element}[data-number-hover="4"] & {
      transform: translateX(10px);
    }
  }

  ${Layer}:nth-child(4) & {
    width: 127.52px;
    height: 184.7px;
    top: 89.65px;
    left: 354.73px;

    ${Element}[data-align-left="true"] & {
      width: 194.89px;
      height: 282.29px;
      top: 140.55px;
      left: 231.5px;
    }

    ${Element}[data-isolated-layer="0"] &,
    ${Element}[data-isolated-layer="1"] &,
    ${Element}[data-isolated-layer="2"] & {
      transform: translateX(calc(50vw + 600px));
    }

    ${Element}[data-isolated-layer="4"] & {
      transform: translateX(calc(-50vw - 300px));
    }

    ${Element}[data-isolated-layer="3"][data-focused-layer="3"] & {
      transform: translateX(70%) scale(1.7);
    }

    ${Element}[data-isolated-layer="3"] & {
      transform: scale(1.7) translateX(-70%);
    }

    ${Element}[data-number-hover="0"] & {
      transform: translateX(-10px);
    }

    ${Element}[data-number-hover="2"] & {
      transform: translateX(10px);
    }

    ${Element}[data-number-hover="3"] &,
    ${Element}[data-number-hover="4"] & {
      transform: translateX(20px);
    }
  }

  ${Layer}:nth-child(5) & {
    width: 97.38px;
    height: 96.6px;
    top: 135.25px;
    left: 462.15px;

    ${Element}[data-align-left="true"] & {
      width: 148.82px;
      height: 147.64px;
      top: 211.42px;
      left: 319.18px;
    }

    ${Element}[data-isolated-layer="0"] &,
    ${Element}[data-isolated-layer="1"] &,
    ${Element}[data-isolated-layer="2"] &,
    ${Element}[data-isolated-layer="3"] & {
      transform: translateX(calc(50vw + 900px));
    }

    ${Element}[data-isolated-layer="4"][data-focused-layer="4"] & {
      transform: scale(2) translateX(-10%);
    }

    ${Element}[data-isolated-layer="4"] & {
      transform: scale(2) translateX(-120%);
    }
  }

  ${Element}[data-number-hover] &.-number-active {
    transform: scale(1.1);
  }

  ${Element}[data-number-hover] &:not(.-number-active) {
    opacity: 0.1;
  }

  ${Element}[data-number-hover="1"] & {
    transform: translateX(10px);
  }

  ${Element}[data-number-hover="2"] & {
    transform: translateX(20px);
  }

  ${Element}[data-number-hover="3"] &,
  ${Element}[data-number-hover="4"] & {
    transform: translateX(30px);
  }
`

export default LayeredFigure
