import { Helmet } from "react-helmet"
import HeroWithSegmentedCircle from "components/HeroWithSegmentedCircle"
import Layout from "layouts"
import Loader from "components/Loader"
import { ROUTE_TITLES } from "config/constants"
import SectionQuestion from "components/SectionQuestion"
import SectionShowcase from "components/SectionShowcase"
import SectionWithSegmentedCircle from "components/SectionWithSegmentedCircle"
import { getNonceValue } from "helpers/getNonceValue"
import { useQuery } from "jsonapi-react"

function FieldGuide() {
  const { data, isLoading, error } = useQuery([
    "node/field_guide_page",
    {
      include: ["field_hero_content_box", "field_s_map_overview"],
      nonce: getNonceValue(),
    },
  ])
  const page = data && data[0]
  const { hero, sectionQuestion, sectionWithCircle, sectionShowcase } = {
    ...page,
  }

  return (
    <>
      <Helmet>
        <title>{ROUTE_TITLES["/field-guide"]}</title>
      </Helmet>
      {isLoading || error ? (
        <Loader error={error} />
      ) : (
        <Layout>
          <HeroWithSegmentedCircle
            title={hero.title}
            textColumns={hero.getTextColumns()}
            ctaText="Let’s get started."
          />
          <SectionQuestion
            title={sectionQuestion.title}
            question={sectionQuestion.question}
          />
          <SectionWithSegmentedCircle
            segments={sectionWithCircle.getSegments()}
          />
          <SectionShowcase
            title={sectionShowcase.title}
            subtitle={sectionShowcase.subtitle}
          />
        </Layout>
      )}
    </>
  )
}

export default FieldGuide
