import styled from "styled-components/macro"
import { media } from "styles/breakpoints"
import Slider from "./Slider"

function SectionTileRow({ tiles }) {
  return (
    <Element>
      <Container>
        <Slider items={tiles} />
      </Container>
    </Element>
  )
}

const Element = styled.section`
  overflow: hidden;
  margin-top: -40px;
  padding-bottom: 100px;
  position: relative;
`

const Container = styled.div`
  margin-left: ${(p) => p.theme.sidePadding.small}px;

  ${media.smallDesktop} {
    margin-left: ${(p) => p.theme.sidePadding.medium}px;
  }

  ${media.mediumDesktop} {
    margin-left: ${(p) => p.theme.sidePadding.large}px;
  }

  @media (min-width: ${(p) =>
      p.theme.containerWidth + p.theme.sidePadding.large * 2}px) {
    margin-left: calc((100vw - ${(p) => p.theme.containerWidth}px) / 2);
  }

  @media (min-width: 2200px) {
    /* max-width: 1576px; */
  }
`

export default SectionTileRow
