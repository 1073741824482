import { Switch, Route, useRouteMatch, useParams } from "react-router-dom"
import Layout from "layouts"
import CaseStudy from "components/CaseStudy"

function NestedRoute() {
  const { slug } = useParams()
  return <CaseStudy slug={slug} />
}

function Showcase() {
  const match = useRouteMatch()

  return (
    <Layout>
      <Switch>
        <Route path={`${match.path}/:slug`}>
          <NestedRoute />
        </Route>
        <Route path={match.path} />
      </Switch>
    </Layout>
  )
}

export default Showcase
