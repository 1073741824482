import { breakpoints } from "styles/breakpoints"
import useWindowSize from "hooks/useWindowSize"
import HomeDesktop from "./HomeDesktop"
import HomeMobile from "./HomeMobile"

function Home() {
  const { width } = useWindowSize()

  return width >= breakpoints.smallTablet ? <HomeDesktop /> : <HomeMobile />
}

export default Home
