import styled, { css } from "styled-components/macro"
import { useEffect, useState } from "react"

import Container from "components/Container"
import Gallery from "./Gallery"
import Header from "./Header"
import { Helmet } from "react-helmet"
import Loader from "components/Loader"
import Map from "./Map"
import { getNonceValue } from "helpers/getNonceValue"
import slugify from "helpers/slugify"
import { trackPageView } from "utils/analytics"
import { useQuery } from "jsonapi-react"

function CaseStudy({ slug }) {
  let { data, isLoading, error } = useQuery([
    "node/showcase_detail",
    {
      include: [
        "field_showcase_category",
        "field_header_image",
        "field_header_video",
        "field_gallery_images",
        "field_gallery_images.field_g_image_item",
        "field_gallery_images.field_g_video_item",
        "field_gallery_images.field_g_video_thumbnail",
      ],
      nonce: getNonceValue(),
    },
  ])
  const page = data?.find((item) => slugify(item.title) === slug)
  const {
    title,
    getCategory,
    description,
    getHeaderImageUrl,
    getHeaderVideoUrl,
    map,
    getGallery,
  } = {
    ...page,
  }
  const [isVisible, setVisibility] = useState(false)

  error = error || (data && !page)

  useEffect(() => {
    setVisibility(!!page)
  }, [page])

  // useEffect(() => {
  //   if (title) {
  //     trackPageView(title)
  //   }
  // }, [title])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isLoading || error ? (
        <Loader error={error} />
      ) : (
        <Element isVisible={isVisible}>
          <Container>
            <Header
              title={title}
              category={getCategory()}
              description={description}
              imageUrl={getHeaderImageUrl()}
              videoUrl={getHeaderVideoUrl()}
            />
            <Map
              title={map.title}
              legend={map.legend}
              ballPosition={map.ballPosition}
            />
            <Gallery slides={getGallery()} />
          </Container>
        </Element>
      )}
    </>
  )
}

const Element = styled.div`
  overflow: hidden;
  transition: 1s 0.15s ${(p) => p.easeOutExpo};
  transition-property: visibility, opacity, transform;

  ${(p) =>
    !p.isVisible &&
    css`
      visibility: hidden;
      opacity: 0;
      transform: translateY(100px);
    `}
`

export default CaseStudy
