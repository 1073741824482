import styled, { css } from "styled-components/macro"
import { Heading } from "components/Typography"
import Container from "components/Container"
import { fadeOut, slideInUp, slideInLeft, wordSpaceIn } from "styles/keyframes"

function Slide({ alignCenter, active, className, children }) {
  return (
    <Element alignCenter={alignCenter} active={active} className={className}>
      <Content>{children}</Content>
    </Element>
  )
}

const Element = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${Heading} {
    position: relative;
    z-index: 1;
  }

  ${(p) =>
    p.alignCenter &&
    css`
      text-align: center;

      ${Heading} {
        margin-left: auto;
        margin-right: auto;
      }
    `}

  &:not(.-active) {
    pointer-events: none;
  }
`

const wordStyles = (index, baseDelay) => {
  return css`
    animation-delay: ${index * 0.07 + baseDelay}s; // TODO: refactor
  `
}

const Content = styled(Container)`
  > * {
    position: relative;
    z-index: 1;
    opacity: 0;
    will-change: opacity, transform;

    &.-word-spacing {
      word-spacing: 0.3em;

      span span {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 0.28em;
        }
      }
    }
  }

  ${Element}.-active & {
    > * {
      animation: ${slideInUp} 1.2s ${(p) => p.theme.easeOutQuart} 0.3s forwards;
      will-change: opacity, transform;

      &.-slide-in-left {
        animation-name: ${slideInLeft};
      }

      &.-word-spacing {
        opacity: 1;
        animation-name: ${wordSpaceIn};
        will-change: word-spacing;

        span span {
          opacity: 0;
          animation: ${slideInLeft} 1.2s ${(p) => p.theme.easeOutQuart} 0.3s
            forwards;
          will-change: opacity, transform;

          &:nth-child(1) {
            ${wordStyles(0, 0.3)};
          }

          &:nth-child(2) {
            ${wordStyles(1, 0.3)};
          }

          &:nth-child(3) {
            ${wordStyles(2, 0.3)};
          }

          &:nth-child(4) {
            ${wordStyles(3, 0.3)};
          }

          &:nth-child(5) {
            ${wordStyles(4, 0.3)};
          }

          &:nth-child(6) {
            ${wordStyles(5, 0.3)};
          }

          &:nth-child(7) {
            ${wordStyles(6, 0.3)};
          }

          &:nth-child(8) {
            ${wordStyles(7, 0.3)};
          }

          &:nth-child(9) {
            ${wordStyles(8, 0.3)};
          }

          &:nth-child(10) {
            ${wordStyles(9, 0.3)};
          }
        }
      }

      &.-delay-1 {
        animation-delay: 0.335s;
      }

      &.-delay-2 {
        animation-delay: 0.37s;
      }

      &.-delay-3 {
        animation-delay: 0.405s;
      }

      &.-delay-small {
        animation-delay: 0.55s; // TODO: refactor

        span span {
          &:nth-child(1) {
            ${wordStyles(0, 0.55)};
          }

          &:nth-child(2) {
            ${wordStyles(1, 0.55)};
          }

          &:nth-child(3) {
            ${wordStyles(2, 0.55)};
          }

          &:nth-child(4) {
            ${wordStyles(3, 0.55)};
          }

          &:nth-child(5) {
            ${wordStyles(4, 0.55)};
          }

          &:nth-child(6) {
            ${wordStyles(5, 0.55)};
          }

          &:nth-child(7) {
            ${wordStyles(6, 0.55)};
          }

          &:nth-child(8) {
            ${wordStyles(7, 0.55)};
          }

          &:nth-child(9) {
            ${wordStyles(8, 0.55)};
          }

          &:nth-child(10) {
            ${wordStyles(9, 0.55)};
          }
        }
      }

      &.-delay-medium {
        animation-delay: 0.8s;

        span span {
          &:nth-child(1) {
            ${wordStyles(0, 0.8)};
          }

          &:nth-child(2) {
            ${wordStyles(1, 0.8)};
          }

          &:nth-child(3) {
            ${wordStyles(2, 0.8)};
          }

          &:nth-child(4) {
            ${wordStyles(3, 0.8)};
          }

          &:nth-child(5) {
            ${wordStyles(4, 0.8)};
          }

          &:nth-child(6) {
            ${wordStyles(5, 0.8)};
          }

          &:nth-child(7) {
            ${wordStyles(6, 0.8)};
          }

          &:nth-child(8) {
            ${wordStyles(7, 0.8)};
          }

          &:nth-child(9) {
            ${wordStyles(8, 0.8)};
          }

          &:nth-child(10) {
            ${wordStyles(9, 0.8)};
          }
        }
      }
    }
  }

  ${Element}.-prev-active & {
    > * {
      animation: ${fadeOut} 0.7s ${(p) => p.theme.easeOutQuart};
    }
  }

  ${Element}:not(.-active) & {
    > * {
    }
  }

  ${Element}:not(.-active):not(.-prev-active) & {
    > * {
    }
  }
`

export default Slide
