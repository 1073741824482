import styled, { css } from "styled-components/macro"
import { rgba } from "polished"
import { media } from "styles/breakpoints"
import { Heading, Paragraph } from "components/Typography"
import { ReactComponent as XIcon } from "assets/icons/x.svg"
import { invertedSelection } from "styles/mixins"

function Drawer({ type, title, content, open, onClose }) {
  return (
    <Element open={open} className={open && "-open"}>
      <Title small>{title}</Title>
      <Inner>
        {type === "grid" ? (
          <Grid>
            {content.map(({ title, description }, index) => (
              <Box key={index}>
                <Heading extraLarge as="h4">
                  0{index + 1}.
                </Heading>
                <Heading medium as="h3">
                  {title}
                </Heading>
                <Paragraph>{description}</Paragraph>
              </Box>
            ))}
          </Grid>
        ) : (
          <Columns>
            {content.map(({ title, description, content }, index) => (
              <Box key={index}>
                <BoxPart>
                  <ColumnTitle>{title}</ColumnTitle>
                  <Paragraph>{description}</Paragraph>
                </BoxPart>
                <BoxPart>{content}</BoxPart>
              </Box>
            ))}
          </Columns>
        )}
        <CloseButton onClick={onClose}>
          <CloseButtonIcon />
        </CloseButton>
      </Inner>
    </Element>
  )
}

const Element = styled.section`
  height: 100%;
  padding-left: 40px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 11;
  transform: translateX(100%);
  background-image: linear-gradient(
    to right,
    ${(p) => rgba(p.theme.color.green, 0.95)} 40px,
    ${(p) => rgba(p.theme.color.black, 0.95)} 40px
  );
  color: ${(p) => p.theme.color.white};
  transition: transform 0.7s ${(p) => p.theme.easeOutQuart};
  will-change: transform;
  ${invertedSelection};

  ${(p) =>
    p.open &&
    css`
      transform: none;
      transition-duration: 1s;
    `}

  ${media.mediumDesktop} {
    left: ${(p) => p.theme.sidePadding.large}px;
  }

  @media (min-width: ${(p) =>
      p.theme.containerWidth + p.theme.sidePadding.large * 2}px) {
    left: calc((100vw - ${(p) => p.theme.containerWidth}px) / 2);
  }
`

const Title = styled(Heading).attrs({
  small: true,
})`
  width: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: 0 0;
  line-height: 40px;
  text-align: center;
  color: ${(p) => p.theme.color.black};
`

const Inner = styled.div`
  width: calc(100% - ${(p) => p.theme.sidePadding.small}px);
  max-width: ${(p) => p.theme.containerWidth - 40}px;
  height: inherit;
  padding-left: 64px;
  position: relative;

  ${media.mediumDesktop} {
    width: calc(100% - 105px);
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  column-gap: 72px;
  row-gap: 10vh;
  height: inherit;

  @media (min-height: 1200px) {
    row-gap: 120px;
  }
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  column-gap: 32px;
  height: inherit;

  @media (max-height: 800px) {
    padding-top: 50px;
  }

  @media (max-height: 700px) {
    ${Paragraph} {
      font-size: 0.9em;
      line-height: 1.25;
    }
  }

  @media (max-height: 630px) {
    h3 {
      font-size: 1.5em;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    h4 {
      margin-top: 16px;
      font-size: 0.9em;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  ${media.ltMediumTablet} {
    column-gap: 8px;

    ${Paragraph} {
      font-size: 0.95em;
    }
  }
`

const Box = styled.div`
  transition: 0.8s ${(p) => p.theme.easeOutQuart};
  will-change: opacity, transform;

  ${Columns} & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 24px;
    border: 1px solid;
  }

  ${Element}.-open & {
    &:nth-child(1) {
      transition-delay: 0.6s;
    }

    &:nth-child(2) {
      transition-delay: 0.75s;
    }

    &:nth-child(3) {
      transition-delay: 0.9s;
    }

    &:nth-child(4) {
      transition-delay: 1.05s;
    }
  }

  ${Element}:not(.-open) & {
    transition-duration: 0s;
    transition-delay: 0.7s;
    opacity: 0;
    transform: translateX(100px);
  }
`

const BoxPart = styled.div`
  ${Heading} {
    margin-top: 34px;

    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }
`

const ColumnTitle = styled.h3`
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 40px;
  line-height: 1.2; /* 48px */
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  right: -4px;
  border-radius: 50%;
  color: ${(p) => p.theme.color.black};
  cursor: pointer;
  transition: 0.6s ${(p) => p.theme.easeOutQuart};
  transition-property: opacity, transform;

  &::before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: -1;
    background-color: ${(p) => p.theme.color.white};
    border-radius: inherit;
  }

  &::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    border: 2px solid transparent;
    border-radius: inherit;
  }

  &:hover {
    color: ${(p) => p.theme.color.white};

    &::before {
      background-color: ${(p) => p.theme.color.black};
    }

    &::after {
      border-color: currentColor;
    }
  }

  ${Element}:not(.-open) & {
    transition-duration: 0s;
    transition-delay: 0.7s;
    opacity: 0;
    transform: translateX(100px);
  }

  @media (min-height: 800px) {
    top: 24px;
  }
`

const CloseButtonIcon = styled(XIcon)`
  transition: transform 0.6s ${(p) => p.theme.easeOutQuart};

  ${CloseButton}:hover & {
    transform: rotate(90deg);
  }
`

export default Drawer
