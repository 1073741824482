const breakpoints = {
  phone: 320,
  smallTablet: 768,
  mediumTablet: 1024,
  smallDesktop: 1200,
  mediumDesktop: 1400,
}

const ltMediaQuery = (maxWidth) => `@media (max-width: ${maxWidth - 1}px)`
const gteMediaQuery = (maxWidth) => `@media (min-width: ${maxWidth}px)`

const media = {
  ltPhone: ltMediaQuery(breakpoints.phone),
  ltSmallTablet: ltMediaQuery(breakpoints.smallTablet),
  ltMediumTablet: ltMediaQuery(breakpoints.mediumTablet),
  ltSmallDesktop: ltMediaQuery(breakpoints.smallDesktop),
  ltMediumDesktop: ltMediaQuery(breakpoints.mediumDesktop),
  phone: gteMediaQuery(breakpoints.phone),
  smallTablet: gteMediaQuery(breakpoints.smallTablet),
  mediumTablet: gteMediaQuery(breakpoints.mediumTablet),
  smallDesktop: gteMediaQuery(breakpoints.smallDesktop),
  mediumDesktop: gteMediaQuery(breakpoints.mediumDesktop),
  touch: "@media (hover: none) and (pointer: coarse)",
  pointer: "@media (hover: hover) and (pointer: fine)",
}

export { breakpoints, media }
