import { createGlobalStyle } from "styled-components"
import { media } from "./breakpoints"
import spacing from "./spacing"
import "./fonts.css"
import "./reset.css"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(p) => p.theme.color.background};
    font-weight: ${(p) => p.theme.basefontWeight};
    font-size: ${(p) => p.theme.baseFontSize};
    line-height: ${(p) => p.theme.baseLineHeight};
    font-family: ${(p) => p.theme.font.primary};
    color: ${(p) => p.theme.color.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
    
    ${media.ltSmallTablet} {
     font-weight: 300; 
    }
  }

  ::selection {
    background: ${(p) => p.theme.color.text};
    color: ${(p) => p.theme.color.background};
  }

  a {
    text-decoration: none;
    color: inherit;
    transition: .15s;
  }
  
  svg {
    fill: currentColor; 
  }
  
  ${spacing};
`

export { GlobalStyle }
