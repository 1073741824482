function splitLines(container) {
  const openTag = "<span class='line'>"
  const closingTag = "</span>"
  const spans = container.children
  let top = 0
  let tmp = ""
  container.innerHTML = container.textContent.replace(/\S+/g, "<n>$&</n>")
  for (let i = 0; i < spans.length; i++) {
    var rect = spans[i].getBoundingClientRect().top
    if (top < rect) tmp += closingTag + openTag
    top = rect
    tmp += spans[i].textContent + " "
  }
  container.innerHTML = tmp += closingTag
}

export default splitLines
