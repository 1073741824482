import { Column, Columns } from "components/Layout"
import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components/macro"

import Button from "components/Button"
import Container from "components/Container"
import { Heading } from "components/Typography"
import Observer from "@researchgate/react-intersection-observer"
import { ReactComponent as SvgExternalLink } from "assets/icons/externalLink2.svg"
import { appInsights } from "index"
import calculateHeights from "helpers/calculateHeights"
import { getFileType } from "utils/getFileType"
import { getNonceValue } from "helpers/getNonceValue"
import onScrollAnimations from "styles/onScrollAnimations"
import { rgba } from "polished"
import { trackEvent } from "utils/analytics"
import { useQuery } from "jsonapi-react"
import useWindowSize from "hooks/useWindowSize"

const checkDomain = function (url) {
  if (url.indexOf("//") === 0) {
    url = window.location.protocol + url
  }
  return url
    .toLowerCase()
    .replace(/([a-z])?:\/\//, "$1")
    .split("/")[0]
}

const isExternal = (url) => {
  return (
    (url.indexOf(":") > -1 || url.indexOf("//") > -1) &&
    checkDomain(window.location.href) !== checkDomain(url)
  )
}

function SectionAssets({ id, title, subtitle }) {
  const { data: categories } = useQuery([
    "taxonomy_term/asset_category",
    {
      sort: "weight",
      include: ["field_thumbnail_image", "field_download_file"],
      nonce: getNonceValue(),
    },
  ])
  const { data: singleAssets } = useQuery([
    "node/asset_item",
    {
      include: ["field_download_file", "field_asset_category"],
      nonce: getNonceValue(),
    },
  ])
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(null)
  const [isInView, setInView] = useState(false)
  const { width } = useWindowSize()
  const details = useRef([])
  details.current =
    categories &&
    categories.map(
      (element, index) =>
        (details.current && details.current[index]) ?? React.createRef()
    )

  function handleSectionClick(index) {
    const element = details.current[index].current
    let newIndex
    let height = 0
    if (index !== activeCategoryIndex) {
      newIndex = index
      height = element.dataset.maxHeight
    }
    setActiveCategoryIndex(newIndex)
    element.style.maxHeight = `${height}px`

    details.current
      .filter((item, detailsIndex) => detailsIndex !== index)
      .forEach(({ current: sibling }) => {
        sibling.style.maxHeight = 0
      })
  }

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  useEffect(() => {
    calculateHeights(details.current)
  }, [width, categories, singleAssets])

  return (
    <Observer onChange={handleIntersection}>
      <Element id={id} className={isInView && "-in-view"}>
        <StyledContainer>
          <Header>
            <Columns>
              <Column>
                <Heading extraLarge>{title}</Heading>
              </Column>
              <Column size="half">
                <Subtitle>{subtitle}</Subtitle>
              </Column>
            </Columns>
          </Header>
          <Assets>
            {categories?.map(
              (
                { name, description, getImageUrl, getDownloadUrl, field_order },
                index
              ) => {
                const downloads = singleAssets?.filter(
                  (asset) =>
                    asset.getCategoryName() === name && !!asset.getDownloadUrl()
                )
                const showDownloadAllButton =
                  downloads?.length > 0 &&
                  downloads.filter((item) => !item.field_url).length > 1
                return (
                  <Category
                    className={activeCategoryIndex === index && "-active"}
                    key={index}
                  >
                    <CategoryImageWrapper>
                      <CategoryImage
                        src={getImageUrl()}
                        alt=""
                        loading="lazy"
                      />
                    </CategoryImageWrapper>
                    <CategoryContent>
                      <CategoryName onClick={() => handleSectionClick(index)}>
                        {name}
                      </CategoryName>
                      <CategoryDetails ref={details.current[index]}>
                        <CategoryDescription>{description}</CategoryDescription>
                        {showDownloadAllButton && (
                          <DownloadAllButton
                            iconBox="download"
                            to={getDownloadUrl()}
                          >
                            All
                          </DownloadAllButton>
                        )}
                        {downloads?.length > 0 &&
                          downloads.map(
                            ({ title, getDownloadUrl, field_url }, index) => {
                              const url = getDownloadUrl()
                              const ext = !!field_url
                              if (!url) return null

                              return (
                                <DownloadItem
                                  href={url}
                                  target="_blank"
                                  key={index}
                                  external={ext}
                                >
                                  <div
                                    onClick={(e) => {
                                      trackEvent("media_downloaded", {
                                        media_type: `${getFileType(url)}`,
                                        media_name: title,
                                      })
                                    }}
                                  >
                                    <p>{title}</p>
                                    {ext ? (
                                      <SvgExternalLink />
                                    ) : (
                                      <DownloadItemButton>
                                        Download
                                      </DownloadItemButton>
                                    )}
                                  </div>
                                </DownloadItem>
                              )
                            }
                          )}
                      </CategoryDetails>
                    </CategoryContent>
                    <Switcher onClick={() => setActiveCategoryIndex(index)} />
                  </Category>
                )
              }
            )}
          </Assets>
        </StyledContainer>
      </Element>
    </Observer>
  )
}

const Element = styled.section`
  margin-bottom: 117px;
  padding-top: 90px;
`

const StyledContainer = styled(Container)`
  ${onScrollAnimations(Element)};
`

const Header = styled.header`
  margin-bottom: 90px;

  ${Column} {
    &:first-child {
      max-width: 350px;
    }

    &:last-child {
      padding-top: 30px;
    }
  }
`

const Subtitle = styled(Heading).attrs({
  semiMedium: true,
  "data-size": "semiMedium",
  as: "p",
})`
  max-width: 570px;
  font-weight: 400;
`

const Assets = styled.div`
  counter-reset: category;
`

const Category = styled.div`
  display: flex;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`

const CategoryImageWrapper = styled.div`
  width: 38.87987%;
  height: 60px;
  margin-right: 4.5%;
  position: relative;
  transition: height 0.5s ${(p) => p.theme.easeOutQuart};

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border: 1px solid;
  }

  ${Category}.-active & {
    height: 219px;
  }
`

const CategoryImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const CategoryContent = styled.div`
  flex: 1;
  padding-top: 9px;
  padding-left: 6.5%;
  position: relative;

  &::before {
    content: "0" counter(category);
    counter-increment: category;
    position: absolute;
    top: 9px;
    left: 0;
    font-size: 24px;
    line-height: 1.33333333;
    pointer-events: none;
  }
`

const CategoryName = styled(Heading).attrs({
  moleculeSmall: true,
  "data-size": "moleculeSmall",
  as: "h3",
})`
  margin-left: -12.98%;
  padding-bottom: 18px;
  padding-left: 12.98%;
  border-bottom: 1px solid;
  cursor: pointer;
  user-select: none;
`

const CategoryDetails = styled.div`
  overflow: hidden;
  max-height: 0;
  position: relative;
  transition: max-height 0.5s ${(p) => p.theme.easeOutQuart};
`

const CategoryDescription = styled.p`
  margin-right: 170px;
  margin-bottom: 33px;
  padding-top: 14px;
`

const DownloadAllButton = styled(Button)`
  flex-direction: row-reverse;
  position: absolute;
  top: 1px;
  right: 0;
  padding-right: 0;
  text-transform: none;
  font-weight: inherit;
  font-size: 16px;
  letter-spacing: inherit;

  span {
    margin-left: 16px;
    margin-right: 0;
  }

  &:hover {
    color: ${(p) => rgba(p.theme.color.black, 0.6)};
  }
`

const DownloadItem = styled.a`
  display: block;
  padding-top: 10px;
  height: 36px;

  &:hover {
    opacity: 0.6;
  }

  &:last-child {
    margin-bottom: 30px;
  }

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
  }

  ${(p) =>
    p.external &&
    css`
      svg {
        margin-left: 15px;
        transform: translateY(-2px);
      }
    `}

  ${(p) =>
    !p.external &&
    css`
      & > div {
        justify-content: space-between;
        border-bottom: 1px solid;
      }
    `}
`

const DownloadItemButton = styled.span`
  font-size: 16px;
`

const Switcher = styled.button`
  width: 34px;
  height: 34px;
  position: absolute;
  top: 8px;
  right: 0;
  background-color: ${(p) => p.theme.color.black};
  border: 2px solid transparent;
  border-radius: 50%;
  color: ${(p) => p.theme.color.white};
  pointer-events: none;

  &::before,
  &::after {
    content: "";
    width: 14px;
    height: 2px;
    position: absolute;
    top: 14px;
    left: 8px;
    background: currentColor;
    transition: transform 0.5s ${(p) => p.theme.easeOutQuart};
  }

  &:after {
    transform: rotate(90deg);
  }

  ${Category}.-active & {
    background-color: ${(p) => p.theme.color.white};
    border-color: currentColor;
    color: ${(p) => p.theme.color.black};

    &::before {
      opacity: 0;
      transform: rotate(90deg);
    }

    &::after {
      transform: rotate(180deg);
    }
  }
`

export default SectionAssets
