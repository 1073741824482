import { css } from "styled-components"
import { Columns } from "components/Layout"
import { Heading, Text, Paragraph } from "components/Typography"
import { Element as Button } from "components/Button/Button"

const spacing = css`
  ${Columns} {
    ${Heading}[data-size="extraLarge"] + & {
      margin-top: 100px;
    }
  }

  ${Heading} {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &[data-size="small"] {
      ${Heading}[data-size="extraLarge"] + & {
        margin-top: 50px;
      }
    }

    &[data-size="semiMedium"] {
      ${Heading}[data-size="small"] + & {
        margin-top: 24px;
      }

      ${Heading}[data-size="extraLarge"] + & {
        margin-top: 22px;
      }
    }

    &[data-size="moleculeLarge"] {
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }

    &[data-size="moleculeMedium"] {
      ${Heading}[data-size="moleculeMedium"] + & {
        margin-top: -16px;
      }
    }

    &[data-size="moleculeSubheading"] {
      ${Heading}[data-size="extraLarge"] + & {
        margin-top: 24px;
      }
    }

    &[data-size="moleculeLargeText"] {
      ${Heading}[data-size="moleculeLargeText"] + & {
        margin-top: 32px;
      }
    }
  }

  ${Text},
  ${Paragraph} {
    ${Heading}[data-size="extraLarge"] + &,
    ${Heading}[data-size="large"] + & {
      margin-top: 48px;
    }
  }

  ${Button} {
    ${Heading}[data-size="extraLarge"] + & {
      margin-top: 6px;
    }

    ${Heading}[data-size="medium"] + & {
      margin-top: 22px;
    }

    ${Heading}[data-size="moleculeSubheading"] + & {
      margin-top: 24px;
    }
  }
`

export default spacing
