import styled, { css } from "styled-components/macro"
import { ReactComponent as ChevronLeft } from "assets/icons/chevronLeft.svg"
import { ReactComponent as ChevronRight } from "assets/icons/chevronRight.svg"

function Pagination({
  pageCount,
  activePageIndex,
  onPrevClick,
  onNextClick,
  onPageClick,
}) {
  return (
    <Element>
      <NavigationButton aria-label="Previous" onClick={() => onPrevClick()}>
        <ChevronLeft />
      </NavigationButton>
      <Items>
        {[...Array(pageCount)].map((item, index) => (
          <Item
            isActive={activePageIndex === index}
            key={index}
            onClick={() => onPageClick(index)}
          />
        ))}
      </Items>
      <NavigationButton aria-label="Next" onClick={() => onNextClick()}>
        <ChevronRight />
      </NavigationButton>
    </Element>
  )
}

const Element = styled.div`
  display: flex;
  align-items: center;
  margin-left: -6px;
  user-select: none;
`

const buttonStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.25;
  }
`

const NavigationButton = styled.button`
  ${buttonStyles};
  padding: 0;
  border: none;
  background: none;
`

const Items = styled.ul`
  display: flex;
`

const Item = styled.li`
  ${buttonStyles};

  &::before {
    content: "";
    width: 18px;
    height: 18px;
    border: 2px solid;
    border-radius: 50%;
  }

  ${(p) =>
    p.isActive &&
    css`
      &::before {
        background-color: currentColor;
      }

      &:hover {
        opacity: 1;
      }
    `}
`

export default Pagination
