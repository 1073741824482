import { useState } from "react"
import styled from "styled-components/macro"
import Observer from "@researchgate/react-intersection-observer"
import Slider from "./GallerySlider"

function Gallery({ slides }) {
  const [isInView, setInView] = useState(false)

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  return (
    <Observer onChange={handleIntersection}>
      <Element className={isInView && "-in-view"}>
        <Title>Gallery</Title>
        <StyledSlider items={slides} />
      </Element>
    </Observer>
  )
}

const Element = styled.section``

const StyledSlider = styled(Slider)`
  transition: transform 1s ${(p) => p.theme.easeOutQuart};
  will-change: transform;

  ${Element}:not(.-in-view) & {
    visibility: hidden;
    transform: translateX(100px);
  }
`

const Title = styled.h2`
  margin-bottom: 70px;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2;
`

export default Gallery
