import Item from "./Item"
import { media } from "styles/breakpoints"
import slugify from "helpers/slugify"
import styled from "styled-components/macro"

function NewsList({ items, isCompact }) {
  return (
    <Element>
      {items.map(({ title, date, excerpt, getThumbnailUrl, field_url }, index) => (
        <Item
          title={title}
          date={date}
          excerpt={excerpt}
          imageUrl={getThumbnailUrl()}
          url={field_url ? field_url : `/news/${slugify(title)}`}
          isCompact={isCompact}
          key={index}
        />
      ))}
    </Element>
  )
}

const Element = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 80px;
  column-gap: 28px;
  margin-bottom: 40px;

  ${media.mediumTablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default NewsList
