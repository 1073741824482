import Container from "components/Container"
import { Heading } from "components/Typography"
import { Helmet } from "react-helmet"
import Layout from "layouts"
import { ROUTE_TITLES } from "config/constants"
import styled from "styled-components/macro"

function Search() {
  return (
    <Layout>
      <Helmet>
        <title>{ROUTE_TITLES["/search"]}</title>
      </Helmet>
      <Container>
        <Content>
          <Heading medium>Coming soon</Heading>
        </Content>
      </Container>
    </Layout>
  )
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export default Search
