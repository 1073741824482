import { getLuminance, rgba } from "polished"
import styled, { css } from "styled-components/macro"

import { Heading } from "components/Typography"
import { invertedSelection } from "styles/mixins"
import { media } from "styles/breakpoints"

function ColorCard({
  name,
  caption,
  cmyk,
  rgb,
  hex = "fff",
  pms,
  cardType,
  className,
}) {
  return (
    <Element hex={hex} className={(className || "") + ` -${cardType}`}>
      <Swatch style={{ backgroundColor: `#${hex}` }} />
      <Details>
        <Name>{name}</Name>
        {caption && <Caption>{caption}</Caption>}
        <Values>
          {cmyk && (
            <>
              <Model>CMYK:</Model>
              <Value dangerouslySetInnerHTML={{ __html: cmyk }} />
            </>
          )}
          {rgb && (
            <>
              <Model>RGB:</Model>
              <Value>{rgb}</Value>
            </>
          )}
          {hex && (
            <>
              <Model>HEX:</Model>
              <Value>{hex}</Value>
            </>
          )}
          {cardType === "simple" && pms && (
            <>
              <Model>PMS:</Model>
              <Value>{pms}</Value>
            </>
          )}
        </Values>
        {cardType === "extended" && pms && <Pantone>PMS {pms}</Pantone>}
      </Details>
    </Element>
  )
}

const Element = styled.div`
  max-width: 100%;
  position: relative;

  &.-simple {
    width: 400px;
    height: 248px;
    color: ${(p) => getLuminance(`#${p.hex}`) < 0.5 && p.theme.color.white};

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid ${(p) => rgba(p.theme.color.black, 0.5)};
      pointer-events: none;
    }

    ${(p) =>
      getLuminance(`#${p.hex}`) < 0.5 &&
      css`
        ${invertedSelection};
      `}
  }

  &.-extended {
    width: 329px;
    height: 429px;
    background-color: ${(p) => p.theme.color.white};
    border-radius: 5px;
    box-shadow: 0 4px 50px rgba(52, 74, 49, 0.2);
  }
`

const Swatch = styled.div`
  ${Element}.-simple & {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${Element}.-extended & {
    height: 272px;
    border-radius: 5px 5px 0 0;
  }
`

const Details = styled.div`
  ${Element}.-simple & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 15px 16px 18px;
    position: relative;
  }

  ${Element}.-extended & {
    padding: 26px 28px;
  }
`

const Name = styled(Heading).attrs({
  medium: true,
  "data-size": "medium",
  as: "h3",
})`
  ${Element}.-simple & {
    font-size: 32px;
  }

  ${Element}.-extended & {
    font-weight: 700;
    font-size: 25px;
  }
`

const Caption = styled(Heading).attrs({
  medium: true,
  "data-size": "medium",
  as: "h4",
})`
  ${Element}.-simple & {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 248px;
    height: 73px;
    padding-bottom: 2px;
    position: absolute;
    top: -73px;
    right: 0;
    transform: rotate(-90deg);
    transform-origin: 100% 100%;
    border-top: 2px solid;
    font-size: 32px;
  }
`

const Values = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 80px auto;
  grid-template-rows: auto;
  text-transform: uppercase;

  ${Element}.-simple & {
    font-size: 12px;
    line-height: 1.3333333;
  }

  ${Element}.-extended & {
    display: none;
  }
`

const Model = styled.div`
  font-weight: 500;
`

const Value = styled.div`
  flex: 1;
  cursor: text;
`

const Pantone = styled(Heading).attrs({
  medium: true,
  "data-size": "medium",
  as: "p",
})`
  position: absolute;
  right: 27px;
  bottom: 33px;

  ${media.ltMediumTablet} {
    display: none;
  }
`

export default ColorCard
