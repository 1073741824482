import styled, { css } from "styled-components/macro"
import { media } from "styles/breakpoints"
import Button from "components/Button"
import Container from "components/Container"
import { Heading } from "components/Typography"

function Hero({
  title,
  subtitle,
  textColumns,
  published,
  ctaButtonText,
  ctaButtonUrl,
  backgroundColor,
  backgroundImageUrl,
  backgroundImageOffsetX,
  backgroundSizeCover,
  backgroundSizeContain,
  size,
  titleSize,
  align,
}) {
  return (
    <Element size={size} backgroundColor={backgroundColor}>
      <StyledContainer align={align}>
        <Content>
          {published && <Published>Published {published}</Published>}
          <Title size={titleSize} dangerouslySetInnerHTML={{ __html: title }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          {textColumns && (
            <TextColumns>
              {textColumns.map((text, index) => (
                <TextColumn key={index}>{text}</TextColumn>
              ))}
            </TextColumns>
          )}
          {ctaButtonText && <Button to={ctaButtonUrl}>{ctaButtonText}</Button>}
        </Content>
      </StyledContainer>
      {backgroundSizeCover || backgroundSizeContain ? (
        <BackgroundCover
          backgroundImageUrl={backgroundImageUrl}
          contain={backgroundSizeContain}
        />
      ) : (
        <Background
          src={backgroundImageUrl}
          offsetX={backgroundImageOffsetX}
          alt=""
        />
      )}
    </Element>
  )
}

const Element = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${(p) => p.backgroundColor};

  ${media.ltSmallTablet} {
    padding-top: 92px;
  }

  ${media.smallTablet} {
    height: 570px;

    ${(p) =>
      p.size === "large" &&
      css`
        height: 760px;
      `}
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 35px;

  ${(p) =>
    p.align === "center" &&
    css`
      justify-content: center;
      text-align: center;
      padding-bottom: 0;
    `}

  ${media.smallTablet} {
    ${(p) =>
      !p.align === "center" &&
      css`
        padding-bottom: 122px;
      `}
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;

  ${media.ltSmallTablet} {
    text-align: center;
  }
`

const Published = styled.p`
  margin-top: 90px;
  margin-bottom: 29px;
  opacity: 0.5;
  font-size: 14px;
`

const Title = styled(Heading).attrs((p) => ({
  extraLarge: p.size !== "large",
  moleculeExtraLarge: p.size === "large", // TODO: refactor
  "data-size": p.size === "large" ? "moleculeExtraLarge" : "large",
  as: "h1",
}))`
  line-height: 1.09677419; /* 68px */ // TODO: remove?

  ${media.ltSmallTablet} {
    font-size: 32px;
  }

  ${media.smallTablet} {
    max-width: 10em;

    &&:not(:last-child) {
      margin-bottom: ${(p) => (p.size === "large" ? 20 : 28)}px;
    }
  }
`

const Subtitle = styled(Heading).attrs({
  semiMedium: true,
  "data-size": "semiMedium",
  as: "p",
})`
  &&:not(:last-child) {
    margin-bottom: 42px;
  }

  ${media.ltSmallTablet} {
    font-size: ${(p) => p.theme.baseFontSize};
  }

  ${media.smallTablet} {
    max-width: 55vw;
    white-space: pre-line;
  }
`

const TextColumns = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 660px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`

const TextColumn = styled.p`
  width: calc(50% - 15px);
`

const Background = styled.img`
  display: block;
  image-rendering: -webkit-optimize-contrast;
  pointer-events: none;

  ${media.smallTablet} {
    max-width: none;
    height: 100%;
    position: absolute;
    top: 0;
    left: calc(50% + ${(p) => p.offsetX || 0}px);
  }
`

const BackgroundCover = styled.div(
  (props) => css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props.backgroundImageUrl});
    background-position: center;
    background-repeat: no-repeat;
    background-size: ${props.contain ? "contain" : "cover"};
  `
)

export default Hero
