import { Heading, Paragraph } from "components/Typography"
import { breakpoints, media } from "styles/breakpoints"
import { slideInUpExtra, slideOutUpExtra } from "styles/keyframes"
import styled, { css } from "styled-components/macro"
import { useRef, useState } from "react"

import Container from "components/Container"
import Observer from "@researchgate/react-intersection-observer"
import SegmentedCircle from "components/SegmentedCircle"
import { SplitWords } from "components/SplitText"
import Tabs from "components/Tabs"
import { animateScroll } from "react-scroll"
import theme from "styles/theme"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

function SectionWithSegmentedCircle({ segments }) {
  const [sectionIndex, setSectionIndex] = useState(0)
  const [prevSectionIndex, setPrevSectionIndex] = useState(-1)
  const [isInView, setInView] = useState(false)
  const [circleRotation, setCircleRotation] = useState(0)
  const element = useRef()
  const trackLength = window.innerHeight + 750
  const tabs = segments.map(({ tabLabel }) => {
    return {
      title: tabLabel,
    }
  })
  const detailsCaption = segments[sectionIndex].detailsCaption
  const introSection = segments[0]
  const isIntroVisible = sectionIndex === 0

  function handleIntersection(event) {
    setInView(event.isIntersecting)
  }

  function handleTabChange(index) {
    if (index !== sectionIndex) {
      setPrevSectionIndex(sectionIndex)
      setSectionIndex(index)
    }
    const position = Math.min(
      element.current.offsetTop - theme.headerHeight,
      window.pageYOffset
    )
    animateScroll.scrollTo(position, {
      duration: 500,
      smooth: "easeOutCubic",
    })
  }

  useScrollPosition(
    ({ currPos }) => {
      if (!isInView || window.innerWidth <= breakpoints.mediumTablet) {
        return
      }
      const progress = Math.min(
        Math.max((window.innerHeight - currPos.y) / trackLength, 0),
        1
      )
      setCircleRotation(60 * (1 - progress) - 30)
    },
    [isInView],
    element
  )

  return (
    <Observer onChange={handleIntersection}>
      <Element
        ref={element}
        className={isInView && "-in-view"}
        style={{
          "--circle-rotation": `${circleRotation}deg`,
        }}
      >
        <TabsWrapper>
          <StyledTabs
            items={tabs}
            type="dimmed"
            onChange={(index) => handleTabChange(index)}
          />
        </TabsWrapper>
        <Container>
          <MapOverview>
            <Graph>
              <SegmentedCircle ballPosition={sectionIndex - 1} />
              <IntroLabels isVisible={isIntroVisible}>
                {segments.slice(1).map(({ title }, index) => (
                  <IntroLabel isVisible={isIntroVisible} key={index}>
                    {title}
                  </IntroLabel>
                ))}
              </IntroLabels>
              <Legend>
                {segments.map(({ title, subtitle }, index) => (
                  <LegendItem key={index}>
                    <LegendItemInner isActive={sectionIndex === index}>
                      <LegendSubtitle>
                        <SplitWords>{subtitle}</SplitWords>
                      </LegendSubtitle>
                    </LegendItemInner>
                  </LegendItem>
                ))}
              </Legend>
            </Graph>
            <Details>
              <DetailsIntro isVisible={isIntroVisible}>
                <DetailsIntroTitle>{introSection.subtitle}</DetailsIntroTitle>
                <DetailsIntroText>{introSection.content}</DetailsIntroText>
              </DetailsIntro>
              <DetailsWrapper isVisible={!isIntroVisible}>
                <DetailsItems>
                  {segments.map(({ title, content }, index) => (
                    <DetailsItem
                      isActive={sectionIndex === index}
                      isPrevActive={prevSectionIndex === index}
                      key={index}
                    >
                      <DetailsTitle>{title}</DetailsTitle>
                <DetailsCaption>{detailsCaption}</DetailsCaption>
                      <DetailsContent>{content}</DetailsContent>
                    </DetailsItem>
                  ))}
                </DetailsItems>
              </DetailsWrapper>
            </Details>
          </MapOverview>
        </Container>
      </Element>
    </Observer>
  )
}

const Element = styled.section`
  margin-bottom: 230px;
`

const TabsWrapper = styled.div`
  overflow: hidden;
  position: sticky;
  top: ${(p) => p.theme.headerHeight - 1}px;
  z-index: 9;
`

const StyledTabs = styled(Tabs)`
  transition: transform 1s ${(p) => p.theme.easeOutQuart};
  will-change: transform;

  &[class^="Tabs__Element"] li[class^="Tabs__Tab-"] {
    margin-right: 44px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${Element}:not(.-in-view) & {
    transform: translateX(100px);
  }
`

const MapOverview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 85px;
`

const Graph = styled.div`
  position: relative;
`

const IntroLabels = styled.div`
  transition: opacity 1.1s ${(p) => p.theme.easeInOutCubic};

  ${(p) =>
    !p.isVisible &&
    css`
      opacity: 0;
    `}
`

const IntroLabel = styled.h3`
  padding: 3px 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: ${(p) => p.theme.color.black};
  font-weight: bold;
  font-size: 40px;
  color: ${(p) => p.theme.color.white};
  transition: transform 1.1s ${(p) => p.theme.easeInOutCubic};

  &:nth-child(1) {
    top: calc(50% + 210px);
    left: calc(50% + 50px);
  }

  &:nth-child(2) {
    top: calc(50% - 30px);
    left: calc(50% - 280px);
  }

  &:nth-child(3) {
    top: calc(50% - 230px);
    left: calc(50% + 50px);
  }

  ${(p) =>
    !p.isVisible &&
    css`
      transform: scale(0);
    `}

  ${media.mediumDesktop} {
    &:nth-child(2) {
      left: calc(50% - 330px);
    }
  }
`

const Legend = styled.div`
  pointer-events: none;
`

const LegendItem = styled.div`
  width: 260px;
  position: absolute;
  top: 50%;
  left: 50%;

  &:nth-child(1) {
    display: none;
  }

  &:nth-child(2) {
    transform: translate(-25px, 104px);
  }

  &:nth-child(3) {
    transform: translate(-170px, -220px);
  }

  &:nth-child(4) {
    transform: translate(-10px, -240px);
  }

  ${media.mediumTablet} {
    &:nth-child(4) {
      transform: translate(-190px, -260px);
    }

    &:nth-child(5) {
      transform: translate(-10px, -287px);
    }
  }

  ${media.smallDesktop} {
    &:nth-child(2) {
      transform: translate(45px, 104px);
    }

    &:nth-child(3) {
      transform: translate(-300px, -260px);
    }

    &:nth-child(4) {
      transform: translate(60px, -300px);
    }
  }
`

const LegendItemInner = styled.div`
  transition: transform 1.1s ${(p) => p.theme.easeInOutCubic};
  will-change: transform;

  ${(p) =>
    !p.isActive &&
    css`
      transform: scale(0);
    `}
`

const LegendSubtitle = styled(Heading)`
  font-weight: 500;
  font-style: italic;
  font-size: 30px;

  color: ${(p) => p.theme.color.white};

  span span {
    display: inline-block;
    margin-right: -10px;
    margin-bottom: -8px;
    padding: 5px 10px;
    background-color: ${(p) => p.theme.color.black};
  }
`

const Details = styled.div`
  width: 41%;
  padding-bottom: 300px;
  position: relative;
`

const DetailsIntro = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  transition: opacity 0.5s;

  ${(p) =>
    !p.isVisible &&
    css`
      opacity: 0;
    `}
`

const DetailsIntroTitle = styled(Heading).attrs({
  extraLarge: true,
  "data-size": "extraLarge",
})`
  max-width: 370px;
`

const DetailsIntroText = styled.div`
  max-width: 460px;
  margin-top: 28px;
  font-size: 30px;
  line-height: 1.333333;
`

const DetailsWrapper = styled.div`
  transition: opacity 0.5s;

  ${(p) =>
    !p.isVisible &&
    css`
      opacity: 0;
    `}
`

const DetailsCaption = styled(Heading).attrs({
  small: true,
  as: "h5",
})`
  min-height: 76px;
  padding-top: 43px;
  border-top: 1px solid;
  font-weight: 500;
`

const DetailsItems = styled.div`
  position: relative;
`

const DetailsItem = styled.div`
  opacity: 0;
  position: absolute;

  ${(p) =>
    p.isActive &&
    css`
      animation: ${slideInUpExtra} 0.8s ${(p) => p.theme.easeOutCubic} 0.4s
        forwards;
    `}

  ${(p) =>
    p.isPrevActive &&
    css`
      animation: ${slideOutUpExtra} 0.6s ${(p) => p.theme.easeInQuad} forwards;
    `}

  ${(p) =>
    !p.isActive &&
    css`
      pointer-events: none;
    `}
`

const DetailsTitle = styled(Heading)`
  position: absolute;
  /* top: -203px; */
  top: -100px;
  left: 0;
  font-size: 60px;
  line-height: 1.2;
`

const DetailsContent = styled.div`
  ${Heading} {
    margin-top: 9px;
    font-weight: 700;
  }

  ${Paragraph}, ul {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.333333;

    &:first-of-type {
      margin-top: 25px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  li {
    padding-left: 32px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      left: 10px;
      width: 8px;
      height: 8px;
      background-color: currentColor;
      border-radius: 50%;
    }
  }
`

export default SectionWithSegmentedCircle
