import { keyframes } from "styled-components/macro"

const slideDistanceY = "50px"
const slideDistanceX = "20px"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
  }
`

const slideInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(${slideDistanceY});
  }
  
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideInUpExtra = keyframes`
  from {
    opacity: 0;
    transform: translateY(calc(${slideDistanceY} * 5));
  }
  
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideOutUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  
  to {
    opacity: 0;
    transform: translateY(-${slideDistanceY});
  }
`

const slideOutUpExtra = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  
  to {
    opacity: 0;
    transform: translateY(calc(-${slideDistanceY} * 5));
  }
`

const slideInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(${slideDistanceX});
  }
  
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideOutLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  
  to {
    opacity: 0;
    transform: translateX(-${slideDistanceX});
  }
`

const wordSpaceIn = keyframes`
  from {
    word-spacing: .3em;
  }
  
  to {
    word-spacing: 0;
  }
`

const moveLeft = keyframes`
  15% {
    transform: translateX(-5px); 
  }
  35% {
    transform: translateX(0); 
  }
`

const moveRight = keyframes`
  15% {
    transform: translateX(5px); 
  }
  35% {
    transform: translateX(0); 
  }
`

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export {
  fadeIn,
  fadeOut,
  slideInUp,
  slideInUpExtra,
  slideOutUp,
  slideOutUpExtra,
  slideInLeft,
  slideOutLeft,
  wordSpaceIn,
  moveLeft,
  moveRight,
  pulse,
}
