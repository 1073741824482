import { useEffect, useRef } from "react"

import { Link } from "react-router-dom"
import splitLines from "helpers/splitLines"
import styled from "styled-components/macro"

function Tile({ title, text, imageUrl, url }) {
  const titleEl = useRef()

  useEffect(() => {
    splitLines(titleEl.current) // TODO: refactor for reactivity
  }, [])

  return (
    <Element to={url}>
      <ImageWrapper>
        <Image src={imageUrl} alt={title} loading="lazy" />
      </ImageWrapper>
      <Title ref={titleEl}>{title}</Title>
      <Text>{text}</Text>
    </Element>
  )
}

const Element = styled(Link)`
  display: block;
  width: 260px;
  margin-right: 24px;
  vertical-align: top;
  white-space: initial;
`

const ImageWrapper = styled.figure`
  height: 260px;
  overflow: hidden;
  margin-bottom: 23px;
  background: ${(p) => p.theme.color.black};
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: transform;
  transition: transform 0.5s ${(p) => p.theme.easeOutQuart};

  ${Element}:hover & {
    transform: scale(1.225);
  }
`

const Title = styled.h3`
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.02em;

  span {
    display: inline-block;
    position: relative;

    &::before {
      content: "";
      opacity: 0;
      width: 0%;
      height: 1px;
      position: absolute;
      bottom: -2px;
      left: 0;
      background-color: currentColor;
      pointer-events: none;
      transition: 0.5s ${(p) => p.theme.easeOutQuart};
      transition-property: opacity, width;

      ${Element}:hover & {
        width: 100%;
        opacity: 1;
      }
    }
  }
`

const Text = styled.p``

export default Tile
