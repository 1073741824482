import styled, { css } from "styled-components/macro"

import Button from "components/Button"
import ColorCard from "components/ColorCard"
import Container from "components/Container"
import Header from "components/Header"
import { Heading } from "components/Typography"
import { Helmet } from "react-helmet"
import Hero from "components/Hero"
import Loader from "components/Loader"
import { ROUTE_TITLES } from "config/constants"
import ScrollToTop from "components/ScrollToTop"
import SectionPlain from "components/SectionPlain"
import Slideshow from "components/Slideshow"
import { getNonceValue } from "helpers/getNonceValue"
import { useQuery } from "jsonapi-react"

function HomeMobile() {
  const {
    data: homepageData,
    isLoading,
    error,
  } = useQuery([
    "node/homepage",
    {
      include: ["field_hero_img_mobile", "field_s_green_background"],
      nonce: getNonceValue(),
    },
  ])
  const homepage = homepageData && homepageData[0]
  const { hero } = { ...homepage }

  const { data: guidelinesData } = useQuery([
    "node/guidelines_page",
    {
      include: [
        "field_guidelines_tabs",
        "field_guidelines_tabs.field_main_download_file",
        "field_guidelines_tabs.field_logos_sections",
        "field_guidelines_tabs.field_logos_sections.field_download_file",
        "field_guidelines_tabs.field_logos_sections.field_logos_boxes",
        "field_guidelines_tabs.field_logos_sections.field_logos_boxes.field_box_image",
        "field_guidelines_tabs.field_logos_sections.field_logos_boxes.field_box_image_svg",
        "field_guidelines_tabs.field_s_pp_swatches",
      ],
      nonce: getNonceValue(),
    },
  ])
  const guidelinesPage = guidelinesData && guidelinesData[0]
  const { getSubpages } = { ...guidelinesPage }
  const subpages = guidelinesPage && getSubpages()
  const logosPage = subpages && subpages[0]
  const typographyPage = subpages && subpages[1]
  const colorsPage = subpages && subpages[2]
  const logoSections = logosPage?.getLogoSections() || []

  const colors = [
    colorsPage?.sectionColor?.colorCard,
    ...(colorsPage?.sectionPalette?.getColors() || []),
  ]

  const logoSlideshow = [
    {
      title: logosPage?.header?.title,
      description: logosPage?.header?.subtitle,
      ctaText: logosPage?.header?.ctaText,
      getCtaUrl: logosPage?.header?.getCtaUrl,
      imageUrl:
        logoSections?.length > 0 &&
        logoSections?.[0].slideshow?.length > 0 &&
        logoSections?.[0].slideshow
          ?.find((item) => item.getIsPicture())
          ?.getImageUrl(),
      isPicture: true,
    },
    ...logoSections,
  ]

  return (
    <>
      <Helmet>
        <title>{ROUTE_TITLES["/"]}</title>
      </Helmet>
      {isLoading || error ? (
        <Loader error={error} />
      ) : (
        <div className="App">
          <StyledHeader isTransparent />
          <Hero
            title={hero.title}
            subtitle={hero.subtitle}
            backgroundImageUrl={hero.getMobileImageUrl()}
          />
          <SectionPlain
            content="For the full experience, please visit The Foundation on your desktop. For quick, on-the-go information, scroll down."
            ctaText="Let’s get started."
          />
          <SimplifiedSectionColor backgroundColor={`#ADDDB7`}>
            <Container>
              <Title>Colors.</Title>
              <SimplifiedSectionColorSubtitle>
                {colorsPage?.sectionColor?.title}
              </SimplifiedSectionColorSubtitle>
              {colorsPage?.sectionColor?.description}
            </Container>
            <ColorCards>
              {colors.map((color, index) => (
                <StyledColorCard {...color} cardType="simple" key={index} />
              ))}
            </ColorCards>
          </SimplifiedSectionColor>
          <SimplifiedSectionLogos>
            <Container>
              <Title>Logos.</Title>
            </Container>
            {logoSlideshow.length > 1 && (
              <StyledSlideshow hasPagination="extended">
                {logoSlideshow.map(
                  (
                    {
                      title,
                      description,
                      ctaText,
                      getCtaUrl,
                      imageUrl,
                      isPicture,
                      slideshow,
                    },
                    index
                  ) => {
                    const downloadUrl = getCtaUrl()
                    imageUrl =
                      imageUrl || (slideshow && slideshow[0]?.getImageUrl())
                    isPicture =
                      isPicture || (slideshow && slideshow[0]?.getIsPicture())
                    return (
                      <SimplifiedSectionLogosSlide key={index}>
                        <SimplifiedSectionLogosSlideImageWrapper>
                          <SimplifiedSectionLogosSlideImage
                            isPicture={isPicture}
                            src={imageUrl}
                            alt=""
                          />
                        </SimplifiedSectionLogosSlideImageWrapper>
                        <SimplifiedSectionLogosSlideContent>
                          <SimplifiedSectionColorSubtitle>
                            {title}
                          </SimplifiedSectionColorSubtitle>
                          <SimplifiedSectionLogosSlideDescription>
                            {description}
                          </SimplifiedSectionLogosSlideDescription>
                          <Button iconBox="download" to={downloadUrl}>
                            {ctaText}
                          </Button>
                        </SimplifiedSectionLogosSlideContent>
                      </SimplifiedSectionLogosSlide>
                    )
                  }
                )}
              </StyledSlideshow>
            )}
          </SimplifiedSectionLogos>
          <SimplifiedSectionTypography>
            <Container>
              <Title>Typography.</Title>
              <SimplifiedSectionColorSubtitle>
                Clinique’s Helvetica Neue.
              </SimplifiedSectionColorSubtitle>
              <SimplifiedSectionTypographyText>
                {typographyPage?.header?.description}
              </SimplifiedSectionTypographyText>
            </Container>
          </SimplifiedSectionTypography>
          <ScrollToTop />
        </div>
      )}
    </>
  )
}

export default HomeMobile

const StyledHeader = styled(Header)`
  position: absolute;
`

const Title = styled(Heading)`
  font-size: 50px;

  &&:not(:last-child) {
    margin-bottom: 28px;
  }
`

const SimplifiedSectionColor = styled.section`
  margin-bottom: 140px;
  padding: 30px 0 1px;
  background-color: ${(p) => p.backgroundColor};

  p strong {
    font-weight: 700;
  }
`

const SimplifiedSectionColorSubtitle = styled(Heading).attrs({
  as: "h3",
})`
  font-size: 28px;
  max-width: 8em;

  &&:not(:last-child) {
    margin-bottom: 22px;
  }
`

const ColorCards = styled.div`
  display: flex;
  overflow: auto;
  margin-top: 65px;
  margin-bottom: -86px;
  padding-bottom: 8px;
`

const StyledColorCard = styled(ColorCard)`
  width: calc(100vw - 114px);
  min-width: calc(100vw - 114px);
  margin-left: ${(p) => p.theme.sidePadding.extraSmall}px;

  &:last-child {
    margin-right: ${(p) => p.theme.sidePadding.extraSmall}px;
  }

  &.-simple {
    height: 232px;
  }
`

const SimplifiedSectionLogos = styled.section`
  margin-bottom: 40px;
`

const StyledSlideshow = styled(Slideshow)`
  margin-top: 43px;

  div[class^="Slideshow__Pagination-"] {
    justify-content: center;
    margin-top: 25px;
    position: static;
  }
`

const SimplifiedSectionLogosSlide = styled.div`
  width: 100%;

  ${SimplifiedSectionColorSubtitle} {
    max-width: none;
  }
`

const SimplifiedSectionLogosSlideImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77.9vw;
  margin-bottom: 40px;
`

const SimplifiedSectionLogosSlideImage = styled.img`
  pointer-events: none;

  ${(p) =>
    p.isPicture &&
    css`
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    `}

  ${(p) =>
    !p.isPicture &&
    css`
      max-width: 90vw;
      max-height: 45vw;
    `}
`

const SimplifiedSectionLogosSlideContent = styled(Container)``

const SimplifiedSectionLogosSlideDescription = styled.div`
  margin-bottom: 28px;

  p {
    font-size: inherit;
    line-height: inherit;
  }
`

const SimplifiedSectionTypography = styled.section`
  padding: 40px 0 60px;
`

const SimplifiedSectionTypographyText = styled.div`
  p {
    font-size: inherit;
    line-height: inherit;
  }
`
