import styled, { css } from "styled-components/macro"

import { SplitLetters } from "components/SplitText"

function Hotspots({ isVisible, items }) {
  return (
    <Element isVisible={isVisible}>
      {items.map((name, index) => (
        <Hotspot aria-label={name} key={index}>
          <Name letterCount={name.length}>{name}</Name>
        </Hotspot>
      ))}
    </Element>
  )
}

const Element = styled.div`
  opacity: 0;
  position: relative;
  z-index: 1;
  transition: opacity 0.5s ${(p) => p.theme.easeOutQuart};

  ${(p) =>
    p.isVisible &&
    css`
      opacity: 1;
      transition-duration: 2s;
    `}
`

const Hotspot = styled.h3`
  width: 32px;
  height: 32px;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  background-color: ${(p) => p.theme.color.black};
  border: 2px solid transparent;
  border-radius: 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.015em;
  color: ${(p) => p.theme.color.white};
  user-select: none;
  transition: width 0.6s ${(p) => p.theme.easeOutQuart}, background 0.1s 0.5s,
    border 0.1s 0.5s, color 0.1s 0.5s;

  &::before,
  &::after {
    content: "";
    width: 14px;
    height: 2px;
    position: absolute;
    top: 13px;
    left: 7px;
    background-color: currentColor;
  }

  &::after {
    transform: rotate(-90deg);
    transition: transform 0.6s ${(p) => p.theme.easeOutQuart};
  }

  &:nth-child(1) {
    top: 168px;
    left: -16px;
  }

  &:nth-child(2) {
    top: 106px;
    left: 135.92px;
  }

  &:nth-child(3) {
    top: 74px;
    left: 320.92px;
  }

  &:nth-child(4) {
    top: 259px;
    left: 408.92px;
  }

  &:nth-child(5) {
    top: 166px;
    left: 543.92px;
  }

  &:hover {
    width: 100px;
    background-color: ${(p) => p.theme.color.white};
    border-color: currentColor;
    color: ${(p) => p.theme.color.black};
    transition: width 0.6s 0.25s ${(p) => p.theme.easeOutQuart}, background 0s,
      color 0s;

    &::after {
      transform: rotate(0deg);
    }

    &:nth-child(1) {
      width: calc(95px + 65px);
    }

    &:nth-child(2) {
      width: calc(40px + 65px);
    }

    &:nth-child(3) {
      width: calc(56px + 65px);
    }

    &:nth-child(4) {
      width: calc(67px + 65px);
    }

    &:nth-child(5) {
      width: calc(70px + 65px);
    }
  }
`

function letterStyles({ letterCount }) {
  let style = ""
  for (let i = 0; i < letterCount; i++) {
    const indent = -0.6 * i
    const opacityDelay = (0.25 * i) / letterCount + 0.3
    style += `
      &:nth-child(${i + 1}) {
        transform: translateX(${indent}em);
    
        ${Hotspot}:hover & {
          transition: opacity 0.6s ${opacityDelay}s cubic-bezier(0.25, 1, 0.5, 1), transform .6s .25s cubic-bezier(0.25, 1, 0.5, 1);
        }
      }
    `
  }
  return css`
    ${style}
  `
}

const Name = styled(SplitLetters)`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 40px;
  transform: translateX(-5px);
  line-height: 28px;
  white-space: nowrap;
  pointer-events: none;
  transition: transform 0.6s ${(p) => p.theme.easeOutQuart};
  will-change: transform;

  span {
    ${letterStyles};
    display: inline-block;
    opacity: 0;
    transition: transform 0.6s ${(p) => p.theme.easeOutQuart};
    will-change: opacity, transform;

    ${Hotspot}:hover & {
      opacity: 1;
      transform: none;
    }
  }

  ${Hotspot}:hover & {
    transform: none;
    transition-delay: 0.35s;
  }
`

export default Hotspots
