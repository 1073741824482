import React, { useCallback, useState } from "react"
import styled, { keyframes } from "styled-components"
import { useEffect, useRef } from "react"

import Draggable from "react-draggable"
import { ReactComponent as FullscreenSvg } from "assets/icons/fullscreen.svg"
import { ReactComponent as MutedSvg } from "assets/icons/muted.svg"
import { ReactComponent as PauseSvg } from "assets/icons/pause.svg"
import { ReactComponent as PlaySvg } from "assets/icons/play.svg"
import { ReactComponent as VolumeSvg } from "assets/icons/volume.svg"
import { transparentize } from "polished"

function formatTime(seconds) {
  seconds = Math.round(seconds)

  let minutes = Math.floor(seconds / 60)
  minutes = minutes >= 10 ? minutes : "0" + minutes

  seconds = Math.floor(seconds % 60)
  seconds = seconds >= 10 ? seconds : "0" + seconds

  return minutes + ":" + seconds
}

export function VideoPlayer({
  thumbnailUrl,
  videoUrl,
  videoType = "video/mp4",
  ...props
}) {
  const [playerWidth, setPlayerWidth] = useState()
  const [isPlaying, setIsPlaying] = useState(true)
  const [currentVolume, setCurrentVolume] = useState(1)
  const [currentTime, setCurrentTime] = useState(0)
  const [playerEl, setPlayerEl] = useState()
  const [controlledVolumePositionX, setControlledVolumePositionX] = useState(0)
  const [controlledProgressPositionX, setControlledProgressPositionX] =
    useState(0)
  const progressRef = useRef()
  const volumeBarRef = useRef()

  var isFullScreen = function () {
    return !!(
      document.fullScreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    )
  }

  const onPlayerClick = (e) => {
    setIsPlaying(!isPlaying)
  }

  const onVideoRender = useCallback((node) => {
    setPlayerEl(node)
  }, [])

  const onTimeUpdate = (e) => {
    setCurrentTime(playerEl.currentTime)
    setControlledProgressPositionX(
      (progressRef.current?.clientWidth * playerEl.currentTime) /
        playerEl.duration
    )
  }

  const onPlayPauseClick = (e) => {
    setIsPlaying(!isPlaying)
  }

  const onVolumeButtonClick = (e) => {
    if (currentVolume > 0) {
      setCurrentVolume(0)
    } else {
      setCurrentVolume(1)
    }
  }

  const onVolumeProgressClick = (e) => {
    var bounds = e.target.getBoundingClientRect()
    const x = e.pageX - bounds.left
    setCurrentVolume((x * e.target.max) / e.target.offsetWidth)
  }

  const onProgressClick = (e) => {
    var bounds = e.target.getBoundingClientRect()
    const x = e.pageX - bounds.left
    playerEl.currentTime = (x * e.target.max) / e.target.offsetWidth
    setControlledProgressPositionX(x)
  }

  const onFullscreenClick = (e) => {
    e.stopPropagation()

    if (isFullScreen()) {
      document.exitFullscreen()
    } else {
      playerEl.webkitRequestFullScreen()
    }
  }

  const onControlledProgressDrag = (e, position) => {
    const { x } = position
    const cTime = (x * playerEl.duration) / progressRef.current?.clientWidth
    playerEl.currentTime = cTime
    setCurrentTime(cTime)
    setControlledProgressPositionX(x)
  }

  const onControlledVolumeDrag = (e, position) => {
    const { x } = position
    const volume = x / volumeBarRef.current?.clientWidth
    if (typeof volume === "number") {
      setCurrentVolume(volume)
    }
  }

  useEffect(() => {
    if (!playerEl) return
    playerEl.volume = currentVolume
    setControlledVolumePositionX(
      volumeBarRef.current?.clientWidth * currentVolume
    )
  }, [currentVolume, playerEl])

  useEffect(() => {
    if (!playerEl) return
    if (isPlaying) {
      if (playerEl.paused || playerEl.ended) playerEl.play()
    } else {
      playerEl.pause()
    }
  }, [isPlaying, playerEl])

  useEffect(() => {
    playerEl?.addEventListener("canplay", (e) => {
      setPlayerWidth(e.target.clientWidth)
    })
  }, [playerEl])

  return (
    <Container {...props} style={{ width: playerWidth }}>
      <Video
        onClick={onPlayerClick}
        ref={onVideoRender}
        onTimeUpdate={onTimeUpdate}
        controlsList="nodownload"
      >
        <source src={videoUrl} type={videoType} />
      </Video>
      {playerEl && (
        <Controls>
          <PlayPauseButton onClick={onPlayPauseClick}>
            {isPlaying ? (
              <PauseSvg />
            ) : (
              <PlaySvg style={{ maxHeight: 14, maxWidth: 14 }} />
            )}
          </PlayPauseButton>
          <VolumeControl>
            <IconControlButton onClick={onVolumeButtonClick}>
              {currentVolume === 0 ? <MutedSvg /> : <VolumeSvg />}
            </IconControlButton>
            <VolumeBarWrapper>
              <VolumeBar
                value={currentVolume}
                min={0}
                max={1}
                onClick={onVolumeProgressClick}
                ref={volumeBarRef}
              />
              <Draggable
                axis="x"
                bounds={{ left: 0, right: volumeBarRef.current?.clientWidth }}
                position={{ x: controlledVolumePositionX, y: -2 }}
                positionOffset={{ x: -4, y: 0 }}
                onDrag={onControlledVolumeDrag}
              >
                <span></span>
              </Draggable>
            </VolumeBarWrapper>
          </VolumeControl>
          <ProgressControl
            style={{
              width: Math.floor((currentTime / playerEl.duration) * 100) + "%",
            }}
          >
            <Progress
              value={currentTime}
              min={0}
              max={playerEl.duration}
              onClick={onProgressClick}
              ref={progressRef}
            />
            <Draggable
              axis="x"
              bounds={{ left: 0, right: progressRef.current?.clientWidth }}
              position={{ x: controlledProgressPositionX, y: -6 }}
              positionOffset={{ x: -8, y: 0 }}
              onDrag={onControlledProgressDrag}
            >
              <ProgressDragHandler>
                <div>
                  <span>{formatTime(currentTime)}</span>
                </div>
              </ProgressDragHandler>
            </Draggable>
          </ProgressControl>
          <FullscreenButton onClick={onFullscreenClick}>
            <FullscreenSvg />
          </FullscreenButton>
        </Controls>
      )}
    </Container>
  )
}

const Container = styled.div`
  overflow: hidden;
`

const Video = styled.video`
  max-width: 100%;
  max-height: calc(100% - 2px);
`

const anim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Controls = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 74px;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(20px);
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  /* Hide width resize by opacity animation */
  animation-name: ${anim};
  animation-duration: 0.7s;
  animation-delay: 0.7s;
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
`
const Progress = styled.progress`
  appearance: none;
  width: 100%;
  height: 4px;
  border: none;
  display: block;
  &:hover {
    cursor: pointer;
  }
  &::-webkit-progress-bar {
    background-color: ${(p) => transparentize(0.9, p.theme.color.white)};
    height: 4px;
  }
  &::-webkit-progress-value {
    background-color: ${(p) => transparentize(0.3, p.theme.color.white)};
    height: 4px;
  }
`

const IconControlButton = styled.button`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.color.white};
  &:hover {
    cursor: pointer;
  }
`

const PlayPauseButton = styled(IconControlButton)`
  width: 30px;
`

const VolumeControl = styled.div`
  position: relative;
  margin-right: 16px;
  display: flex;
  align-items: center;
  button {
    margin-right: 10px;
  }
  span {
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #fff;
    cursor: pointer;
  }
`

const VolumeBar = styled(Progress)`
  width: 40px;
`

const FullscreenButton = styled(IconControlButton)`
  margin-left: 16px;
`

const ProgressControl = styled.div`
  position: relative;
  flex: 1;
  height: 4px;
  margin: 0 8px;
`

const VolumeBarWrapper = styled.div`
  position: relative;
`

const ProgressDragHandler = styled.div`
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #fff;
  cursor: pointer;
  & > div {
    position: absolute;
    left: 50%;
    top: -150%;
    min-width: 50px;
    height: 22px;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    transform: translate(-50%, -50%);
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 4px;
      height: 4px;
      background: rgba(255, 255, 255, 0.8);
      transform: translateY(50%) rotate(45deg);
    }
    span {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.03em;
      padding: 0 12px;
    }
  }
`
