import styled from "styled-components/macro"
import theme from "styles/theme"
import { media } from "styles/breakpoints"
import { animateScroll } from "react-scroll"
import { Heading } from "components/Typography"
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevronRight.svg"

function ScrollIndicator({ parentRef, children, className }) {
  function onClick() {
    const top = parentRef.current.offsetTop
    const height = parentRef.current.getBoundingClientRect().height
    const position = top + height - theme.headerHeight
    animateScroll.scrollTo(position, {
      duration: 1200,
      smooth: "easeInOutCubic",
    })
  }

  return (
    <Element className={className} onClick={onClick}>
      {children}
      <Arrow>
        <ArrowIcon />
      </Arrow>
    </Element>
  )
}

const Element = styled(Heading).attrs({
  medium: true,
  as: "button",
})`
  position: relative;
  cursor: pointer;
  user-select: none;

  ${media.ltSmallTablet} {
    font-size: 16px;
  }
`

const Arrow = styled.span`
  width: 7px;
  height: 18px;
  position: absolute;
  top: 35px;
  left: calc(50% - 3.5px);
  transition: height 0.25s;

  &::before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 3px;
    background-color: currentColor;
  }

  &::after {
    content: "";
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: 0;
    left: -15px;
  }

  ${media.smallTablet} {
    height: 30px;
    top: 56px;

    ${Element}:hover & {
      height: 37px;
    }
  }
`

const ArrowIcon = styled(ChevronRightIcon)`
  width: 5px;
  height: 9px;
  position: absolute;
  bottom: -3px;
  left: 1px;
  transform: rotate(90deg);
`

export default ScrollIndicator
