import styled, { css } from "styled-components/macro"

const sizes = [
  "extraLarge",
  "large",
  "medium",
  "semiMedium",
  "small",
  "extraSmall",
  "moleculeExtraLarge",
  "moleculeLarge",
  "moleculeMedium",
  "moleculeSmall",
  "moleculeSubheading",
  "moleculeLargeText",
]

const Heading = styled.h2.attrs((p) => {
  const size = Object.keys(p).find((item) => sizes.includes(item))
  return {
    "data-size": size,
  }
})`
  font-weight: 700;

  ${(p) =>
    p.extraLarge &&
    css`
      font-size: 62px;
      line-height: 1.16129032; // 72px
    `}

  ${(p) =>
    p.large &&
    css`
      font-size: 45px;
      line-height: 1.06666667; // 48px
      letter-spacing: 0.005em;
    `}
  
  ${(p) =>
    p.medium &&
    css`
      font-weight: 300;
      font-size: 32px;
      line-height: 1.25; // 40px

      b,
      strong {
        font-weight: 500;
      }
    `}
    
  ${(p) =>
    p.semiMedium &&
    css`
      font-weight: 300;
      font-size: 30px;
      line-height: 1.26666667; // 38px

      b,
      strong {
        font-weight: 700;
      }
    `}

  ${(p) =>
    p.small &&
    css`
      text-transform: uppercase;
      font-size: 20px;
      line-height: 1.6; // 32px
      letter-spacing: 0.02em;
    `}

  ${(p) =>
    p.extraSmall &&
    css`
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.71428571; // 24px
      letter-spacing: 0.03em;
    `}
    
  ${(p) =>
    p.moleculeExtraLarge &&
    css`
      font-size: 110px;
      line-height: 1.0182; // 112px

      @media (max-width: 1150px) {
        font-size: 9.56521739vw;
      }
    `}
  
  ${(p) =>
    p.moleculeLarge &&
    css`
      font-size: 100px;
      line-height: 1.04; // 104px
    `}
    
  ${(p) =>
    p.moleculeMedium &&
    css`
      font-weight: 400;
      font-size: 60px;
      line-height: 1.067; //64px
    `}
    
  ${(p) =>
    p.moleculeSmall &&
    css`
      font-weight: 500;
      font-size: 30px;
      line-height: 1.067; // 32px
    `}
    
  ${(p) =>
    p.moleculeSubheading &&
    css`
      font-weight: 400;
      font-size: 24px;
      line-height: 1.33333; // 32px

      strong,
      b {
        font-weight: 500;
      }
    `}
      
  ${(p) =>
    p.moleculeLargeText &&
    css`
      font-weight: 500;
      font-size: 30px;
      line-height: 1.3333333; // 40px
    `}
`

export { Heading }
