import Container from "components/Container"
import { Link } from "react-router-dom"
import logo from "assets/images/logo.svg"
import { media } from "styles/breakpoints"
import navigation from "data/footerNavigation.json"
import styled from "styled-components/macro"
import { trackEvent } from "utils/analytics"

function Footer() {
  return (
    <Element>
      <Container>
        <Grid>
          <LogoWrapper>
            <Logo src={logo} alt="Clinique" loading="lazy" />
            <Tagline>The Foundation</Tagline>
          </LogoWrapper>
          {navigation.map(({ title, items }, index) => (
            <MenuColumn key={index}>
              <MenuTitle>{title}</MenuTitle>
              <MenuList>
                {items.map(({ title, path }, index) => (
                  <MenuItem key={index}>
                    <MenuLink
                      to={path}
                      onClick={() => {
                        trackEvent("click_navigation", {
                          navigation_button_name: title,
                          navigation_category: "footer",
                        })
                      }}
                    >
                      {title}
                    </MenuLink>
                  </MenuItem>
                ))}
              </MenuList>
            </MenuColumn>
          ))}
          <Copyright>© 2010 — {new Date().getFullYear()}</Copyright>
          <Links>
            <MenuLink to="/">Privacy</MenuLink> —{" "}
            <MenuLink to="/">Terms</MenuLink>
          </Links>
        </Grid>
      </Container>
    </Element>
  )
}

const Element = styled.footer`
  padding: 105px 0 40px;
  font-size: 16px;
  line-height: 1.5;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 35% 1fr 1fr 1fr;
  row-gap: 43px;
  column-gap: 8px;

  ${media.smallDesktop} {
    grid-template-columns: 50% 1fr 1fr 1fr;
  }
`

const MenuColumn = styled.div``

const LogoWrapper = styled.div``

const Logo = styled.img`
  width: 180px;

  ${media.mediumTablet} {
    width: 248px;
  }
`

const Tagline = styled.p`
  margin-top: -2px;
`

const MenuTitle = styled.h3`
  margin-bottom: 14px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.015em;
`

const MenuList = styled.ul`
  font-size: 14px;
  letter-spacing: 0.03em;
`

const MenuItem = styled.li``

const MenuLink = styled(Link)`
  display: inline-block;
  padding: 6px 0 5px;

  &:hover {
    opacity: 0.6;
  }
`

const Copyright = styled.p``

const Links = styled.p`
  margin-top: -4px;
  font-size: 14px;
  letter-spacing: 0.03em;
`

export default Footer
